import React from 'react'
import { Link as RouterLink } from 'react-router-dom';

import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import HiveIcon from '@mui/icons-material/Hive';

export const UserRoleList = (props) => {
  return (
    <List subheader={<ListSubheader>User</ListSubheader>}>
      <ListItem button component={RouterLink} to="/manage-entries">
        <ListItemIcon>
          <ConfirmationNumberIcon />
        </ListItemIcon>
        <ListItemText primary="Entries" />
      </ListItem>
      <ListItem button component={RouterLink} to="/manage-wallet-transactions">
        <ListItemIcon>
          <ReceiptLongIcon />
        </ListItemIcon>
        <ListItemText primary="Wallet Transactions" />
      </ListItem>
      <ListItem button component={RouterLink} to="/honey-point-transactions">
        <ListItemIcon>
          <HiveIcon />
        </ListItemIcon>
        <ListItemText primary="Honey Points" />
      </ListItem>
    </List>
  )
};
