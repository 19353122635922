import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AuthService from "./services/AuthService.js";
import { LoginContext } from "./contexts/LoginContext";
import SiteContainer from './components/SiteContainer';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(AuthService.isLoggedIn());
  const [userSub, setUserSub] = useState(AuthService.userSub());
  const [wallet, setWallet] = useState(AuthService.wallet());
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  return (
    <BrowserRouter>
      <LoginContext.Provider value={{ isLoggedIn, setIsLoggedIn, userSub, setUserSub, state, setState, wallet, setWallet }}>
        <SiteContainer></SiteContainer>
      </LoginContext.Provider>
    </BrowserRouter >
  );
}

export default App;
