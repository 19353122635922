import React from 'react'
import { Link as RouterLink } from 'react-router-dom';

import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LockIcon from '@mui/icons-material/Lock';
import FollowTheSignsOutlinedIcon from '@mui/icons-material/FollowTheSignsOutlined';

export const PublicList = (props) => {
  return (
    <List subheader={<ListSubheader>Not Logged In</ListSubheader>}>
      <ListItem button component={RouterLink} to="/login">
        <ListItemIcon>
          <LockIcon />
        </ListItemIcon>
        <ListItemText primary="Log In" />
      </ListItem>
      <ListItem button component={RouterLink} to="/register">
        <ListItemIcon>
          <FollowTheSignsOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Register" />
      </ListItem>
    </List>
  )
};
