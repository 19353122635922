import React from 'react'
import {Card, CardContent, CardActions, Button} from '@mui/material';
import Typography from '@mui/material/Typography';

export const CompCard = (props) => {

  return (
    <Card sx={{ minWidth: 150 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {props.title}
        </Typography>
        <Typography variant="h5" component="div">
          {props.digit === null ? "0": props.digit}
        </Typography>
      </CardContent>
      {!props.hideCardActions && (
      <CardActions>
        <Button size="small" onClick={() => props.handleLoadDetails(props.whatDetails)}>Load Details</Button>
      </CardActions>
      )}
    </Card>
  )
};
