import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Table, TableContainer, TableHead, 
  TableCell, TableRow, TableBody, Box, LinearProgress, Paper, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { CompCard } from '../Shared/CompCard';
import { LoginContext } from '../../contexts/LoginContext';
import Countdown from 'react-countdown';
import Moment from 'react-moment';
import moment from 'moment';
import _ from 'lodash';

export const CompetitionResults = () => {
  const { userSub } = useContext(LoginContext);
  let { id } = useParams();
  const [competition, setCompetition] = useState(null);
  const [details, setDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingLeaderboard, setLoadingLeaderboard] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [detailsTitle, setDetailsTitle] = useState(null);
  const [compClosed, setCompClosed] = useState(false);
  const [compOpened, setCompOpened] = useState(false);
  const [whatDetails, setWhatDetails] = useState(null);
  const [playerLeaderboard, setPlayerLeaderboard] = useState(null);
  const [club, setClub] = useState(null);
  const [breakdown, setBreakdown] = useState(null);
  const [eligableEntries, setEligableEntries] = useState(null);
  const [loadingEntries, setLoadingEntries] = useState(false);
  const [winners, setWinners] = useState([]);

  var enabledStyle = { background: '#fdb611', color: '#000000' };

  const handleCountdownCompleteCompOpen = () => {
    setCompOpened(true);
  }
  const handleCountdownCompleteCompClosed = () => {
    setCompClosed(true);
  }

  useEffect(() => {
    console.log('using effect in competition results');

    console.log('Getting competition: ' + id);
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/competitions/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response3) {
        var localCompetition = response3.data;
        setCompetition(localCompetition);
        if(moment(localCompetition.openDate) <= moment()){
          setCompOpened(true);
        }
        if(moment(localCompetition.endDate) <= moment()){
          setCompClosed(true);
        }

        setPlayerLeaderboard(null);
        setLoadingLeaderboard(true);
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs/' + localCompetition.clubId, {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response4) {
            var localClub = response4.data;
            setClub(response4.data);
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/rate-cards/' + localClub.rateCardId, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response5) {
                setLoadingEntries(true);
                var localRateCard = response5.data;
                if(response5.status === 204)
                localRateCard = null;
                var sortFilter = "sortBy=date_created&sortByDirection=asc"
                var filter = "?" + sortFilter;
                filter = filter + "&competitionId=" + id;
                axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries' + filter, {
                  headers: {
                    'Authorization': localStorage.getItem('jwtToken')
                  }
                })
                  .then(function (response6) {
                    var localEntries = response6.data;
                    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entry-votes' + filter, {
                      headers: {
                        'Authorization': localStorage.getItem('jwtToken')
                      }
                    })
                      .then(function (response3) {
                        var localVotes = response3.data;
                        var distinctUserVotes = makeUnique(localVotes, ["userId", "playerId"]);
                        var localPlayerLeaderboard = _.uniqBy(distinctUserVotes, p => {
                          return p.playerId
                        });
                        _.forEach(localPlayerLeaderboard, p => {
                          var voteCount = _.filter(distinctUserVotes, pp => {
                            return pp.playerId === p.playerId
                          }).length;
                          p.voteCount = voteCount;
                        });
                        var orderedPlayerLeaderboard = _.orderBy(localPlayerLeaderboard, ['voteCount'], ['desc']);                    
                        setPlayerLeaderboard(orderedPlayerLeaderboard);
                        setLoadingLeaderboard(false);

                        var localBreakdown = {
                          playerShareOfSponsorship: localCompetition.sponsorPlayerPercentage/100 * (localCompetition.sponsorCommitment ? localCompetition.sponsorCommitment: 0),
                          fanShareOfSponsorship: localCompetition.sponsorFanPercentage/100 * (localCompetition.sponsorCommitment ? localCompetition.sponsorCommitment: 0),
                          fanhiveFees: 0,
                          clubFees: 0
                        };
                        
                        if(localRateCard){
                          localBreakdown.fanhiveFees = localRateCard.fanhivePercentage/100 * (localCompetition.ticketIncome ? localCompetition.ticketIncome: 0);
                          localBreakdown.clubFees = localRateCard.clubPercentage/100 * (localCompetition.ticketIncome ? localCompetition.ticketIncome: 0);
                        }

                        if(localCompetition.ticketIncome !== null && localCompetition.ticketIncome >= 0) {
                          var ticketIncometoShare = localCompetition.ticketIncome - (localBreakdown.fanhiveFees + localBreakdown.clubFees);
                          localBreakdown.playerShareOfTicketIncome = localCompetition.proceedsPlayerPercentage/100 * ticketIncometoShare;
                          localBreakdown.fanShareOfTicketIncome = localCompetition.proceedsFanPercentage/100 * ticketIncometoShare;
                        } else {
                          localBreakdown.playerShareOfTicketIncome = 0;
                          localBreakdown.fanShareOfTicketIncome = 0;
                        }
                        if(orderedPlayerLeaderboard.length > 0){
                          localBreakdown.playerOfTheMatch = orderedPlayerLeaderboard[0];
                        }

                        var localEligableEntries = _.filter(localEntries, p => {
                          return p.votedForPlayerId === localBreakdown.playerOfTheMatch.playerId && (p.totalQuestions === p.correctAnswers)
                        });
                        setEligableEntries(localEligableEntries);
                        setLoadingEntries(false);

                        setBreakdown(localBreakdown);
                      })
                      .catch(function (error) {
                        console.log(error);
                        setLoadingLeaderboard(false);
                      });
                  })
                  .catch(function (error) {
                    console.log(error);
                    setLoadingLeaderboard(false);
                  });
              })
              .catch(function (error) {
                console.log(error);
                setLoadingLeaderboard(false);
              });
          })
          .catch(function (error) {
            console.log(error);
            setLoadingLeaderboard(false);
          });

        setPageLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id, userSub]);

  const getEntries = () => {
    
    setDetails(null);
    setLoadingDetails(true);
    var sortFilter = "sortBy=date_created&sortByDirection=asc"
    var filter = "?" + sortFilter;
    filter = filter + "&competitionId=" + id;

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries' + filter, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response3) {
        setDetails(response3.data);
        setLoadingDetails(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoadingDetails(false);
      });
  }

  const getVotes = () => {
    
    setDetails(null);
    setLoadingDetails(true);
    var sortFilter = "sortBy=date_created&sortByDirection=asc"
    var filter = "?" + sortFilter;
    filter = filter + "&competitionId=" + id;

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entry-votes' + filter, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response3) {
        setDetails(response3.data);
        setLoadingDetails(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoadingDetails(false);
      });
  }

  const makeUnique = (array = [], keys = []) => {
    if (!keys.length || !array.length) return [];
  
    return array.reduce((list, item) => {
      const hasItem = list.find(listItem =>
        keys.every(key => listItem[key] === item[key])
      );
      if (!hasItem) list.push(item);
      return list;
    }, []);
  };
  

  const handleLoadDetails = (whatDetails) => {
    setWhatDetails(whatDetails);
    switch(whatDetails){
      case 'totalentries':{
        setDetailsTitle("Total Entries")
        getEntries();
        break;
      }
      case 'entriesvoted':{
        setDetailsTitle("Entries Voted")
        getVotes();
        break;
      }
      default: {
        console.log('nothing to handle' + whatDetails);
      }
    }
  }

  const drawWinners = () => {
    var winner = eligableEntries[Math.floor(Math.random() * eligableEntries.length)];
    var localWinners = _.clone(winners);
    localWinners.push(winner);
    setWinners(localWinners);
  }

  return (
    <Grid container>
      <TitleBar title="Competition Results" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {!pageLoading && (
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{mt: 3}}>
              <h2>{competition.name}</h2>
            </Grid>
            <Grid item xs={7}>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{fontWeight: 'bold'}}>
                  {compOpened && !compClosed && (
                  <Box>
                    Competition Open  
                  </Box>
                  )}
                  {!compOpened && (
                    <>
                    Competition opens: <Countdown date={competition.openDate} onComplete={handleCountdownCompleteCompOpen} />
                    </>
                  )}
                </Grid>
                <Grid item xs={6} sx={{textAlign: 'right', fontWeight: 'bold'}}>
                  {compClosed && (
                  <Box>
                    Competition Closed  
                  </Box>
                  )}
                  {!compClosed && (
                    <>
                    Competition closes: <Countdown date={competition.endDate} onComplete={handleCountdownCompleteCompClosed} />
                    </>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <CompCard digit={competition.totalEntries} title="Total Entries" whatDetails="totalentries" handleLoadDetails={handleLoadDetails}></CompCard>
                </Grid>
                <Grid item xs={4}>
                  <CompCard digit={competition.totalEntriesVoted} title="Entries Voted" whatDetails="entriesvoted" handleLoadDetails={handleLoadDetails}></CompCard>
                </Grid>
                <Grid item xs={4}>
                  <CompCard digit={competition.totalEntriesRated} title="Entries Rated" ></CompCard>
                </Grid>
                <Grid item xs={4}>
                  <CompCard digit={competition.totalEntriesAnswered} title="Entries Answered"></CompCard>
                </Grid>
                <Grid item xs={4}>
                  <CompCard digit={competition.totalEntriesCorrect} title="Answered Correct" ></CompCard>
                </Grid>
                <Grid item xs={4}>
                  <CompCard digit={competition.totalEntries - competition.totalEntriesCorrect} title="Answered Incorrect" ></CompCard>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                      {loadingLeaderboard && (
                        <Box sx={{ width: '100%', mt: 4 }}>
                          <LinearProgress />
                        </Box>
                      )}
                      {!loadingLeaderboard && playerLeaderboard && (
                        <>
                        <Box sx={{ mt: 4, fontWeight: 'bold' }}>
                          Player of the Match Leaderboard
                        </Box>
                        {!loadingLeaderboard && playerLeaderboard && playerLeaderboard.length === 0 && (
                          <Box sx={{ width: '100%' }}>
                            No player votes have been received 
                          </Box>
                        )}
                        {!loadingLeaderboard && playerLeaderboard && playerLeaderboard.length > 0 && (
                        <TableContainer component={Paper} sx={{mt: 2}}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Player</TableCell>
                                <TableCell>Votes</TableCell>                          
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {playerLeaderboard.map((detail) => (
                                <TableRow
                                  key={detail.id}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                  {detail.playerName}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                  {detail.voteCount}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        )}
                        </>
                      )}
                      </Grid>
                      <Grid item xs={12}>
                      {loadingEntries && eligableEntries && (
                        <Box sx={{ width: '100%', mt: 4 }}>
                          <LinearProgress />
                        </Box>
                      )}
                      {!loadingEntries && eligableEntries && (
                        <>
                        <Box sx={{ fontWeight: 'bold' }}>
                          Eligable Entries
                        </Box>
                        {!loadingEntries && eligableEntries && eligableEntries.length === 0 && (
                          <Box sx={{ width: '100%' }}>
                            No player votes have been received 
                          </Box>
                        )}
                        {!loadingEntries && eligableEntries && eligableEntries.length > 0 && (
                        <TableContainer component={Paper} sx={{mt: 2}}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>User</TableCell>
                                <TableCell></TableCell>                          
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {eligableEntries.map((eligableEntry) => (
                                <TableRow
                                  key={eligableEntry.id}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                  {eligableEntry.userName}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                  
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        )}
                        <Button
                          type="button"
                          fullWidth
                          variant="contained"
                          style={enabledStyle}
                          sx={{mt: 2}}
                          onClick={drawWinners}
                        >
                          Draw Winner
                        </Button>
                        </>
                      )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    {competition && club && breakdown && (
                    <>
                    <Grid container spacing={2} sx={{marginTop: 7}}>
                      <Grid item xs={6}>
                        <CompCard digit={"£" + (competition.ticketIncome === null ? 0 : competition.ticketIncome)}  title="Ticket Income" hideCardActions={true}></CompCard>
                      </Grid>
                      <Grid item xs={6}>
                        <CompCard digit={"£" + competition.sponsorCommitment} title="Sponsor Income" hideCardActions={true}></CompCard>
                      </Grid>
                    </Grid>
                    <TableContainer component={Paper} sx={{mt: 2}}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Detail</TableCell>
                            <TableCell>Amount</TableCell>                          
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Player share of Sponsorship
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{textAlign: 'right'}}>
                              £{breakdown.playerShareOfSponsorship.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Fan share of Sponsorship
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{textAlign: 'right'}}>
                              £{breakdown.fanShareOfSponsorship.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Player share of Ticket Income
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{textAlign: 'right'}}>
                              £{breakdown.playerShareOfTicketIncome.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Fan share of Ticket Income
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{textAlign: 'right'}}>
                              £{breakdown.fanShareOfTicketIncome.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Fanhive Fees
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{textAlign: 'right'}}>
                              £{breakdown.fanhiveFees.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Club Fees
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{textAlign: 'right'}}>
                            £{breakdown.clubFees.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                        <>
                        <Box sx={{ fontWeight: 'bold', mt: 2 }}>
                          Winners
                        </Box>
                        <TableContainer component={Paper} sx={{mt: 2}}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>User</TableCell>
                                <TableCell></TableCell>                          
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {winners.map((winner) => (
                                <TableRow
                                  key={winner.id}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                  {winner.userName}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                  
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </>
                    </>
                    )}          
                  </Grid>                  
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{pt: 5}}>
                <RouterLink to={"/manage-competitions/"}>Back to Competitions</RouterLink>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              {!loadingDetails && details && details.length > 0 && (
                <>
                  <Box sx={{ mt: 4, fontWeight: 'bold' }}>
                    {detailsTitle}
                  </Box>
                  <TableContainer component={Paper} sx={{mt: 2}}>
                    <Table aria-label="simple table">
                      <TableHead>
                        {whatDetails === "totalentries" && (
                        <TableRow>
                          <TableCell>User</TableCell>
                          <TableCell>Date Created</TableCell>                          
                        </TableRow>
                        )}
                        {whatDetails === "entriesvoted" && (
                        <TableRow>
                          <TableCell>User</TableCell>
                          <TableCell>Voted For</TableCell>                          
                        </TableRow>
                        )}
                      </TableHead>
                      <TableBody>
                        {details.map((detail) => (
                          <TableRow
                            key={detail.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            {whatDetails === "totalentries" && (
                            <>
                            <TableCell component="th" scope="row">
                              {detail.userName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Moment format="YYYY/MM/DD">
                                {detail.dateCreated}
                              </Moment>
                            </TableCell>
                            </>
                            )}
                            {whatDetails === "entriesvoted" && (
                            <>
                            <TableCell component="th" scope="row">
                              {detail.createdBy}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {detail.playerName}
                            </TableCell>
                            </>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
              {loadingDetails && (
                <Box sx={{ width: '100%', mt: 4 }}>
                  <LinearProgress />
                </Box>
              )}
              {details && details.length === 0 && (
                <div>
                  <h3>No Entries.</h3>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}