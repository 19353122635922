import React, { useState, useEffect, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TitleBar } from '../Shared/TitleBar';
import { Grid, Box, LinearProgress, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

export const ManageClubs = () => {
  const navigate = useNavigate();
  const [clubs, setClubs] = useState(null);

  const getClubs = useCallback((clubs) => {
    console.log('Getting clubs');
    
    if(clubs)
      setClubs(null);

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setClubs(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status === 401) {
          navigate('/log-out');
        }
      });
  }, [navigate]);

  useEffect((clubs) => {
    console.log('using effect in manage clubs component');
    getClubs(clubs);
  }, [getClubs]);

  const handleRefresh = () => {
    getClubs(clubs);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Manage Clubs"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">
          <RouterLink to="/create-club">Create Club</RouterLink>
        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {clubs && clubs.length > 0 && (
          <TableContainer component={Paper} sx={{mt: 2}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Logo</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Default Sport</TableCell>
                  <TableCell>Rate Card</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clubs.map((club) => (
                  <TableRow
                    key={club.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {club.logoUrl && !club.ballColour && (
                      <img src={club.logoUrl} alt={club.name} style={{height:'30px'}}></img>
                      )}
                      {club.ballColour && (
                      <img src={'https://fanhive-balls.s3.eu-west-2.amazonaws.com/ball-' + club.ballColour + '.png'} alt={club.name} style={{height:'30px'}}></img>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <RouterLink to={"/update-club/" + club.id}>{club.name}</RouterLink>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div>{club.addressLine1}</div>
                      {club.addressLine1 && (
                      <div>{club.addressLine2}</div>
                      )}
                      <div>{club.town}</div>
                      <div>{club.county}</div>
                      <div>{club.country}</div>
                      <div>{club.postalCode}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {club.phoneNumber && (
                      <Grid container>
                        <Grid item xs={3}><PhoneIcon></PhoneIcon></Grid>
                        <Grid item xs={9}>{club.phoneNumber}</Grid>
                      </Grid>
                      )}
                      {club.email && (
                      <Grid container>
                        <Grid item xs={3}><EmailIcon></EmailIcon></Grid>
                        <Grid item xs={9}>{club.email}</Grid>
                      </Grid>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {club.defaultSportName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {club.rateCardName}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )}
          {!clubs && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {clubs && clubs.length === 0 && (
            <div>
              <h3>No Clubs.</h3>
              <div>Try Refresh or use the "Create Club" link above to create one.</div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}