import React, {useEffect, useState} from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import {Grid, LinearProgress} from '@mui/material';
import { TitleBar } from '../Shared/TitleBar';
import Box from '@mui/material/Box';

export const PaymentResult = () => {
  const [balanceUpdated, setBalanceUpdated] = useState(false);
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  var result = "";
  if (query.get("result") != null) {
    result = query.get("result");
  }

  useEffect(() => {
    console.log('using effect in payment result');    
    setBalanceUpdated(true);
  }, []);
  
  return (
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title={result === "approved" ? "Payment Approved" : "Payment Declined"}></TitleBar>
          {!balanceUpdated && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
        </Grid>
        {balanceUpdated && (
          <>
            <Grid item xs={12}>
            {result === "approved" && (
              <h3>Your transaction was Approved and your balance has been updated.</h3>
            )}
            {result === "declined" && (
              <h3>Your transaction was Declined.</h3>
            )}
            </Grid>
            <Grid item xs={12}>
              <RouterLink to="/competitions">Go to Competitions</RouterLink>
            </Grid>
          </>
        )}
      </Grid>
  );
}