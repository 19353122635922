import React, { useContext, useEffect, useCallback } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HiveIcon from '@mui/icons-material/Hive';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

import { Route, Routes } from 'react-router-dom';
import Container from '@mui/material/Container';

import { PublicList } from "./RoleLists/PublicList";
import { PrivateList } from "./RoleLists/PrivateList";

import { Register } from "./Register";
import { Login } from "./Login";
import { Logout } from "./Logout";
import { Users } from "./Users";
import { ConfirmUser } from './ConfirmUser';
import { CreateSport } from './Sports/CreateSport';
import { ManageSports } from './Sports/ManageSports';
import { UpdateSport } from './Sports/UpdateSport';
import { CreateRateCard } from './RateCards/CreateRateCard';
import { ManageRateCards } from './RateCards/ManageRateCards';
import { UpdateRateCard } from './RateCards/UpdateRateCard';
import { CreateClub } from './Clubs/CreateClub';
import { ManageClubs } from './Clubs/ManageClubs';
import { UpdateClub } from './Clubs/UpdateClub';
import { CreatePosition } from './Positions/CreatePosition';
import { ManagePositions } from './Positions/ManagePositions';
import { UpdatePosition } from './Positions/UpdatePosition';
import { CreateTeam } from './Teams/CreateTeam';
import { ManageTeams } from './Teams/ManageTeams';
import { UpdateTeam } from './Teams/UpdateTeam';
import { CreateLeagueDivision } from './LeagueDivisions/CreateLeagueDivision';
import { ManageLeagueDivisions } from './LeagueDivisions/ManageLeagueDivisions';
import { UpdateLeagueDivision } from './LeagueDivisions/UpdateLeagueDivision';
import { CreateCup } from './Cups/CreateCup';
import { ManageCups } from './Cups/ManageCups';
import { UpdateCup } from './Cups/UpdateCup';
import { CreateUser } from './Users/CreateUser';
import { ManageUsers } from './Users/ManageUsers';
import { UpdateUser } from './Users/UpdateUser';
import { CreatePlayer } from './Players/CreatePlayer';
import { ManagePlayers } from './Players/ManagePlayers';
import { UpdatePlayer } from './Players/UpdatePlayer';
import { CreateTeamPlayer } from './TeamPlayers/CreateTeamPlayer';
import { ManageTeamPlayers } from './TeamPlayers/ManageTeamPlayers';
import { UpdateTeamPlayer } from './TeamPlayers/UpdateTeamPlayer';
import { CreateMatch } from './Matches/CreateMatch';
import { ManageMatches } from './Matches/ManageMatches';
import { UpdateMatch } from './Matches/UpdateMatch';
import { CreateMatchPlayer } from './MatchPlayers/CreateMatchPlayer';
import { ManageMatchPlayers } from './MatchPlayers/ManageMatchPlayers';
import { UpdateMatchPlayer } from './MatchPlayers/UpdateMatchPlayer';
import { CreateSponsor } from './Sponsors/CreateSponsor';
import { ManageSponsors } from './Sponsors/ManageSponsors';
import { UpdateSponsor } from './Sponsors/UpdateSponsor';
import { CreateCompetition } from './Competitions/CreateCompetition';
import { ManageCompetitions } from './Competitions/ManageCompetitions';
import { UpdateCompetition } from './Competitions/UpdateCompetition';
import { CompetitionResults } from './Competitions/CompetitionResults';
import { CreateEntry } from './Entries/CreateEntry';
import { ManageEntries } from './Entries/ManageEntries';
import { UpdateEntry } from './Entries/UpdateEntry';
import { CreateQuestion } from './Questions/CreateQuestion';
import { ManageQuestions } from './Questions/ManageQuestions';
import { UpdateQuestion } from './Questions/UpdateQuestion';
import { CreateQuestionAnswer } from './QuestionAnswers/CreateQuestionAnswer';
import { ManageQuestionAnswers } from './QuestionAnswers/ManageQuestionAnswers';
import { UpdateQuestionAnswer } from './QuestionAnswers/UpdateQuestionAnswer';
import { CreateCompetitionQuestion } from './CompetitionQuestions/CreateCompetitionQuestion';
import { ManageCompetitionQuestions } from './CompetitionQuestions/ManageCompetitionQuestions';
import { UpdateCompetitionQuestion } from './CompetitionQuestions/UpdateCompetitionQuestion';
import { CreateWallet } from './Wallets/CreateWallet';
import { ManageWallets } from './Wallets/ManageWallets';
import { UpdateWallet } from './Wallets/UpdateWallet';
import { TopUp } from './Wallets/TopUp';
import { PaymentStub } from './Wallets/PaymentStub';
import { PaymentResult } from './Wallets/PaymentResult';
import { CreateWalletTransaction } from './WalletTransactions/CreateWalletTransaction';
import { ManageWalletTransactions } from './WalletTransactions/ManageWalletTransactions';
import { UpdateWalletTransaction } from './WalletTransactions/UpdateWalletTransaction';
import { CreateEntryQuestionAnswer } from './EntryQuestionAnswers/CreateEntryQuestionAnswer';
import { ManageEntryQuestionAnswers } from './EntryQuestionAnswers/ManageEntryQuestionAnswers';
import { UpdateEntryQuestionAnswer } from './EntryQuestionAnswers/UpdateEntryQuestionAnswer';
import { PublicHome } from './HomePages/PublicHome';
import { AdminHome } from './HomePages/AdminHome';
import { ClubAdminHome } from './HomePages/ClubAdminHome';
import { UserHome } from './HomePages/UserHome';
import { Complete } from './Pages/Complete';
import { Competitions } from './CompetitionEntry/Competitions';
import { Questions } from './CompetitionEntry/Questions';
import { QuestionsTimesUp } from './CompetitionEntry/QuestionsTimesUp';
import { Entered } from './CompetitionEntry/Entered';
import { EntryHome } from './CompetitionEntry/EntryHome';
import { Voting } from './CompetitionEntry/Voting';
import { Rating } from './CompetitionEntry/Rating';
import { Completed } from './CompetitionEntry/Completed';
import { HoneyPointsHome } from './HoneyPoints/HoneyPointsHome';
import { PlayerRatingsHome } from './PlayerRatings/PlayerRatingsHome';
import { CreateHoneyPoint } from './HoneyPoints/CreateHoneyPoint';
import { ManageHoneyPoints } from './HoneyPoints/ManageHoneyPoints';
import { UpdateHoneyPoint } from './HoneyPoints/UpdateHoneyPoint';
import { HoneyPointTransactions } from './HoneyPointTransactions/HoneyPointTransactions';
import { CompetitionHome } from './Competitions/CompetitionHome';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Link as RouterLink } from 'react-router-dom';
import { LoginContext } from '../contexts/LoginContext';
import Button from '@mui/material/Button';
import RequireAuth from './RequireAuth';

import Logo from '../images/logo.png';
import LogoIcon from '../images/logo_icon.png';
import axios from 'axios';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function SiteContainer() {

  const theme = createTheme({
    palette: {
      primary: {
        light: '#fdb611',
        main: '#fdb611',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#495563',
        main: '#495563',
        dark: '#495563',
        contrastText: '#000',
      },
    },
  });

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [refreshingCredits, setRefreshingCredits] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const { isLoggedIn, userSub, wallet, setWallet } = useContext(LoginContext);

  const mainInterval = useCallback((wallet) => {
    console.log('Syncing balances');
    // console.log(localStorage.getItem("wallet"));
    // if(wallet && !localStorage.getItem("wallet")){
    //   console.log('Wallet removed, must have logged out');
    //   return;
    // }
    if (userSub && wallet && (wallet.balance !== wallet.expectedBalance || wallet.honeyPointsBalance !== wallet.expectedHoneyPointsBalance)) {
      console.log('Balances HAVE changed, syncing...');
      setRefreshingCredits(true);
      axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallets?userId=' + userSub.id, {
        headers: {
          'Authorization': localStorage.getItem('jwtToken')
        }
      })
        .then(function (response) {
          var localWallet = response.data[0];
          if (localWallet.balance !== wallet.expectedBalance || localWallet.honeyPointsBalance !== wallet.expectedHoneyPointsBalance) {
            console.log('Balances out of sync');
            setTimeout(() => {
              mainInterval(wallet);
            }, 3000);
          } else {
            console.log('Balances in sync, setting new wallet');
            var newWallet = {
              id: localWallet.id,
              balance: localWallet.balance,
              honeyPointsBalance: localWallet.honeyPointsBalance,
              expectedBalance: wallet.expectedBalance,
              expectedHoneyPointsBalance: wallet.expectedHoneyPointsBalance
            };
            setWallet(newWallet);
            localStorage.setItem("wallet", JSON.stringify(newWallet));
            setRefreshingCredits(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      console.log('Balances have NOT changed');
    }
  }, [userSub, setWallet]);

  useEffect(() => {
    console.log('*****WALLET HAS CHANGED******');
    mainInterval(wallet);
  }, [wallet, mainInterval]);

  useEffect((wallet) => {
    console.log('using effect in site container');
    
  }, [userSub, setWallet, mainInterval]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {isLoggedIn && (
        <MenuItem component={RouterLink} to="/log-out" onClick={handleMenuClose}>{'Log Out'}</MenuItem>
      )}
    </Menu>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ background: '#495563' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ mt: 1 }}>
            <RouterLink to="/"><img src={LogoIcon} height="45px" alt="Fanhive"></img></RouterLink>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {isLoggedIn && (
            <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
              {wallet && wallet.balance >= 0 && (
                  <Button color="inherit" component={RouterLink} to="/top-up">
                    <LocalActivityIcon />: 
                    {refreshingCredits && (
                      <CircularProgress
                        size={18}
                        sx={{
                          color: '#ffffff',
                          marginLeft: '3px'
                        }}
                      />
                    )}
                    {!refreshingCredits && (
                      <> {wallet.balance}</>
                    )}
                  </Button>
              )}
              {wallet && wallet.honeyPointsBalance >= 0 && (
                <Button color="inherit" sx={{ color: '#fdb611' }} component={RouterLink} to="/honey-point-transactions">
                    <HiveIcon />: 
                    {refreshingCredits && (
                      <CircularProgress
                        size={18}
                        sx={{
                          color: '#fdb611',
                          marginLeft: '3px'
                        }}
                      />
                    )}
                    {!refreshingCredits && (
                      <> {wallet.honeyPointsBalance}</>
                    )}
                  </Button>
              )}
              {userSub.adminClubLogo && (
                <img src={userSub.adminClubLogo} height="45px" alt="Whitby Town FC"></img>
              )}
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
            </Box>
          )}
          {!isLoggedIn && (
            <>
              <Button color="inherit" component={RouterLink} to="/login">Login</Button>
              <Button color="inherit" component={RouterLink} to="/register">Register</Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      {renderMenu}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box sx={{ mt: 1 }}>
            <RouterLink to="/"><img src={Logo} height="35px" alt="Fanhive"></img></RouterLink>
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {(isLoggedIn) && (
          <PrivateList isLoggedIn={isLoggedIn} userSub={userSub}></PrivateList>
        )}
        {(!isLoggedIn) && (
          <PublicList isLoggedIn={isLoggedIn} userSub={userSub}></PublicList>
        )}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Container fixed>
          <ThemeProvider theme={theme}>
            <div className="context">
              <Routes>
                <Route path="/" element={<PublicHome></PublicHome>} />
                <Route path="/register" element={<Register></Register>} />
                <Route path="/confirm-user" element={<ConfirmUser></ConfirmUser>} />
                <Route path="/users" element={<Users></Users>} />
                <Route path="/login" element={<Login></Login>} />
                <Route path="/log-out" element={<RequireAuth><Logout /></RequireAuth>} />
                <Route path="/admin-home" element={<RequireAuth><AdminHome /></RequireAuth>} />
                <Route path="/club-admin-home" element={<RequireAuth><ClubAdminHome /></RequireAuth>} />
                <Route path="/user-home" element={<RequireAuth><UserHome /></RequireAuth>} />
                <Route path="/manage-sports" element={<RequireAuth><ManageSports /></RequireAuth>} />
                <Route path="/create-sport" element={<RequireAuth><CreateSport /></RequireAuth>} />
                <Route path="/update-sport/:id" element={<RequireAuth><UpdateSport /></RequireAuth>} />
                <Route path="/manage-clubs" element={<RequireAuth><ManageClubs /></RequireAuth>} />
                <Route path="/create-club" element={<RequireAuth><CreateClub /></RequireAuth>} />
                <Route path="/update-club/:id" element={<RequireAuth><UpdateClub /></RequireAuth>} />
                <Route path="/manage-rate-cards" element={<RequireAuth><ManageRateCards /></RequireAuth>} />
                <Route path="/create-rate-card" element={<RequireAuth><CreateRateCard /></RequireAuth>} />
                <Route path="/update-rate-card/:id" element={<RequireAuth><UpdateRateCard /></RequireAuth>} />
                <Route path="/manage-positions" element={<RequireAuth><ManagePositions /></RequireAuth>} />
                <Route path="/create-position" element={<RequireAuth><CreatePosition /></RequireAuth>} />
                <Route path="/update-position/:id" element={<RequireAuth><UpdatePosition /></RequireAuth>} />
                <Route path="/manage-teams" element={<RequireAuth><ManageTeams /></RequireAuth>} />
                <Route path="/create-team" element={<RequireAuth><CreateTeam /></RequireAuth>} />
                <Route path="/update-team/:id" element={<RequireAuth><UpdateTeam /></RequireAuth>} />
                <Route path="/manage-league-divisions" element={<RequireAuth><ManageLeagueDivisions /></RequireAuth>} />
                <Route path="/create-league-division" element={<RequireAuth><CreateLeagueDivision /></RequireAuth>} />
                <Route path="/update-league-division/:id" element={<RequireAuth><UpdateLeagueDivision /></RequireAuth>} />
                <Route path="/manage-cups" element={<RequireAuth><ManageCups /></RequireAuth>} />
                <Route path="/create-cup" element={<RequireAuth><CreateCup /></RequireAuth>} />
                <Route path="/update-cup/:id" element={<RequireAuth><UpdateCup /></RequireAuth>} />
                <Route path="/manage-users" element={<RequireAuth><ManageUsers /></RequireAuth>} />
                <Route path="/create-user" element={<RequireAuth><CreateUser /></RequireAuth>} />
                <Route path="/update-user/:id" element={<RequireAuth><UpdateUser /></RequireAuth>} />
                <Route path="/manage-players" element={<RequireAuth><ManagePlayers /></RequireAuth>} />
                <Route path="/create-player" element={<RequireAuth><CreatePlayer /></RequireAuth>} />
                <Route path="/update-player/:id" element={<RequireAuth><UpdatePlayer /></RequireAuth>} />
                <Route path="/manage-team-players" element={<RequireAuth><ManageTeamPlayers /></RequireAuth>} />
                <Route path="/create-team-player" element={<RequireAuth><CreateTeamPlayer /></RequireAuth>} />
                <Route path="/update-team-player/:id" element={<RequireAuth><UpdateTeamPlayer /></RequireAuth>} />
                <Route path="/manage-matches" element={<RequireAuth><ManageMatches /></RequireAuth>} />
                <Route path="/create-match" element={<RequireAuth><CreateMatch /></RequireAuth>} />
                <Route path="/update-match/:id" element={<RequireAuth><UpdateMatch /></RequireAuth>} />
                <Route path="/manage-match-players" element={<RequireAuth><ManageMatchPlayers /></RequireAuth>} />
                <Route path="/create-match-player" element={<RequireAuth><CreateMatchPlayer /></RequireAuth>} />
                <Route path="/update-match-player/:id" element={<RequireAuth><UpdateMatchPlayer /></RequireAuth>} />
                <Route path="/manage-sponsors" element={<RequireAuth><ManageSponsors /></RequireAuth>} />
                <Route path="/create-sponsor" element={<RequireAuth><CreateSponsor /></RequireAuth>} />
                <Route path="/update-sponsor/:id" element={<RequireAuth><UpdateSponsor /></RequireAuth>} />
                <Route path="/manage-competitions" element={<RequireAuth><ManageCompetitions /></RequireAuth>} />
                <Route path="/create-competition" element={<RequireAuth><CreateCompetition /></RequireAuth>} />
                <Route path="/update-competition/:id" element={<RequireAuth><UpdateCompetition /></RequireAuth>} />
                <Route path="/competition-results/:id" element={<RequireAuth><CompetitionResults /></RequireAuth>} />
                <Route path="/competition-home/:id" element={<RequireAuth><CompetitionHome /></RequireAuth>} />
                <Route path="/manage-entries" element={<RequireAuth><ManageEntries /></RequireAuth>} />
                <Route path="/create-entry" element={<RequireAuth><CreateEntry /></RequireAuth>} />
                <Route path="/update-entry/:id" element={<RequireAuth><UpdateEntry /></RequireAuth>} />
                <Route path="/manage-questions" element={<RequireAuth><ManageQuestions /></RequireAuth>} />
                <Route path="/create-question" element={<RequireAuth><CreateQuestion /></RequireAuth>} />
                <Route path="/update-question/:id" element={<RequireAuth><UpdateQuestion /></RequireAuth>} />
                <Route path="/manage-question-answers" element={<RequireAuth><ManageQuestionAnswers /></RequireAuth>} />
                <Route path="/create-question-answer" element={<RequireAuth><CreateQuestionAnswer /></RequireAuth>} />
                <Route path="/update-question-answer/:id" element={<RequireAuth><UpdateQuestionAnswer /></RequireAuth>} />
                <Route path="/manage-competition-questions" element={<RequireAuth><ManageCompetitionQuestions /></RequireAuth>} />
                <Route path="/create-competition-question" element={<RequireAuth><CreateCompetitionQuestion /></RequireAuth>} />
                <Route path="/update-competition-question/:id" element={<RequireAuth><UpdateCompetitionQuestion /></RequireAuth>} />
                <Route path="/manage-wallets" element={<RequireAuth><ManageWallets /></RequireAuth>} />
                <Route path="/create-wallet" element={<RequireAuth><CreateWallet /></RequireAuth>} />
                <Route path="/update-wallet/:id" element={<RequireAuth><UpdateWallet /></RequireAuth>} />
                <Route path="/top-up" element={<RequireAuth><TopUp /></RequireAuth>} />
                <Route path="/payment-stub" element={<RequireAuth><PaymentStub /></RequireAuth>} />
                <Route path="/payment-result" element={<RequireAuth><PaymentResult /></RequireAuth>} />
                <Route path="/manage-wallet-transactions" element={<RequireAuth><ManageWalletTransactions /></RequireAuth>} />
                <Route path="/create-wallet-transaction" element={<RequireAuth><CreateWalletTransaction /></RequireAuth>} />
                <Route path="/update-wallet-transaction/:id" element={<RequireAuth><UpdateWalletTransaction /></RequireAuth>} />
                <Route path="/manage-entry-question-answers" element={<RequireAuth><ManageEntryQuestionAnswers /></RequireAuth>} />
                <Route path="/create-entry-question-answer" element={<RequireAuth><CreateEntryQuestionAnswer /></RequireAuth>} />
                <Route path="/update-entry-question-answer/:id" element={<RequireAuth><UpdateEntryQuestionAnswer /></RequireAuth>} />
                <Route path="/complete" element={<RequireAuth><Complete /></RequireAuth>} />
                <Route path="/competitions" element={<RequireAuth><Competitions /></RequireAuth>} />
                <Route path="/questions" element={<RequireAuth><Questions /></RequireAuth>} />
                <Route path="/questions-times-up" element={<RequireAuth><QuestionsTimesUp /></RequireAuth>} />
                <Route path="/entered" element={<RequireAuth><Entered /></RequireAuth>} />
                <Route path="/entry-home" element={<RequireAuth><EntryHome /></RequireAuth>} />
                <Route path="/voting" element={<RequireAuth><Voting /></RequireAuth>} />
                <Route path="/rating" element={<RequireAuth><Rating /></RequireAuth>} />
                <Route path="/completed" element={<RequireAuth><Completed /></RequireAuth>} />
                <Route path="/honey-points" element={<RequireAuth><HoneyPointsHome /></RequireAuth>} />
                <Route path="/player-ratings" element={<RequireAuth><PlayerRatingsHome /></RequireAuth>} />
                <Route path="/manage-honey-points" element={<RequireAuth><ManageHoneyPoints /></RequireAuth>} />
                <Route path="/create-honey-point" element={<RequireAuth><CreateHoneyPoint /></RequireAuth>} />
                <Route path="/update-honey-point/:id" element={<RequireAuth><UpdateHoneyPoint /></RequireAuth>} />
                <Route path="/honey-point-transactions" element={<RequireAuth><HoneyPointTransactions /></RequireAuth>} />
              </Routes>
            </div>
          </ThemeProvider>
        </Container>
      </Main>
    </Box>
  );
}