import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';
import { LoginContext } from '../../contexts/LoginContext';

export const CreateQuestion = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  const [question, setQuestion] = useState({
    clubId: userSub.adminClubId === null ? "" : userSub.adminClubId,
    sportId: "0",
    name: ""
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [sports, setSports] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setQuestion(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {

    console.log('Getting clubs');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setClubs(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    console.log('Getting sports');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setSports(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [])

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/questions', {
      ...question,
      sportId: question.sportId === "0" ? null : question.sportId, 
      clubId: question.clubId === "0" ? null : question.clubId
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Question&action=created&name=' + question.name + '&id=' + response.data.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);

    if ((field === "name" || field === "") && question.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }

    return !hasErrors;
  }

  return (
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Create Question"></TitleBar>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            {userSub.adminClubId === null && (
            <FormControl margin="normal" fullWidth>
              <InputLabel id="clubIdLabel">Club</InputLabel>
              <Select
                labelId="clubIdLabel"
                id="clubId"
                name="clubId"
                value={question.clubId}
                label="Club"
                onChange={handleFormChange}
              >
                <MenuItem value="0">N/A</MenuItem>
                {clubs && clubs.map((club) => (
                  <MenuItem value={club.id} key={club.id}>{club.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            )}
            <FormControl margin="normal" fullWidth>
              <InputLabel id="sportIdLabel">Sport</InputLabel>
              <Select
                labelId="sportIdLabel"
                id="sportId"
                name="sportId"
                value={question.sportId}
                label="Sport"
                onChange={handleFormChange}
              >
                <MenuItem value="0">N/A</MenuItem>
                {sports && sports.map((sport) => (
                  <MenuItem value={sport.id} key={sport.id}>{sport.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              error={nameError}
              helperText={nameHelperText}
              name="name"
              required
              fullWidth
              id="name"
              label="Question"
              value={question.name}
              onChange={handleFormChange}
              onBlur={() => validateForm("name")}
            />
            <SubmitButton loading={loading}></SubmitButton>
            <AddBar cancelTo="/manage-questions"></AddBar>
          </Box>
        </Grid>
      </Grid>
  );
}