import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';

export const CreateEntryQuestionAnswer = () => {
  const navigate = useNavigate();
  const [entryQuestionAnswer, setEntryQuestionAnswer] = useState({
    entryId: "",
    questionAnswerId: ""
  });
  const [entryIdError, setEntryIdError] = useState(false);
  const [entryIdHelperText, setEntryIdHelperText] = useState("");
  const [questionAnswerIdError, setQuestionAnswerIdError] = useState(false);
  const [questionAnswerIdHelperText, setQuestionAnswerIdHelperText] = useState("");
  const [entries, setEntries] = useState(null);
  const [questionAnswers, setQuestionAnswers] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setEntryQuestionAnswer(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    console.log('using effect in create entryQuestionAnswer');

    console.log('Getting entries');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setEntries(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    console.log('Getting questionanswers');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/question-answers?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setQuestionAnswers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entry-question-answers', entryQuestionAnswer, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-entry-question-answers');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setEntryIdError(false);
    setQuestionAnswerIdError(false);

    if ((field === "entryId" || field === "") && entryQuestionAnswer.entryId === '') {
      hasErrors = true;
      setEntryIdError(true);
      setEntryIdHelperText("Entry is required");
    }
    if ((field === "questionAnswerId" || field === "") && entryQuestionAnswer.questionAnswerId === '') {
      hasErrors = true;
      setQuestionAnswerIdError(true);
      setQuestionAnswerIdHelperText("Question Answer is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Create Question Answer"></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="entryIdLabel">Entry</InputLabel>
            <Select
              labelId="entryIdLabel"
              error={entryIdError}
              id="entryId"
              name="entryId"
              value={entryQuestionAnswer.entryId}
              label="Entry"
              onChange={handleFormChange}
            >
              {entries && entries.map((entry) => (
                <MenuItem value={entry.id} key={entry.id}>{entry.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{entryIdHelperText}</FormHelperText>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="questionAnswerIdLabel">Question Answer</InputLabel>
            <Select
              labelId="questionAnswerIdLabel"
              error={questionAnswerIdError}
              id="questionAnswerId"
              name="questionAnswerId"
              value={entryQuestionAnswer.questionAnswerId}
              label="Question Answer"
              onChange={handleFormChange}
            >
              {questionAnswers && questionAnswers.map((questionAnswer) => (
                <MenuItem value={questionAnswer.id} key={questionAnswer.id}>{questionAnswer.name}</MenuItem>
              ))}
              </Select>
            <FormHelperText error>{questionAnswerIdHelperText}</FormHelperText>
          </FormControl>
          <SubmitButton loading={loading}></SubmitButton>
          <AddBar cancelTo="/manage-entry-question-answers"></AddBar>
        </Box>
      </Grid>
    </Grid>
  );
}