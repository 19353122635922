import React, { useEffect, useState } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Grid, Box, LinearProgress } from '@mui/material';
import Moment from 'react-moment';
import { TitleBar } from '../Shared/TitleBar';
import axios from 'axios';

export const Completed = () => {
  const [entry, setEntry] = useState(null);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  var entryId = "";
  if (query.get("entryId") != null) {
    entryId = query.get("entryId");
  }

  useEffect(() => {
    console.log('using effect in completed');
    axios.get(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries/${entryId}`, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setEntry(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [entryId]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title={`Thank you! Step 2 Complete`}></TitleBar>
          <h2>Your player ratings have been submitted.</h2>
        </Grid>
        <Grid item xs={12}>
          {entry && (
            <div>
              <p>
                You have completed Step 2 of the <span className="compDynamicWord">{entry.competitionName}</span>, <span className="compDynamicWord"><Moment format="dddd, D MMMM yyyy">
            {entry.matchStartDate}
          </Moment></span> Competition
              </p>
              <p>
                The <span className="compDynamicWord">{entry.clubName}</span> Competition Winners, updated Fan Honey Scores and Player ratings will be announced 
                at <span className="compDynamicWord"><Moment format="HH:mm">
              {entry.competitionEndDate}
            </Moment></span>.
              </p>
              <p>
                The more you participate, the more Fanhive honey points you earn.
              </p>
              <p>
                <RouterLink to="/honey-points">View the Nantwich FC Fans honey point scores in Honey Scores</RouterLink>                
                <br></br>
                <RouterLink to="/player-ratings">View the Nantwich FC Player ratings in Player Ratings</RouterLink>
              </p>
            </div>
          )}
          {!entry && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
        </Grid>
      </Grid>
    </div >
  );
}