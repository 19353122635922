import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LoginContext } from '../../contexts/LoginContext';

export const CreateSponsor = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  const [sponsor, setSponsor] = useState({
    name: "",
    clubId: userSub.adminClubId === null ? "0" : userSub.adminClubId,
    logoUrl: "",
    website: ""
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [clubs, setClubs] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setSponsor(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update team');

    if(userSub.adminClubId === null) {
      console.log('Getting clubs');
      axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs?sortBy=name&sortByDirection=asc', {
        headers: {
          'Authorization': localStorage.getItem('jwtToken')
        }
      })
        .then(function (response) {
          setClubs(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [userSub]);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sponsors', {
      ...sponsor,
      clubId: PrepProp(sponsor.clubId) === "0" ? null : sponsor.clubId,
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate(`/complete?entity=Sponsor&action=created&name=${sponsor.name}&id=${response.data.id}`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    
    if ((field === "name" || field === "") && sponsor.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Create Sponsor"></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
          {userSub.adminClubId === null && (
          <FormControl margin="normal" fullWidth>
            <InputLabel id="clubIdLabel">Club</InputLabel>
            <Select
              labelId="clubIdLabel"
              id="clubId"
              name="clubId"
              value={sponsor.clubId}
              label="Club"
              onChange={handleFormChange}
            >
              <MenuItem value="0">N/A</MenuItem>
              {clubs && clubs.map((club) => (
                <MenuItem value={club.id} key={club.id}>{club.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          )}
          <TextField
            margin="normal"
            id="name"
            name="name"
            error={nameError}
            helperText={nameHelperText}
            fullWidth
            label="Name"
            value={sponsor.name}
            onChange={handleFormChange}
          />
          <TextField
            margin="normal"
            id="logoUrl"
            name="logoUrl"
            fullWidth
            label="Logo Url"
            value={sponsor.logoUrl}
            onChange={handleFormChange}
          />
          <TextField
            margin="normal"
            id="website"
            name="website"
            fullWidth
            label="Website"
            value={sponsor.website}
            onChange={handleFormChange}
          />
          <SubmitButton loading={loading}></SubmitButton>
          <AddBar cancelTo="/manage-sponsors"></AddBar>
        </Box>
      </Grid>
    </Grid>
  );
}