import React, { useState, useEffect, useCallback, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TitleBar } from '../Shared/TitleBar';
import { Grid, Box, LinearProgress, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import Moment from 'react-moment';
import { LoginContext } from '../../contexts/LoginContext';

export const HoneyPointTransactions = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  const [walletTransactions, setHoneyPointTransactions] = useState(null);

  const getHoneyPointTransactions = useCallback((walletTransactions) => {
    console.log('Getting walletTransactions');
    
    if(walletTransactions)
      setHoneyPointTransactions(null);

    var sortFilter = "sortBy=date_created&sortByDirection=desc"
    var filter = "?" + sortFilter;
    if (userSub.role === "user")
      filter = filter + "&userId=" + userSub.id;
    if (userSub.role === "clubadmin")
      filter = filter + "&clubId=" + userSub.adminClubId;

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/honey-transactions' + filter, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setHoneyPointTransactions(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status === 401) {
          navigate('/log-out');
        }
      });
  }, [navigate, userSub]);

  useEffect((walletTransactions) => {
    console.log('using effect in walletTransactions component');
    getHoneyPointTransactions(walletTransactions);
  }, [getHoneyPointTransactions]);

  const handleRefresh = () => {
    getHoneyPointTransactions(walletTransactions);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Honey Points"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">
          {userSub.role === "admin" && (
          <RouterLink to="/create-wallet-transaction">Create HoneyPoint Transaction</RouterLink>
          )}
        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {walletTransactions && walletTransactions.length > 0 && (
          <TableContainer component={Paper} sx={{mt: 2}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                <TableCell>Transaction Date</TableCell>
                  <TableCell>Activity</TableCell>
                  {userSub.role !== "user" && (
                  <TableCell>User</TableCell>
                  )}
                  <TableCell align="right">Debit</TableCell>
                  <TableCell align="right">Credit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {walletTransactions.map((walletTransaction) => (
                  <TableRow
                    key={walletTransaction.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {userSub.role === "admin" && (
                        <RouterLink to={"/update-honey-point-transaction/" + walletTransaction.id}>
                          <Moment format="YYYY/MM/DD HH:mm">
                            {walletTransaction.dateCreated}
                          </Moment>
                        </RouterLink>
                      )}
                      {userSub.role !== "admin" && (
                        <Moment format="YYYY/MM/DD HH:mm">
                          {walletTransaction.dateCreated}
                        </Moment>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {walletTransaction.honeyPointName}
                    </TableCell>
                    {userSub.role !== "user" && (
                    <TableCell component="th" scope="row">
                      {walletTransaction.userName}
                    </TableCell>
                    )}
                    <TableCell component="th" scope="row" align="right">
                      {walletTransaction.isDebit ? walletTransaction.amount : ""}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {walletTransaction.isDebit ? "" : walletTransaction.amount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )}
          {!walletTransactions && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {walletTransactions && walletTransactions.length === 0 && (
            <div>
              <h3>No HoneyPoint Transactions.</h3>
              {userSub.role === "admin" && (
                <div>Try Refresh or use the "Create Honey Point Transaction" link above to create one.</div>
              )}
              {userSub.role !== "admin" && (
                <div>Try Refresh or top-up your credits.</div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}