import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Grid, Divider, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {FormControlLabel, Box, LinearProgress} from '@mui/material';
import { TitleBar } from '../Shared/TitleBar';
import axios from 'axios';
import { LoginContext } from '../../contexts/LoginContext';
import _ from 'lodash';

export const Questions = () => {
  const navigate = useNavigate();
  const { userSub, wallet, setWallet } = useContext(LoginContext);
  var enabledStyle = { background: '#fdb611', color: '#000000' };
  const totalTimeAllowed = 180;
  const [countdownClass, setCountdownClass] = useState("countdown greenText");
  const [rem, setRem] = useState("03:00");
  const [timeUp, setTimeUp] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [start, setStart] = useState(false);
  const [entry, setEntry] = useState(null);
  const [timerIntervalPersisted, setTimerIntervalPersisted] = useState(null);
  const [answered, setAnswered] = useState(false);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  var entryId = "";
  if (query.get("entryId") != null) {
    entryId = query.get("entryId");
  }
  var retry = 0;
  if (query.get("retry") != null) {
    retry = parseInt(query.get("retry"));
  }
  const handleStart = () => {
    setStart(true);
    startQuestions();
  }

  const handleChange = (event) => {
    setAnswer(event.target.value);
    var selectedAnswer = _.find(questions[currentQuestionIndex].answers, (o) => {
      return o.questionAnswerId === event.target.value;
    })
    setTimeout(() => {
      if (answers === null) {
        var firstLocalAnswers = [
          {
            questionId: questions[currentQuestionIndex].id,
            answerId: event.target.value,
            isCorrect: selectedAnswer.isCorrect
          }];
        setAnswers(firstLocalAnswers);
      } else {
        var localAnswers = _.clone(answers);
        localAnswers.push({
          questionId: questions[currentQuestionIndex].id,
          answerId: event.target.value,
          isCorrect: selectedAnswer.isCorrect
        });
        setAnswers(localAnswers);
      }
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        clearInterval(timerIntervalPersisted);
        console.log('Cleared interval');
      }
    }, 250);
  };



  const startQuestions = () => {
    var secRemaining = totalTimeAllowed;
    var timerInterval = setInterval(() => {
      secRemaining = secRemaining - 1;

      if (secRemaining <= 30)
        setCountdownClass("countdown orangeText");
      if (secRemaining <= 10)
        setCountdownClass("countdown redText");

      var minRem = Math.floor(secRemaining/60);
      var secRem = secRemaining%60;
      setRem(`${String(minRem).padStart(2, '0')}:${String(secRem).padStart(2, '0')}`);
      if (secRemaining < 0) {
        clearInterval(timerInterval);
        setTimeUp(true);
        if (retry < 1) {
          navigate(`/questions-times-up?entryId=${entryId}&retry=${retry}&sportId=${entry.sportId}&totalQuestions=${entry.totalQuestions}`);
        } else {
          submitAnswers(answered);
        }
      }
    }, 1000);
    setTimerIntervalPersisted(timerInterval);
  }

  const submitAnswers = useCallback((answered) => {
    console.log('Submitting answers');
    if(answered){
      console.log('Dont resubmit');
      return;
    }
    setAnswered(true);
    var promises = [];
    var correctAnswers = 0;
    questions.forEach(theQuestion => {
      var questionAnswer = {
        entryId: entryId,
        userId: userSub.id,
        questionId: theQuestion.id
      };
      var theAnswer = _.find(answers, (o) => {
        return o.questionId === theQuestion.id;
      });
      if(theAnswer !== undefined){
        questionAnswer.questionAnswerId = theAnswer.answerId;
        if(theAnswer.isCorrect) {
          correctAnswers++;
        }
      }
      promises.push(axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entry-question-answers', questionAnswer, {
        headers: {
          'Authorization': localStorage.getItem('jwtToken')
        }
      }));
    });
    Promise.all(promises).then((results) => {
      console.log('Into promise');
      axios.put(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries/${entryId}`, {
        answered: true,
        correctAnswers: correctAnswers
      },{
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function () {
            console.log('Into updated entry');
            if(correctAnswers === questions.length) {
              var slug = "correct-answers"
              axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/honey-points?slug=' + slug, {
                headers: {
                  'Authorization': localStorage.getItem('jwtToken')
                }
              })
                .then(function (response3) {
                  var localHoneyPoint = response3.data[0];
                  axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/honey-transactions', {
                    userId: userSub.id,
                    walletId: wallet.id,
                    entryId: entryId,
                    isDebit: false,
                    honeyPointSlug: slug
                  }, {
                    headers: {
                      'Authorization': localStorage.getItem('jwtToken')
                    }
                  })
                    .then(function (response4) {
                      var newWallet = {
                        id: wallet.id,
                        balance: wallet.balance,
                        expectedBalance: wallet.balance,
                        honeyPointsBalance: wallet.honeyPointsBalance,
                        expectedHoneyPointsBalance: wallet.honeyPointsBalance + localHoneyPoint.points,
                      };
                      setWallet(newWallet);
                      localStorage.setItem("wallet", JSON.stringify(newWallet));
                      navigate(`/entered?entryId=${entryId}`);
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                })
                .catch(function (error) {
                  console.log(error);
                });
            } else {
              navigate(`/entered?entryId=${entryId}`);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    })
  }, [answers, entryId, questions, navigate, userSub, wallet, setWallet]);

  useEffect(() => {
    console.log('using effect 2 in questions component');
    if(answers && answers.length === questions.length) {
      console.log('Answers and Questions are equal, submitting answers');
      submitAnswers(answered);
    } else {
      console.log('Answers and Questions not equal');
    }
  }, [answers, questions, submitAnswers, answered]);

  const getEntry = useCallback((entry) => {
    console.log('Getting entry');

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries/' + entryId, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {

        if(response.data === null){
          console.log('Entry not available yet, retrying get entry');
          setTimeout(() => {
            getEntry();
          }, 2000);
        } else {
          var localEntry = response.data;
          setEntry(response.data);
          axios.get(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/questions?random=${localEntry.sportId}&limit=${localEntry.totalQuestions}&`, {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {
            var qs = [];
            response.data.forEach(question => {
              var answers = [];
              JSON.parse(question.extraData).questionAnswers.forEach(answer => {
                answers.push({ questionAnswerId: answer.questionAnswerId, name: answer.name, isCorrect: answer.isCorrect })
              });
              qs.push({ id: question.id, name: question.name, answers: answers });
            });
            setQuestions(qs);
          })
          .catch(function (error) {
            console.log(error);
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [entryId]);

  useEffect((entry) => {
    console.log('using effect in questions component');
    getEntry(entry);
  }, [getEntry]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title={start ? `Question ${currentQuestionIndex+1} of ${entry.totalQuestions}` : "Questions"}></TitleBar>
        </Grid>
        {((!start && !entry) || answered) && (
        <Grid item xs={12} align="right">
          <Box sx={{ width: '100%', mt: 4 }}>
              <LinearProgress />
          </Box>
        </Grid>
        )}
        {!start && entry && !answered && (
          <>
            <Grid item xs={12}>
              <h2>You will have {totalTimeAllowed/60} minutes to answer {entry.totalQuestions} questions</h2>
              <div>When you ready, click the "Start" button below.</div>
              <Button
                type="button"
                sx={{mt:4}}
                fullWidth
                variant="contained"
                onClick={handleStart}
                style={enabledStyle}
              >
                Start
              </Button>
            </Grid>
          </>
        )}
        {start && !answered && (
          <>
            {entry && questions && questions.length && (
              <>
                <Grid item xs={12}>
                  <h2>
                    {!timeUp && (
                      <div className={countdownClass}>Time remaining: {rem}</div>
                    )}
                    {timeUp && (
                      <div className={countdownClass}>Times up</div>
                    )}
                  </h2>
                  <h3>{questions[currentQuestionIndex].name}</h3>
                  <div>
                    <RadioGroup
                      aria-label="gender"
                      name="controlled-radio-buttons-group"
                      value={answer}
                      onChange={handleChange}
                    >
                      {questions[currentQuestionIndex].answers.map((questionAnswer) => (
                        <div key={questionAnswer.questionAnswerId}>
                          <FormControlLabel value={questionAnswer.questionAnswerId} control={<Radio />} label={questionAnswer.name + ' - ' + questionAnswer.isCorrect} />
                          <Divider sx={{ mt: 1, mb: 2 }} />
                        </div>
                      ))}
                    </RadioGroup>
                  </div>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </div>
  );
}