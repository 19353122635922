import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';
import Moment from 'react-moment';
import { LoginContext } from '../../contexts/LoginContext';
import _ from "lodash";
import moment from 'moment';

export const CreateCompetition = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  const [competition, setCompetition] = useState({
    name: "",
    matchId: "",
    entryCredits: "",
    totalQuestions: "3",
    sponsorId: "0",
    sponsorCommitment: "",
    maxEntries: "",
    sponsorPlayerPercentage: "50",
    sponsorFanPercentage: "50",
    proceedsPlayerPercentage: "50",
    proceedsFanPercentage: "50",
    endDate: new Date(),
    openDate: new Date()
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [matchIdError, setMatchIdError] = useState(false);
  const [matchIdHelperText, setMatchIdHelperText] = useState("");
  const [entryCreditsError, setEntryCreditsError] = useState(false);
  const [entryCreditsHelperText, setEntryCreditsHelperText] = useState("");
  const [totalQuestionsError, setTotalQuestionsError] = useState(false);
  const [totalQuestionsHelperText, setTotalQuestionsHelperText] = useState("");
  const [sponsorPlayerPercentageError, setSponsorPlayerPercentageError] = useState(false);
  const [sponsorPlayerPercentageHelperText, setSponsorPlayerPercentageHelperText] = useState("");
  const [sponsorFanPercentageError, setSponsorFanPercentageError] = useState(false);
  const [sponsorFanPercentageHelperText, setSponsorFanPercentageHelperText] = useState("");
  const [proceedsPlayerPercentageError, setProceedsPlayerPercentageError] = useState(false);
  const [proceedsPlayerPercentageHelperText, setProceedsPlayerPercentageHelperText] = useState("");
  const [proceedsFanPercentageError, setProceedsFanPercentageError] = useState(false);
  const [proceedsFanPercentageHelperText, setProceedsFanPercentageHelperText] = useState("");
  const [sponsorCommitmentError, setSponsorCommitmentError] = useState(false);
  const [sponsorCommitmentHelperText, setSponsorCommitmentHelperText] = useState("");
  const [maxEntriesError, setMaxEntriesError] = useState(false);
  const [maxEntriesHelperText, setMaxEntriesHelperText] = useState("");
  const [endDateError, setEndDateError] = useState(false);
  const [endDateHelperText, setEndDateHelperText] = useState("");
  const [openDateError, setOpenDateError] = useState(false);
  const [openDateHelperText, setOpenDateHelperText] = useState("");
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [matches, setMatches] = useState(null);
  const [sponsors, setSponsors] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setCompetition(prevState => ({
      ...prevState,
      [name]: value
    }));
    if(name === "matchId") {
      var match = _.find(matches, (o) => {
        return o.id === value;
      });
      setCompetition({
        ...competition,
        matchId: value,
        name: match.name
      });
      var selectedMatch = _.find(matches, (o) => {
        return o.id === value
      });
      setSelectedMatch(selectedMatch);
    }
  };

  useEffect(() => {
    console.log('using effect in update competition');

    console.log('Getting matches');
    var sortFilter = "sortBy=Name&sortByDirection=asc&active_match=true";
    var filter = userSub.role === "clubadmin" ? "?clubId=" + userSub.adminClubId + "&" + sortFilter : "?" + sortFilter;
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/matches' + filter, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        var localMatches = response.data;
        setMatches(localMatches);
      })
      .catch(function (error) {
        console.log(error);
      });

    console.log('Getting sponsors');
    sortFilter = "sortBy=Name&sortByDirection=asc"
    filter = userSub.role === "clubadmin" ? "?clubId=" + userSub.adminClubId + "&" + sortFilter : "?" + sortFilter;
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sponsors' + filter, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setSponsors(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [userSub]);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/competitions', {
      ...competition,
      entryCredits: parseInt(competition.entryCredits),
      totalQuestions: parseInt(competition.totalQuestions),
      sponsorId: competition.sponsorId === "0" ? null : competition.sponsorId,
      sponsorCommitment: competition.sponsorCommitment === '' ? null: parseInt(competition.sponsorCommitment),
      maxEntries: competition.maxEntries === '' ? null: parseInt(competition.maxEntries),
      sponsorPlayerPercentage: parseInt(competition.sponsorPlayerPercentage),
      sponsorFanPercentage: parseInt(competition.sponsorFanPercentage),
      proceedsPlayerPercentage: parseInt(competition.proceedsPlayerPercentage),
      proceedsFanPercentage: parseInt(competition.proceedsFanPercentage),
      endDate: moment(competition.endDate).format('yyyy-MM-DDTHH:mm'),
      openDate: moment(competition.openDate).format('yyyy-MM-DDTHH:mm')
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Competition&action=created&name=' + competition.name + '&id=' + response.data.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setMatchIdError(false);
    setEntryCreditsError(false);
    setSponsorPlayerPercentageError(false);
    setSponsorFanPercentageError(false);
    setProceedsPlayerPercentageError(false);
    setProceedsFanPercentageError(false);
    setEndDateError(false);
    setOpenDateError(false);
    setTotalQuestionsError(false);

    if ((field === "name" || field === "") && competition.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "matchId" || field === "") && competition.matchId === '') {
      hasErrors = true;
      setMatchIdError(true);
      setMatchIdHelperText("Match is required");
    }
    if ((field === "entryCredits" || field === "") && competition.entryCredits < 0) {
      hasErrors = true;
      setEntryCreditsError(true);
      setEntryCreditsHelperText("Entry Credits are required");
    }
    if ((field === "totalQuestions" || field === "") && competition.totalQuestions <= 0) {
      hasErrors = true;
      setTotalQuestionsError(true);
      setTotalQuestionsHelperText("Total Questions is required");
    }
    if ((field === "sponsorPlayerPercentage" || field === "") && competition.sponsorPlayerPercentage < 0) {
      hasErrors = true;
      setSponsorPlayerPercentageError(true);
      setSponsorPlayerPercentageHelperText("Sponsor Player Percentage is required");
    }
    if ((field === "sponsorFanPercentage" || field === "") && competition.sponsorFanPercentage < 0) {
      hasErrors = true;
      setSponsorFanPercentageError(true);
      setSponsorFanPercentageHelperText("Sponsor Fan Percentage is required");
    }
    if ((field === "proceedsPlayerPercentage" || field === "") && competition.proceedsPlayerPercentage < 0) {
      hasErrors = true;
      setProceedsPlayerPercentageError(true);
      setProceedsPlayerPercentageHelperText("Proceeds Player Percentage is required");
    }
    if ((field === "proceedsFanPercentage" || field === "") && competition.proceedsFanPercentage < 0) {
      hasErrors = true;
      setProceedsFanPercentageError(true);
      setProceedsFanPercentageHelperText("Proceeds Fan Percentage is required");
    }
    if ((field === "sponsorCommitment" || field === "") && (competition.sponsorCommitment !== '' && isNaN(competition.sponsorCommitment))) {
      hasErrors = true;
      setSponsorCommitmentError(true);
      setSponsorCommitmentHelperText("Sponsor Commitment must be a number");
    }
    if ((field === "maxEntries" || field === "") && (competition.maxEntries !== '' && isNaN(competition.maxEntries))) {
      hasErrors = true;
      setMaxEntriesError(true);
      setMaxEntriesHelperText("Max Entries must be a number");
    }
    if ((field === "endDate" || field === "") && (competition.endDate === '' || competition.endDate === null)) {
      hasErrors = true;
      setEndDateError(true);
      setEndDateHelperText("End Date is required");
    }
    if ((field === "openDate" || field === "") && (competition.openDate === '' || competition.openDate === null)) {
      hasErrors = true;
      setOpenDateError(true);
      setOpenDateHelperText("Open Date is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Create Competition"></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="matchIdLabel">Match</InputLabel>
            <Select
              labelId="matchIdLabel"
              error={matchIdError}
              id="matchId"
              name="matchId"
              value={competition.matchId}
              label="Match"
              onChange={handleFormChange}
            >
              {matches && matches.map((match) => (
                <MenuItem value={match.id} key={match.id}>{match.name}:&nbsp;<Moment format="YYYY/MM/DD HH:mm">
                {match.startDate}
              </Moment></MenuItem>
              ))}
            </Select>
            <FormHelperText error>{matchIdHelperText}</FormHelperText>
          </FormControl>
          <TextField
            margin="normal"
            id="name"
            name="name"
            required
            error={nameError}
            helperText={nameHelperText}
            fullWidth
            label="Name"
            value={competition.name}
            onChange={handleFormChange}
          />
          <TextField
            margin="normal"
            id="totalQuestions"
            name="totalQuestions"
            required
            error={totalQuestionsError}
            helperText={totalQuestionsHelperText}
            fullWidth
            label="Total Questions"
            value={competition.totalQuestions}
            onChange={handleFormChange}
          />
          <TextField
            margin="normal"
            id="entryCredits"
            name="entryCredits"
            required
            error={entryCreditsError}
            helperText={entryCreditsHelperText}
            fullWidth
            label="Entry Credits"
            value={competition.entryCredits}
            onChange={handleFormChange}
          />
          <TextField
            margin="normal"
            id="maxEntries"
            name="maxEntries"
            error={maxEntriesError}
            helperText={maxEntriesHelperText}
            fullWidth
            label="Max Entries"
            value={competition.maxEntries}
            onChange={handleFormChange}
          />
          <TextField
            margin="normal"
            id="sponsorPlayerPercentage"
            name="sponsorPlayerPercentage"
            required
            error={sponsorPlayerPercentageError}
            helperText={sponsorPlayerPercentageHelperText}
            fullWidth
            label="Sponsor Player Percentage"
            value={competition.sponsorPlayerPercentage}
            onChange={handleFormChange}
          />
          <TextField
            margin="normal"
            id="sponsorFanPercentage"
            name="sponsorFanPercentage"
            required
            error={sponsorFanPercentageError}
            helperText={sponsorFanPercentageHelperText}
            fullWidth
            label="Sponsor Fan Percentage"
            value={competition.sponsorFanPercentage}
            onChange={handleFormChange}
          />
          <TextField
            margin="normal"
            id="proceedsPlayerPercentage"
            name="proceedsPlayerPercentage"
            required
            error={proceedsPlayerPercentageError}
            helperText={proceedsPlayerPercentageHelperText}
            fullWidth
            label="Proceeds Player Percentage"
            value={competition.proceedsPlayerPercentage}
            onChange={handleFormChange}
          />
          <TextField
            margin="normal"
            id="proceedsFanPercentage"
            name="proceedsFanPercentage"
            required
            error={proceedsFanPercentageError}
            helperText={proceedsFanPercentageHelperText}
            fullWidth
            label="Proceeds Fan Percentage"
            value={competition.proceedsFanPercentage}
            onChange={handleFormChange}
          />
          <FormControl margin="normal" fullWidth>
            <InputLabel id="sponsorIdLabel">Sponsor</InputLabel>
            <Select
              labelId="sponsorIdLabel"
              id="sponsorId"
              name="sponsorId"
              value={competition.sponsorId}
              label="Sponsor"
              onChange={handleFormChange}
            >
              <MenuItem value="0">N/A</MenuItem>
              {sponsors && sponsors.map((sponsor) => (
                <MenuItem value={sponsor.id} key={sponsor.id}>{sponsor.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            id="sponsorCommitment"
            name="sponsorCommitment"
            error={sponsorCommitmentError}
            helperText={sponsorCommitmentHelperText}
            fullWidth
            label="Sponsor Commitment"
            value={competition.sponsorCommitment}
            onChange={handleFormChange}
          />
          {selectedMatch && (
            <div className="matchStartDate">
            Match Start Date: <Moment format="YYYY/MM/DD HH:mm">
              {selectedMatch.startDate}
            </Moment>
            </div>
          )}
          {selectedMatch && selectedMatch.autoDates === 1 && (
          <div className="matchStartDate">Auto dates are enabled on associated match</div>
          )}
          <FormControl margin="normal" fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="End"
                value={competition.endDate}
                disabled={selectedMatch && selectedMatch.autoDates === 1}
                error={endDateError}
                inputFormat="yyyy/MM/dd HH:mm"
                onChange={(newValue) => {
                  setCompetition(prevState => ({
                    ...prevState,
                    endDate: newValue
                  }));
                }}
              />
            </LocalizationProvider>
            <FormHelperText error>{endDateHelperText}</FormHelperText>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Open"
                value={competition.openDate}
                disabled={selectedMatch && selectedMatch.autoDates === 1}
                error={openDateError}
                inputFormat="yyyy/MM/dd HH:mm"
                onChange={(newValue) => {
                  setCompetition(prevState => ({
                    ...prevState,
                    openDate: newValue
                  }));
                }}
              />
            </LocalizationProvider>
            <FormHelperText error>{openDateHelperText}</FormHelperText>
          </FormControl>
          <SubmitButton loading={loading}></SubmitButton>
          <AddBar cancelTo="/manage-competitions"></AddBar>
        </Box>
      </Grid>
    </Grid>
  );
}