import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Grid, Box, LinearProgress, IconButton, Card, CardContent, Typography, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TitleBar } from '../Shared/TitleBar';
import Moment from 'react-moment';
import _ from 'lodash';
import { LoginContext } from '../../contexts/LoginContext';
import Countdown from 'react-countdown';

export const Competitions = () => {
  const navigate = useNavigate();
  const { wallet, userSub, setWallet } = useContext(LoginContext);
  const [competitions, setCompetitions] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogInsufficientFunds, setOpenDialogInsufficientFunds] = useState(false);
  const [selectedCompetition, setSelectedCompetition] = useState(false);
  const [entering, setEntering] = useState(false);
  const [competitionId, setCompetitionId] = useState("");
  var enabledStyle = { background: '#fdb611', color: '#000000' };
  var disabledStyle = { background: '#e2e2e2', color: '#ffffff' };

  const handleCountdownComplete = () => {
    setTimeout(() => {
      getCompetitions(competitions);
    }, 3000);
  }
  
  const handleDialogClickOpen = (event) => {
    setCompetitionId(event.target.value);
    var selectedCompetition = _.find(competitions, (o) => {
      return o.id === event.target.value;
    });
    setSelectedCompetition(selectedCompetition);
    if (wallet.balance >= selectedCompetition.entryCredits) {
      setOpenDialog(true);
    } else {
      setOpenDialogInsufficientFunds(true);
    }
  };

  const enterCompetition = () => {
    setEntering(true);
    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallet-transactions', {
      walletId: wallet.id,
      isDebit: true,
      amount: selectedCompetition.entryCredits
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        console.log("Balance debited");
        axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries', {
          userId: userSub.id,
          competitionId: selectedCompetition.id,
          entryCredits: selectedCompetition.entryCredits
        }, {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response2) {
            var entryId = response2.data.id;
            var slug = "entry"
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/honey-points?slug=' + slug, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response3) {
                var localHoneyPoint = response3.data[0];
                axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/honey-transactions', {
                  userId: userSub.id,
                  walletId: wallet.id,
                  entryId: entryId,
                  isDebit: false,
                  honeyPointSlug: slug
                }, {
                  headers: {
                    'Authorization': localStorage.getItem('jwtToken')
                  }
                })
                  .then(function (response4) {
                    var newWallet = {
                      id: wallet.id,
                      balance: wallet.balance,
                      expectedBalance: wallet.balance - selectedCompetition.entryCredits,
                      honeyPointsBalance: wallet.honeyPointsBalance,
                      expectedHoneyPointsBalance: wallet.honeyPointsBalance + localHoneyPoint.points,
                    };
                    setWallet(newWallet);
                    localStorage.setItem("wallet", JSON.stringify(newWallet));
                    setEntering(false);
                    setOpenDialog(false);
                    navigate(`/questions?entryId=${entryId}&sportId=${selectedCompetition.sportId}&totalQuestions=${selectedCompetition.totalQuestions}`);
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleDialogCloseInsufficientFunds = () => {
    setOpenDialogInsufficientFunds(false);
  };
  const handleDialogCloseTopUp = () => {
    setOpenDialogInsufficientFunds(false);
    navigate('/top-up');
  };

  const getCompetitions = useCallback((competitions) => {
    console.log('Getting competitions');

    if (competitions)
      setCompetitions(null);

    var sortFilter = "sortBy=end_date&sortByDirection=asc"
    var filter = "?active=true&" + sortFilter;
    axios.get(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/competitions${filter}`, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setCompetitions(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          navigate('/questions?competitionId=' + competitionId);
        }
      });
  }, [navigate, competitionId]);

  useEffect((competitions) => {
    console.log('using effect in competitions component');
    getCompetitions(competitions);
  }, [getCompetitions]);

  const handleRefresh = () => {
    getCompetitions(competitions);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Competitions"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">

        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {competitions && competitions.length > 0 && (
            <>
              {competitions.map((competition) => (
                <Card sx={{ minWidth: 275, marginBottom: 5 }} key={competition.id}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      <Grid container>
                        <Grid item xs={2} sx={{ textAlign: 'center' }}>
                          <img src={competition.ownClubShield} alt={competition.ownClubName} style={{ height: '40px' }}></img>
                        </Grid>
                        <Grid item xs={8} sx={{ textAlign: 'center' }}>
                          <div className="compBox">
                            <div className="compName">
                              <div>{competition.ownClubName}</div>
                              <div>vs</div>
                              <div>{competition.opponentClubName}</div>
                            </div>
                            <div className="compMatchStartDate">
                              Match starts: <Moment format="YYYY/MM/DD HH:mm">
                                {competition.matchStartDate}
                              </Moment>
                            </div>
                            <div className="compEndDate">
                              Competition closes: <Countdown date={competition.endDate} onComplete={handleCountdownComplete} />
                            </div>
                            {competition.sponsorName && (
                              <div className="compSponsor"><img src={competition.sponsorLogo} alt={competition.sponsorName} style={{ height: '20px' }}></img></div>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: 'center' }}>
                          <img src={competition.opponentClubShield} alt={competition.opponentClubName} style={{ height: '40px' }}></img>
                          {competition.clubId === competition.opponentClubId && (
                            <div className="compButton">
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={handleDialogClickOpen}
                                style={enabledStyle}
                                value={competition.id}
                              >
                                Enter
                              </Button>
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <div className="compButton">
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              onClick={handleDialogClickOpen}
                              style={enabledStyle}
                              value={competition.id}
                            >
                              Enter for {competition.ownClubName}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </>
          )}
          {!competitions && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {competitions && competitions.length === 0 && (
            <div>
              <h3>No Competitions.</h3>
              <div>Try Refresh.</div>
            </div>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Entry Terms</DialogTitle>
        <DialogContent dividers={true} id="scroll-dialog-description">
          <div className="compTerms1">
            WIN
            {selectedCompetition.sponsorCommitment && (
              <span> £{selectedCompetition.sponsorCommitment} CASH PLUS </span>
            )} CASH FROM THE WINNERS' CASH POOL</div>
          <div className="compTerms2"><span className="compDynamicWord">{selectedCompetition.entryCredits}</span> <span className="compDynamicWord">{selectedCompetition.entryCredits === 1 ? "credit" : "credits"}</span> per ticket, <span className="compDynamicWord">{selectedCompetition.maxEntries === null ? "Unlimited" : selectedCompetition.maxEntries}</span> Tickets available </div>
          <div className="compTerms3">Answer <span className="compDynamicWord">{selectedCompetition.totalQuestions} {selectedCompetition.sportName}</span> related questions and select the <span className="compDynamicWord">{selectedCompetition.ownClubName}</span> Fans Player of the Match after the match between <span className="compDynamicWord">{selectedCompetition.ownClubName}</span> and <span className="compDynamicWord">{selectedCompetition.opponentClubName}</span>.</div>
          <div className="compTerms4">You must have answered the questions correctly (Step 1) and have selected the Fans Player of the Match (Step 2) to be entered into the Draw after the match.</div>
          <div className="compTerms5">You will also have the opportunity to rate the <span className="compDynamicWord">{selectedCompetition.ownClubName}</span> Players after the match.</div>
          <div className="compTerms6">Competition ends on <span className="compDynamicWord"><Moment format="dddd, D MMMM yyyy">
            {selectedCompetition.endDate}
          </Moment> at <Moment format="HH:mm">
              {selectedCompetition.endDate}
            </Moment></span></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Box sx={{ position: 'relative'}}>
            <Button 
              onClick={enterCompetition} 
              disabled={entering}
              style={entering ? disabledStyle : enabledStyle}
              variant="contained">Agree & Start</Button>
              {entering && (
              <CircularProgress
                size={24}
                sx={{
                  color: '#495563',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
              )}
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogInsufficientFunds}
        onClose={handleDialogCloseInsufficientFunds}
        scroll="paper"
        aria-labelledby="scroll-dialog-title-insuf"
        aria-describedby="scroll-dialog-description-insuf"
      >
        <DialogTitle id="scroll-dialog-title">Insufficient Funds</DialogTitle>
        <DialogContent dividers={true} id="scroll-dialog-description-insuf">
          This competition requires <span className="compDynamicWord">{selectedCompetition.entryCredits}</span> <span className="compDynamicWord">{selectedCompetition.entryCredits === 1 ? "credit" : "credits"}</span>. Unfortunately you have insufficient credits.
          <br></br>
          Please click the "Top Up Credits" button below to get more credits so you can enter.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCloseInsufficientFunds}>Cancel</Button>
          <Button onClick={handleDialogCloseTopUp} variant="contained">Top Up Credits</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}