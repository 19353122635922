import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';

export const CreateEntry = () => {
  const navigate = useNavigate();
  const [entry, setEntry] = useState({
    userId: "",
    competitionId: "",
    entryCredits: "",
    sponsorId: ""
  });
  const [userIdError, setUserIdError] = useState(false);
  const [userIdHelperText, setUserIdHelperText] = useState("");
  const [competitionIdError, setCompetitionIdError] = useState(false);
  const [competitionIdHelperText, setCompetitionIdHelperText] = useState("");
  const [entryCreditsError, setEntryCreditsError] = useState(false);
  const [entryCreditsHelperText, setEntryCreditsHelperText] = useState("");
  const [users, setUsers] = useState(null);
  const [competitions, setCompetitions] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setEntry(prevState => ({
      ...prevState,
      [name]: value
    }));
    if(name === 'competitionId'){
      let competition = competitions.find(competition=> competition.id===value);
      setEntry(prevState => ({
        ...prevState,
        entryCredits: competition.entryCredits
      }));
    }
  };

  useEffect(() => {
    console.log('using effect in update entry');

    console.log('Getting users');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/users?sortBy=user_name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setUsers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    console.log('Getting competitions');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/competitions?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setCompetitions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries', {
      userId: entry.userId,
      competitionId: entry.competitionId,
      entryCredits: parseInt(entry.entryCredits)
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-entries');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setUserIdError(false);
    setCompetitionIdError(false);
    setEntryCreditsError(false);

    if ((field === "userId" || field === "") && entry.userId === '') {
      hasErrors = true;
      setUserIdError(true);
      setUserIdHelperText("User is required");
    }
    if ((field === "competitionId" || field === "") && entry.competitionId === '') {
      hasErrors = true;
      setCompetitionIdError(true);
      setCompetitionIdHelperText("Competition is required");
    }
    if ((field === "entryCredits" || field === "") && entry.entryCredits <= 0) {
      hasErrors = true;
      setEntryCreditsError(true);
      setEntryCreditsHelperText("Entry Credits are required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Create Entry"></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="userIdLabel">User</InputLabel>
            <Select
              labelId="userIdLabel"
              error={userIdError}
              id="userId"
              name="userId"
              value={entry.userId}
              label="User"
              onChange={handleFormChange}
            >
              {users && users.map((user) => (
                <MenuItem value={user.id} key={user.id}>{user.userName}</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{userIdHelperText}</FormHelperText>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="competitionIdLabel">Competition</InputLabel>
            <Select
              labelId="competitionIdLabel"
              error={competitionIdError}
              id="competitionId"
              name="competitionId"
              value={entry.competitionId}
              label="Competition"
              onChange={handleFormChange}
            >
              {competitions && competitions.map((competition) => (
                <MenuItem value={competition.id} key={competition.id}>{competition.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{competitionIdHelperText}</FormHelperText>
          </FormControl>
          <TextField
            margin="normal"
            id="entryCredits"
            name="entryCredits"
            required
            error={entryCreditsError}
            helperText={entryCreditsHelperText}
            fullWidth
            label="Entry Credits"
            value={entry.entryCredits}
            onChange={handleFormChange}
          />
          <SubmitButton loading={loading}></SubmitButton>
          <AddBar cancelTo="/manage-entries"></AddBar>
        </Box>
      </Grid>
    </Grid>
  );
}