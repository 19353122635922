import React, { useContext } from 'react';
import { TitleBar } from "../Shared/TitleBar";
import Box from '@mui/material/Box';
import { LoginContext } from '../../contexts/LoginContext';

export const AdminHome = () => {
  const { userSub } = useContext(LoginContext);
  return (
    <div>
      <TitleBar title="Admin Home"></TitleBar>
      <Box>
        <h2>Welcome to Fanhive {userSub.firstName}</h2>
        <div>Use the menu on the left to manage Fanhive.</div>
      </Box>
    </div>
  )
};
