import React, { useState, useEffect, useCallback, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TitleBar } from '../Shared/TitleBar';
import { Grid, Box, LinearProgress, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import Moment from 'react-moment';
import { LoginContext } from '../../contexts/LoginContext';

export const ManageWallets = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  const [wallets, setWallets] = useState(null);

  const getWallets = useCallback((wallets) => {
    console.log('Getting wallets');
    
    if(wallets)
      setWallets(null);

    var sortFilter = "sortBy=date_created&sortByDirection=desc"
    var filter = "?" + sortFilter;
    if (userSub.role === "user")
      filter = filter + "&userId=" + userSub.id;
    if (userSub.role === "clubadmin")
      filter = filter + "&clubId=" + userSub.adminClubId;

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallets' + filter, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setWallets(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status === 401) {
          navigate('/log-out');
        }
      });
  }, [navigate, userSub]);

  useEffect((wallets) => {
    console.log('using effect in wallets component');
    getWallets(wallets);
  }, [getWallets]);

  const handleRefresh = () => {
    getWallets(wallets);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Manage Wallets"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">
          <RouterLink to="/create-wallet">Create Wallet</RouterLink>
        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {wallets && wallets.length > 0 && (
          <TableContainer component={Paper} sx={{mt: 2}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell align="right">Credit Balance</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Date Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wallets.map((wallet) => (
                  <TableRow
                    key={wallet.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <RouterLink to={"/update-wallet/" + wallet.id}>{wallet.name}</RouterLink>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {wallet.userName}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {wallet.balance}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {wallet.createdBy}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Moment format="YYYY/MM/DD">
                        {wallet.dateCreated}
                      </Moment>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )}
          {!wallets && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {wallets && wallets.length === 0 && (
            <div>
              <h3>No Wallets.</h3>
              <div>Try Refresh or use the "Create Wallet" link above to create one.</div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}