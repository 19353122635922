import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { LoginContext } from '../../contexts/LoginContext';

export const UpdateTeam = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  let { id } = useParams();
  const [team, setTeam] = useState({
    name: "",
    sportId: "",
    leagueDivisionId: "",
    clubId: userSub.adminClubId === null ? "" : userSub.adminClubId,
  });
  const [sports, setSports] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [leagueDivisions, setLeagueDivisions] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [sportIdError, setSportIdError] = useState(false);
  const [sportIdHelperText, setSportIdHelperText] = useState("");
  const [clubIdError, setClubIdError] = useState(false);
  const [clubIdHelperText, setClubIdHelperText] = useState("");
  const [leagueDivisionIdError, setLeagueDivisionIdError] = useState(false);
  const [leagueDivisionIdHelperText, setLeagueDivisionIdHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setTeam(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update team');

    console.log('Getting sports');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {

        setSports(response.data);

        console.log('Getting team: ' + id);
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/league-divisions', {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {
            setLeagueDivisions(response.data);

            console.log('Getting clubs: ' + id);
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs', {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response) {
                setClubs(response.data);

                console.log('Getting team: ' + id);
                axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/teams/' + id, {
                  headers: {
                    'Authorization': localStorage.getItem('jwtToken')
                  }
                })
                  .then(function (response) {
                    setTeam(prevState => ({
                      ...prevState,
                      name: PrepProp(response.data.name),
                      sportId: PrepProp(response.data.sportId),
                      leagueDivisionId: PrepProp(response.data.leagueDivisionId) === "" ? "0" : PrepProp(response.data.leagueDivisionId),
                      clubId: PrepProp(response.data.clubId)
                    }));

                    setPageLoading(false);
                  })
                  .catch(function (error) {
                    console.log(error);
                  });

                setPageLoading(false);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteTeam = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/teams/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/complete?entity=Team&action=deleted&name=' + team.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/teams/' + id, {
      ...team,
      leagueDivisionId: team.leagueDivisionId === "0" ? null : team.leagueDivisionId
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Team&action=updated&name=' + team.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setSportIdError(false);
    setLeagueDivisionIdError(false);
    setClubIdError(false);

    if ((field === "clubId" || field === "") && team.clubId === '') {
      hasErrors = true;
      setClubIdError(true);
      setClubIdHelperText("Club is required");
    }
    if ((field === "name" || field === "") && team.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "sportId" || field === "") && team.sportId === '') {
      hasErrors = true;
      setSportIdError(true);
      setSportIdHelperText("Sport is required");
    }
    if ((field === "leagueDivisionId" || field === "") && team.leagueDivisionId === '') {
      hasErrors = true;
      setLeagueDivisionIdError(true);
      setLeagueDivisionIdHelperText("League Division is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title="Update Team" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            {userSub.adminClubId === null && (
              <FormControl margin="normal" fullWidth>
                <InputLabel id="positionIdLabel">Club</InputLabel>
                <Select
                  labelId="clubIdLabel"
                  error={clubIdError}
                  required
                  id="clubId"
                  name="clubId"
                  value={team.clubId}
                  label="Position"
                  onChange={handleFormChange}
                >
                  {clubs && clubs.map((club) => (
                    <MenuItem value={club.id} key={club.id}>{club.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{clubIdHelperText}</FormHelperText>
              </FormControl>
            )}
            <FormControl margin="normal" fullWidth>
              <InputLabel id="sportIdLabel">Sport</InputLabel>
              <Select
                labelId="sportIdLabel"
                error={sportIdError}
                id="sportId"
                name="sportId"
                value={team.sportId}
                label="Sport"
                onChange={handleFormChange}
              >
                {sports && sports.map((sport) => (
                  <MenuItem value={sport.id} key={sport.id}>{sport.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{sportIdHelperText}</FormHelperText>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="leagueDivisionIdLabel">League Division</InputLabel>
              <Select
                labelId="leagueDivisionIdLabel"
                error={leagueDivisionIdError}
                id="leagueDivisionId"
                name="leagueDivisionId"
                value={team.leagueDivisionId}
                label="League Division"
                onChange={handleFormChange}
              >
                <MenuItem value="0">N/A</MenuItem>
                {leagueDivisions && leagueDivisions.map((leagueDivision) => (
                  <MenuItem value={leagueDivision.id} key={leagueDivision.id}>{leagueDivision.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{leagueDivisionIdHelperText}</FormHelperText>
            </FormControl>
            <TextField
              margin="normal"
              error={nameError}
              helperText={nameHelperText}
              name="name"
              required
              fullWidth
              id="name"
              label="Name"
              value={team.name}
              onChange={handleFormChange}
              onBlur={() => validateForm("name")}
            />
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deleteTeam} loadingDelete={loadingDelete} cancelTo="/manage-teams"></UpdateBar>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}