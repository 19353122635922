import React, { useState, useContext } from 'react';
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { LoginContext } from '../contexts/LoginContext';
import UserPool from "../UserPool";
import { useNavigate, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { TitleBar } from './Shared/TitleBar';
import { SubmitButton } from './Shared/SubmitButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import jwt_decode from "jwt-decode";
import axios from 'axios';

export const Login = () => {
  const navigate = useNavigate();
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  var qUserName = "";
  if (query.get("userName") != null) {
    qUserName = query.get("userName");
  }
  const [user, setUser] = useState({
    userName: qUserName,
    password: ""
  });
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [userNameError, setEmailError] = useState(false);
  const [userNameHelperText, setEmailHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const { setIsLoggedIn, setUserSub, setWallet } = useContext(LoginContext);

  const handleDialogClickOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleFormChange = e => {
    const { name, value } = e.target;
    setUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    var cognitoUser = new CognitoUser({
      Username: user.userName,
      Pool: UserPool
    });

    const authDetails = new AuthenticationDetails({
      Username: user.userName,
      Password: user.password
    });
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log("Success");
        localStorage.setItem("jwtToken", data.idToken.jwtToken);
        var decodedJwt = jwt_decode(data.idToken.jwtToken);
        
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/users?sub=' + decodedJwt.sub, {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {

            var loggedInUser = response.data;
            var userSub = {
              id: loggedInUser.id,
              sub: decodedJwt.sub,
              firstName: loggedInUser.firstName,
              surname: loggedInUser.surname,
              userName: loggedInUser.userName,
              role: loggedInUser.role,
              adminClubId: loggedInUser.adminClubId,
              adminClubName: loggedInUser.adminClubName,
              adminClubLogo: loggedInUser.adminClubLogo
            };
            localStorage.setItem("userSub", JSON.stringify(userSub));
            setUserSub(userSub);
            setIsLoggedIn(true);

            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallets?userId=' + loggedInUser.id, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response2) {

                var localWallet = response2.data[0];
                var newWallet = {
                  id: localWallet.id,
                  balance: localWallet.balance,
                  honeyPointsBalance: localWallet.honeyPointsBalance,
                  expectedBalance: localWallet.balance,
                  expectedHoneyPointsBalance: localWallet.honeyPointsBalance
                };
                localStorage.setItem("wallet", JSON.stringify(newWallet));
                setWallet(newWallet);

                setLoading(false);

                switch(userSub.role){
                  case "user": {
                    navigate('/user-home');
                    break;
                  }
                  case "clubadmin": {
                    navigate('/club-admin-home');
                    break;
                  }
                  case "admin": {
                    navigate('/admin-home');
                    break;
                  }
                  default: {
                    navigate('/');
                  }
                }

              })
              .catch(function (error) {
                console.log(error);
              });

          })
          .catch(function (error) {
            console.log(error);
          });
      },
      onFailure: (err) => {
        console.log("onFailure: ", err);
        setLoading(false);
        setDialogMessage(err.message);
        handleDialogClickOpen(true);
      }
    });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setEmailError(false);
    setPasswordError(false);

    if ((field === "userName" || field === "") && user.userName === '') {
      hasErrors = true;
      setEmailError(true);
      setEmailHelperText("Email or User Name is required");
    }
    if ((field === "password" || field === "") && user.password === '') {
      hasErrors = true;
      setPasswordError(true);
      setPasswordHelperText("Password is required");
    }

    return !hasErrors;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Login"></TitleBar>
          {qUserName && (
            <>
              <h2>Thank you for confirming your email address.</h2>
              <div>Please login with your User Name or Email.</div>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <TextField
              margin="normal"
              error={userNameError}
              helperText={userNameHelperText}
              name="userName"
              required
              fullWidth
              id="userName"
              label="Email or User Name"
              value={user.userName}
              onChange={handleFormChange}
              onBlur={() => validateForm("userName")}
            />
            <TextField
              margin="normal"
              error={passwordError}
              helperText={passwordHelperText}
              name="password"
              type="password"
              required
              fullWidth
              id="password"
              label="Password"
              value={user.password}
              onChange={handleFormChange}
              onBlur={() => validateForm("password")}
            />
            <SubmitButton loading={loading} name="Login"></SubmitButton>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"There was a problem."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}