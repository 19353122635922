import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import { LoginContext } from '../../contexts/LoginContext';
import _ from 'lodash';
import moment from 'moment';

export const CreateMatch = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  const [match, setMatch] = useState({
    description: "",
    homeTeamId: "",
    awayTeamId: "",
    cupId: "0",
    startDate: new Date(),
    autoDates: true
  });
  const [homeTeamIdError, setHomeTeamIdError] = useState(false);
  const [homeTeamIdHelperText, setHomeTeamIdHelperText] = useState("");
  const [awayTeamIdError, setAwayTeamIdError] = useState(false);
  const [awayTeamIdHelperText, setAwayTeamIdHelperText] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [startDateHelperText, setStartDateHelperText] = useState("");
  const [teams, setTeams] = useState(null);
  const [cups, setCups] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setMatch(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const ownTeams = () => {
    return _.filter(teams, (o) => {
      if(userSub.adminClubId){
        return o.clubId === userSub.adminClubId;
      }
      return o;
    })
  }
  const opponentTeams = () => {
    return _.filter(teams, (o) => {
      return o.clubId !== userSub.adminClubId;
    })
  }
  const newName = () => {
    var homeTeam = _.find(teams, (o) => {
      return o.id === match.homeTeamId;
    });
    var awayTeam = _.find(teams, (o) => {
      return o.id === match.awayTeamId;
    });
    return  `${homeTeam.clubName} (${homeTeam.name}) vs ${awayTeam.clubName} (${awayTeam.name})`;
  }

  useEffect(() => {
    console.log('using effect in update match');

    console.log('Getting teams');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/teams?sortBy=group_name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setTeams(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
      
    console.log('Getting cups');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/cups?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setCups(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/matches', {
      ...match,
      cupId: match.cupId === "0" ? null : match.cupId, 
      startDate: moment(match.startDate).format('yyyy-MM-DDTHH:mm')
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate(`/complete?entity=Match&action=created&name=${newName()}&id=${response.data.id}&specialCase=3`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setHomeTeamIdError(false);
    setAwayTeamIdError(false);
    setStartDateError(false);
    
    if ((field === "homeTeamId" || field === "") && match.homeTeamId === '') {
      hasErrors = true;
      setHomeTeamIdError(true);
      setHomeTeamIdHelperText("Own Team is required");
    }
    if ((field === "awayTeamId" || field === "") && match.awayTeamId === '') {
      hasErrors = true;
      setAwayTeamIdError(true);
      setAwayTeamIdHelperText("Opponent Team is required");
    }
    if ((field === "startDate" || field === "") && (match.startDate === '' || match.startDate === null)) {
      hasErrors = true;
      setStartDateError(true);
      setStartDateHelperText("Start Date is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Create Match"></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="homeTeamIdLabel">Own Team</InputLabel>
            <Select
              labelId="homeTeamIdLabel"
              error={homeTeamIdError}
              id="homeTeamId"
              name="homeTeamId"
              value={match.homeTeamId}
              label="Own Team"
              onChange={handleFormChange}
            >
              {teams && ownTeams().map((team) => (
                <MenuItem value={team.id} key={team.id}>{team.clubName} ({team.leagueDivisionName})</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{homeTeamIdHelperText}</FormHelperText>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="awayTeamIdLabel">Opponent Team</InputLabel>
            <Select
              labelId="awayTeamIdLabel"
              error={awayTeamIdError}
              id="awayTeamId"
              name="awayTeamId"
              value={match.awayTeamId}
              label="Opponent Team"
              onChange={handleFormChange}
            >
              {teams && opponentTeams().map((team) => (
                <MenuItem value={team.id} key={team.id}>{team.clubName} ({team.leagueDivisionName})</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{awayTeamIdHelperText}</FormHelperText>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="cupIdLabel">Cup</InputLabel>
            <Select
              labelId="cupIdLabel"
              id="cupId"
              name="cupId"
              value={match.cupId}
              label="Cup"
              onChange={handleFormChange}
            >
              <MenuItem value="0">N/A</MenuItem>
              {cups && cups.map((cup) => (
                <MenuItem value={cup.id} key={cup.id}>{cup.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            id="description"
            name="description"
            fullWidth
            label="Description"
            multiline
            rows={4}
            value={match.description}
              onChange={handleFormChange}
          />
          <FormControl margin="normal" fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Start"
                value={match.startDate}
                error={startDateError}
                inputFormat="yyyy/MM/dd HH:mm"
                onChange={(newValue) => {
                  setMatch(prevState => ({
                    ...prevState,
                    startDate: newValue
                  }));
                }}
              />
            </LocalizationProvider>
            <FormHelperText error>{startDateHelperText}</FormHelperText>
          </FormControl>
          <SubmitButton loading={loading}></SubmitButton>
          <AddBar cancelTo="/manage-matches"></AddBar>
        </Box>
      </Grid>
    </Grid>
  );
}