import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';
import { LoginContext } from '../../contexts/LoginContext';

export const CreateTeam = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  const [team, setTeam] = useState({
    name: "",
    sportId: "",
    clubId: userSub.adminClubId === null ? "" : userSub.adminClubId,
    clubName: "",
    leagueDivisionId: "0",
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [sportIdError, setSportIdError] = useState(false);
  const [sportIdHelperText, setSportIdHelperText] = useState("");
  const [clubIdError, setClubIdError] = useState(false);
  const [clubIdHelperText, setClubIdHelperText] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [sports, setSports] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [leagueDivisions, setLeagueDivisions] = useState(null);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setTeam(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    console.log('using effect in update team');

    if(userSub.adminClubId === null) {
      console.log('Getting clubs');
      axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs?sortBy=name&sortByDirection=asc', {
        headers: {
          'Authorization': localStorage.getItem('jwtToken')
        }
      })
        .then(function (response) {
          setClubs(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    
    console.log('Getting sports');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setSports(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    console.log('Getting league divisions');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/league-divisions?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLeagueDivisions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [userSub.adminClubId]);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/teams', team, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Team&action=created&name=' + team.name + '&id=' + response.data.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setSportIdError(false);
    setClubIdError(false);

    if ((field === "clubId" || field === "") && team.clubId === '') {
      hasErrors = true;
      setClubIdError(true);
      setClubIdHelperText("Club is required");
    }
    if ((field === "name" || field === "") && team.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "sportId" || field === "") && team.sportId === '') {
      hasErrors = true;
      setSportIdError(true);
      setSportIdHelperText("Sport is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Create Team"></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
          {userSub.adminClubId === null && (
            <FormControl margin="normal" fullWidth>
              <InputLabel id="positionIdLabel">Club</InputLabel>
              <Select
                labelId="clubIdLabel"
                error={clubIdError}
                required
                id="clubId"
                name="clubId"
                value={team.clubId}
                label="Position"
                onChange={handleFormChange}
              >
                {clubs && clubs.map((club) => (
                  <MenuItem value={club.id} key={club.id}>{club.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{clubIdHelperText}</FormHelperText>
            </FormControl>
          )}
          <FormControl margin="normal" fullWidth>
            <InputLabel id="sportIdLabel">Sport</InputLabel>
            <Select
              labelId="sportIdLabel"
              error={sportIdError}
              id="sportId"
              required
              name="sportId"
              value={team.sportId}
              label="Sport"
              onChange={handleFormChange}
            >
              {sports && sports.map((sport) => (
                <MenuItem value={sport.id} key={sport.id}>{sport.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{sportIdHelperText}</FormHelperText>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="leagueDivisionIdLabel">League Division</InputLabel>
            <Select
              labelId="leagueDivisionIdLabel"
              id="leagueDivisionId"
              name="leagueDivisionId"
              value={team.leagueDivisionId}
              label="League Division"
              onChange={handleFormChange}
            >
              <MenuItem value="0">N/A</MenuItem>
              {leagueDivisions && leagueDivisions.map((leagueDivision) => (
                <MenuItem value={leagueDivision.id} key={leagueDivision.id}>{leagueDivision.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            error={nameError}
            helperText={nameHelperText}
            name="name"
            required
            fullWidth
            id="name"
            label="Name"
            value={team.name}
            onChange={handleFormChange}
            onBlur={() => validateForm("name")}
          />
          <SubmitButton loading={loading}></SubmitButton>
          <AddBar cancelTo="/manage-teams"></AddBar>
        </Box>
      </Grid>
    </Grid>
  );
}