import React, { useState, useEffect, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TitleBar } from '../Shared/TitleBar';
import { Grid, Box, LinearProgress, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import RefreshIcon from '@mui/icons-material/Refresh';
import Moment from 'react-moment';

export const ManageMatchPlayers = () => {
  const navigate = useNavigate();
  const [matchPlayers, setMatchPlayers] = useState(null);

  const getMatchPlayers = useCallback((matchPlayers) => {
    console.log('Getting matchPlayers');
    
    if(matchPlayers)
      setMatchPlayers(null);

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/match-players', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setMatchPlayers(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status === 401) {
          navigate('/log-out');
        }
      });
  }, [navigate]);

  useEffect((matchPlayers) => {
    console.log('using effect in users component');
    getMatchPlayers(matchPlayers);
  }, [getMatchPlayers]);

  const handleRefresh = () => {
    getMatchPlayers(matchPlayers);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Manage Match Players"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">
          <RouterLink to="/create-match-player">Create Match Player</RouterLink>
        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {matchPlayers && matchPlayers.length > 0 && (
          <TableContainer component={Paper} sx={{mt: 2}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Match Name</TableCell>
                  <TableCell>Player Name</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Date Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {matchPlayers.map((matchPlayer) => (
                  <TableRow
                    key={matchPlayer.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <RouterLink to={"/update-match-player/" + matchPlayer.id}>{moment(matchPlayer.matchStartDate).format('yyyy/MM/DD HH:mm')} - {matchPlayer.matchName}</RouterLink>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {matchPlayer.playerName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {matchPlayer.createdBy}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Moment format="YYYY/MM/DD">
                        {matchPlayer.dateCreated}
                      </Moment>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )}
          {!matchPlayers && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {matchPlayers && matchPlayers.length === 0 && (
            <div>
              <h3>No Match Players.</h3>
              <div>Try Refresh or use the "Create Match Player" link above to create one.</div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}