import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';

import { TitleBar } from "../Shared/TitleBar";
import { LoginContext } from '../../contexts/LoginContext';

export const PublicHome = () => {
  const { isLoggedIn } = useContext(LoginContext);

  return (
    <div>
      <TitleBar title="Fanhive"></TitleBar>
      <Box>
        <h2>Welcome to Fanhive</h2>
        {!isLoggedIn && (
        <div><RouterLink to="/login">Login</RouterLink> or <RouterLink to="/register">Register</RouterLink> to get started</div>
        )}
        {isLoggedIn && (
          <div>Show competitions here</div>
        )}
      </Box>
    </div>
  )
};
