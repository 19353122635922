import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export const UpdateQuestionAnswer = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [questionAnswer, setQuestionAnswer] = useState({
    name: "",
    questionId: "",
    isCorrect: false
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [questionIdError, setQuestionIdError] = useState(false);
  const [questionIdHelperText, setQuestionIdHelperText] = useState("");
  const [questions, setQuestions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    if(name === 'isCorrect'){
      setQuestionAnswer(prevState => ({
        ...prevState,
        isCorrect: e.target.checked
      }));
    } else {
      setQuestionAnswer(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update questionAnswer');

    console.log('Getting sports');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/questions?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {

        setQuestions(response.data);

        console.log('Getting question answer: ' + id);
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/question-answers/' + id, {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {
            setQuestionAnswer(prevState => ({
              ...prevState,
              name: PrepProp(response.data.name),
              questionId: PrepProp(response.data.questionId),
              isCorrect: PrepProp(response.data.isCorrect)
            }));

            setPageLoading(false);
          })
          .catch(function (error) {
            console.log(error);
          });

      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteQuestionAnswer = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/question-answers/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/complete?entity=Question Answer&action=deleted&name=' + questionAnswer.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/question-answers/' + id, questionAnswer, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Question Answer&action=updated&name=' + questionAnswer.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setQuestionIdError(false);

    if ((field === "name" || field === "") && questionAnswer.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "questionId" || field === "") && questionAnswer.questionId === '') {
      hasErrors = true;
      setQuestionIdError(true);
      setQuestionIdHelperText("Question is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title="Update QuestionAnswer" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="questionIdLabel">Question</InputLabel>
              <Select
                labelId="questionIdLabel"
                error={questionIdError}
                id="questionId"
                name="questionId"
                value={questionAnswer.questionId}
                label="Question"
                onChange={handleFormChange}
              >
                {questions && questions.map((question) => (
                  <MenuItem value={question.id} key={question.id}>{question.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{questionIdHelperText}</FormHelperText>
            </FormControl>
            <TextField
              margin="normal"
              error={nameError}
              helperText={nameHelperText}
              name="name"
              required
              fullWidth
              id="name"
              label="Answer"
              value={questionAnswer.name}
              onChange={handleFormChange}
              onBlur={() => validateForm("name")}
            />
            <FormGroup>
              <FormControlLabel control={<Switch id="isCorrect" name="isCorrect" checked={questionAnswer.isCorrect} onChange={handleFormChange} />} label="Is Correct" />
            </FormGroup>
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deleteQuestionAnswer} loadingDelete={loadingDelete} cancelTo="/manage-question-answers"></UpdateBar>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}