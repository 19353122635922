import React, {useState} from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TitleBar } from '../Shared/TitleBar';

export const TopUp = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");

  const handleChange = (event) => {
    setAmount(event.target.value);
    navigate(`/payment-stub?amount=${event.target.value}`);
  };

  return (
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Top Up"></TitleBar>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
        <h3>Each competition you enter has a cost in Credits. Top-up your credits here and use these credits for any competition.</h3>
          <div>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={amount}
              onChange={handleChange}
            >
              <FormControlLabel value="5" control={<Radio />} label="5 Credits - £5" />
              <FormControlLabel value="10" control={<Radio />} label="10 Credits - £10" />
              <FormControlLabel value="20" control={<Radio />} label="20 Credits - £20" />
            </RadioGroup>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{marginTop: '20px'}}>
            <RouterLink to="/manage-wallet-transactions" sx={{mt: 2}}>Wallet Transactions</RouterLink>
          </div>
        </Grid>
      </Grid>
  );
}