import React from 'react'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

export const SubmitButton = (props) => {
  var enabledStyle = { background: '#fdb611', color: '#000000' };
  var disabledStyle = { background: '#e2e2e2', color: '#ffffff' };
  let buttonText = "Save";
  if(props.name){
    buttonText = props.name;
  }
  return (
    <Box sx={{ position: 'relative', mt: 3 }}>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={props.loading}
        style={props.loading ? disabledStyle : enabledStyle}
      >
        {buttonText}
      </Button>
      {props.loading && (
        <CircularProgress
          size={24}
          sx={{
            color: '#495563',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  )
};
