import React from 'react'
import { Link as RouterLink } from 'react-router-dom';

import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import StreetviewIcon from '@mui/icons-material/Streetview';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonIcon from '@mui/icons-material/Person';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import SportsIcon from '@mui/icons-material/Sports';
import GroupsIcon from '@mui/icons-material/Groups';
import TodayIcon from '@mui/icons-material/Today';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import HiveIcon from '@mui/icons-material/Hive';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

export const AdminRoleList = (props) => {
  return (
    <>
      <List subheader={<ListSubheader>Static Data</ListSubheader>}>
        <ListItem button component={RouterLink} to="/manage-honey-points">
          <ListItemIcon>
            <HiveIcon />
          </ListItemIcon>
          <ListItemText primary="Honey Points" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manage-sports">
          <ListItemIcon>
            <SportsSoccerIcon />
          </ListItemIcon>
          <ListItemText primary="Sports" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manage-positions">
          <ListItemIcon>
            <StreetviewIcon />
          </ListItemIcon>
          <ListItemText primary="Positions" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manage-league-divisions">
          <ListItemIcon>
            <SafetyDividerIcon />
          </ListItemIcon>
          <ListItemText primary="Leagues/Divisions" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manage-cups">
          <ListItemIcon>
            <EmojiEventsIcon />
          </ListItemIcon>
          <ListItemText primary="Cups" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manage-questions">
          <ListItemIcon>
            <HelpCenterIcon />
          </ListItemIcon>
          <ListItemText primary="Questions" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manage-rate-cards">
          <ListItemIcon>
            <LocalOfferIcon />
          </ListItemIcon>
          <ListItemText primary="Rate Cards" />
        </ListItem>
      </List>
      <List subheader={<ListSubheader>Super Admin</ListSubheader>}>
        <ListItem button component={RouterLink} to="/manage-clubs">
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Clubs" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manage-teams">
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary="Teams" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manage-players">
          <ListItemIcon>
            <DirectionsRunIcon />
          </ListItemIcon>
          <ListItemText primary="Players" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manage-sponsors">
          <ListItemIcon>
            <LocalAtmIcon />
          </ListItemIcon>
          <ListItemText primary="Sponsors" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manage-matches">
          <ListItemIcon>
            <SportsIcon />
          </ListItemIcon>
          <ListItemText primary="Matches" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manage-competitions">
          <ListItemIcon>
            <TodayIcon />
          </ListItemIcon>
          <ListItemText primary="Competitions" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manage-entries">
          <ListItemIcon>
            <ConfirmationNumberIcon />
          </ListItemIcon>
          <ListItemText primary="Entries" />
        </ListItem>
        <ListItem button component={RouterLink} to="/manage-users">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      </List>
    </>
  )
};
