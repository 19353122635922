import React, { useState, useEffect, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { TitleBar } from '../Shared/TitleBar';
import { Grid, Box, LinearProgress, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import Moment from 'react-moment';

export const CompetitionHome = () => {
  let { id } = useParams();
  const [competition, setCompetition] = useState(null);
  const [entries, setEntries] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);

  const loadPage = useCallback((competition, entries) => {
    setPageLoading(true);
    console.log('Getting competition');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/competitions/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setCompetition(response.data);
        console.log('Getting entries');
        var sortFilter = "sortBy=name&sortByDirection=asc"
        var filter = "?competitionId=" + id + "&" + sortFilter;
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries' + filter, {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {
            setEntries(response.data);
            setPageLoading(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  useEffect((competitions, entries) => {
    console.log('using effect in competition home component');
    loadPage(competitions, entries);
  }, [loadPage]);

  const handleRefresh = () => {
    loadPage(competition, entries);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Competition"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">

        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        {pageLoading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        {competition && (
          <Grid item xs={12}>
            <h2>{competition.name}</h2>
          </Grid>
        )}
        {competition && (
          <>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Entries
                      </Typography>
                      <Typography variant="h5" component="div">
                        {competition.totalEntries}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Correct Answers
                      </Typography>
                      <Typography variant="h5" component="div">
                        {competition.totalEntriesCorrect}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Votes
                      </Typography>
                      <Typography variant="h5" component="div">
                        {competition.totalEntriesVoted}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Ratings
                      </Typography>
                      <Typography variant="h5" component="div">
                        {competition.totalEntriesRated}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {entries && entries.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>User Name</TableCell>
                    <TableCell>Date Entered</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entries.map((entry) => (
                    <TableRow
                      key={entry.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {entry.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {entry.userName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Moment format="YYYY/MM/DD HH:mm">
                          {entry.dateCreated}
                        </Moment>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </div>
  );
}