import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';

export const CreateSport = () => {
  const navigate = useNavigate();
  const [sport, setSport] = useState({
    name: "",
    matchDuration: ""
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [matchDurationError, setMatchDurationError] = useState(false);
  const [matchDurationHelperText, setMatchDurationHelperText] = useState("");
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setSport(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports', {
      ...sport,
      matchDuration: parseInt(sport.matchDuration),
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Sport&action=created&name=' + sport.name + '&id=' + response.data.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setMatchDurationError(false);

    if ((field === "name" || field === "") && sport.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "matchDuration" || field === "") && sport.matchDuration === '') {
      hasErrors = true;
      setMatchDurationError(true);
      setMatchDurationHelperText("Match Duration is required");
    }

    return !hasErrors;
  }

  return (
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Create Sport"></TitleBar>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <TextField
              margin="normal"
              error={nameError}
              helperText={nameHelperText}
              name="name"
              required
              fullWidth
              id="name"
              label="Name"
              value={sport.name}
              onChange={handleFormChange}
              onBlur={() => validateForm("name")}
            />
            <TextField
              margin="normal"
              error={matchDurationError}
              helperText={matchDurationHelperText}
              name="matchDuration"
              required
              fullWidth
              id="matchDuration"
              label="Match Duration"
              value={sport.matchDuration}
              onChange={handleFormChange}
              onBlur={() => validateForm("matchDuration")}
            />
            <SubmitButton loading={loading}></SubmitButton>
            <AddBar cancelTo="/manage-sports"></AddBar>
          </Box>
        </Grid>
      </Grid>
  );
}