import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';

export const UpdateRateCard = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [rateCard, setRateCard] = useState({
    name: "",
    fanhivePercentage: "",
    clubPercentage: ""
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [fanhivePercentageError, setFanhivePercentageError] = useState(false);
  const [fanhivePercentageHelperText, setFanhivePercentageHelperText] = useState("");
  const [clubPercentageError, setClubPercentageError] = useState(false);
  const [clubPercentageHelperText, setClubPercentageHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setRateCard(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update rateCards');

    console.log('Getting rateCards: ' + id);
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/rate-cards/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setRateCard(prevState => ({
          ...prevState,
          name: PrepProp(response.data.name),
          fanhivePercentage: PrepProp(response.data.fanhivePercentage),
          clubPercentage: PrepProp(response.data.clubPercentage)
        }));

        setPageLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteRateCard = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/rate-cards/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/manage-rate-cards');
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/rate-cards/' + id, {
      ...rateCard,
      fanhivePercentage: parseInt(rateCard.fanhivePercentage),
      clubPercentage: parseInt(rateCard.clubPercentage),
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Rate Card&action=updated&name=' + rateCard.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setFanhivePercentageError(false);
    setClubPercentageError(false);

    if ((field === "name" || field === "") && rateCard.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "fanhivePercentage" || field === "") && rateCard.fanhivePercentage === '') {
      hasErrors = true;
      setFanhivePercentageError(true);
      setFanhivePercentageHelperText("Fanhive Percentage is required");
    }
    if ((field === "clubPercentage" || field === "") && rateCard.clubPercentage === '') {
      hasErrors = true;
      setClubPercentageError(true);
      setClubPercentageHelperText("Club Percentage is required");
    }

    return !hasErrors;
  }

  return (
      <Grid container>
        <TitleBar title="Update Rate Card" pageLoading={pageLoading}></TitleBar>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          {!pageLoading && (
            <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
              <TextField
                margin="normal"
                error={nameError}
                helperText={nameHelperText}
                name="name"
                required
                fullWidth
                id="name"
                label="Name"
                value={rateCard.name}
                onChange={handleFormChange}
                onBlur={() => validateForm("name")}
              />
              <TextField
                margin="normal"
                error={fanhivePercentageError}
                helperText={fanhivePercentageHelperText}
                name="fanhivePercentage"
                required
                fullWidth
                id="fanhivePercentage"
                label="Fanhive Percentage"
                value={rateCard.fanhivePercentage}
                onChange={handleFormChange}
                onBlur={() => validateForm("fanhivePercentage")}
              />
              <TextField
                margin="normal"
                error={clubPercentageError}
                helperText={clubPercentageHelperText}
                name="clubPercentage"
                required
                fullWidth
                id="clubPercentage"
                label="Club Percentage"
                value={rateCard.clubPercentage}
                onChange={handleFormChange}
                onBlur={() => validateForm("clubPercentage")}
              />
              <SubmitButton loading={loading}></SubmitButton>
              <UpdateBar handleDelete={deleteRateCard} loadingDelete={loadingDelete} cancelTo="/manage-rate-cards"></UpdateBar>
            </Box>
          )}
        </Grid>
      </Grid>
  );
}