import React, { useEffect, useState } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import {Grid, Box, LinearProgress, Button} from '@mui/material';
import axios from 'axios';

export const EntryTasks = (props) => {
  const [entry, setEntry] = useState(null);
  var enabledStyle = { background: '#fdb611', color: '#000000' };
  var disabledStyle = { background: '#e2e2e2', color: '#ffffff' };
  
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  var entryId = "";
  if (query.get("entryId") != null) {
    entryId = query.get("entryId");
  }

  useEffect(() => {
    console.log('using effect in entry tasks');
    axios.get(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries/${entryId}`, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setEntry(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [entryId]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          {entry && (
            <>
            {props.showCompetitionName && (
            <h2>{entry.competitionName}</h2>
            )}
            <h3>{props.infoText}</h3>
            {!props.hideAnswered && (
            <Button
              component={RouterLink}
              style={entry.answered ? disabledStyle : enabledStyle}
              sx={{mt: 4}}
              disabled={entry.answered}
              fullWidth
              variant="contained"
              to={`/questions?entryId=${entryId}`}
            >
              Answer Questions
            </Button>
            )}
            {!props.hideVoted && (
            <Button
              component={RouterLink}
              style={entry.voted ? disabledStyle : enabledStyle}
              sx={{mt: 4}}
              disabled={entry.voted}
              fullWidth
              variant="contained"
              to={`/voting?entryId=${entryId}&matchId=${entry.matchId}`}
            >
              Select Player of the Match
            </Button>
            )}
            {!props.hideRated && (
            <Button
              component={RouterLink}
              style={entry.rated ? disabledStyle : enabledStyle}
              sx={{mt: 4}}
              disabled={entry.rated}
              fullWidth
              variant="contained"
              to={`/rating?entryId=${entryId}&matchId=${entry.matchId}`}
            >
              Rate Players
            </Button>
            )}
            </>
          )}
          {!entry && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
        </Grid>
      </Grid>
    </div >
  );
}