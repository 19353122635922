import React, { useState, useEffect, useCallback, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { TitleBar } from '../Shared/TitleBar';
import { Grid, Box, LinearProgress, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import Pagination from '@mui/material/Pagination';
import { LoginContext } from '../../contexts/LoginContext';
import _ from 'lodash';

export const ManagePlayers = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  const [players, setPlayers] = useState(null);
  const [usePlayers, setUsePlayers] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [defaultPageSize] = useState(5);
  const [pager, setPager] = useState({
    count: 0,
    skip: 0,
    take: 0
  });

  const handleSearchChange = e => {
    const { value } = e.target;
    setSearchText(value);
    if(value === ""){
      setUsePlayers(players);
      var quotient1 = Math.floor(players.length/defaultPageSize);
      var remainder1 = players.length % defaultPageSize > 0 ? 1 : 0;
      setPager({
        count: quotient1 + remainder1,
        skip: 0,
        take: defaultPageSize
      });
    } else {
      var filPlayers = _.filter(players, (o) => {
        return o.firstName.toLowerCase().includes(value.toLowerCase()) || 
        o.surname.toLowerCase().includes(value.toLowerCase()) || 
        o.clubName.toLowerCase().includes(value.toLowerCase());
      });
      console.log(filPlayers.length);
      setUsePlayers(filPlayers);
      var quotient2 = Math.floor(filPlayers.length/defaultPageSize);
      var remainder2 = filPlayers.length % defaultPageSize > 0 ? 1 : 0;
      setPager({
        count: quotient2 + remainder2,
        skip: 0,
        take: defaultPageSize
      });
    }
  };

  const handlePageChange = (val) => {
    setPager({
      ...pager,
      skip: (val - 1) * defaultPageSize
    });
  }

  const playersPage = () => {
    return _.take(_.drop(usePlayers, pager.skip), pager.take);
  }

  const getPlayers = useCallback((players) => {
    console.log('Getting players');

    if (players) {
      setPlayers(null);
      setUsePlayers(null);
    }

    var sortFilter = "sortBy=first_name&sortByDirection=asc"
    var filter = userSub.role === "clubadmin" ? "?clubId=" + userSub.adminClubId + "&" + sortFilter : "?" + sortFilter;

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/players' + filter, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setPlayers(response.data);
        setUsePlayers(response.data);
        var quotient = Math.floor(response.data.length/defaultPageSize);
        var remainder = response.data.length % defaultPageSize > 0 ? 1 : 0;
        setPager({
          count: quotient + remainder,
          skip: 0,
          take: defaultPageSize
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          navigate('/log-out');
        }
      });
  }, [navigate, userSub, defaultPageSize]);

  useEffect((players) => {
    console.log('using effect in users component');
    getPlayers(players);
  }, [getPlayers]);

  const handleRefresh = () => {
    getPlayers(players);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Manage Players"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">
          <RouterLink to="/create-player">Create Player</RouterLink>
        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="normal"
            name="searchText"
            required
            fullWidth
            id="searchText"
            label="Search Player Name"
            value={searchText}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={12}>
          {usePlayers && usePlayers.length > 0 && (
            <div>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Photo</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Sport</TableCell>
                    <TableCell sx={{textAlign: 'center'}}>Number</TableCell>
                    <TableCell>Position</TableCell>                    
                    <TableCell>Teams</TableCell>
                    <TableCell>Socials</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {playersPage().map((player) => (
                    <TableRow
                      key={player.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {player.photoUrl && (
                        <img src={player.photoUrl} alt={player.name} style={{height:'30px'}}></img>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <RouterLink to={"/update-player/" + player.id}>{(userSub.role === "admin") ? player.name + " (" + player.clubName + ")" : player.name}</RouterLink>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {player.sportName}
                      </TableCell>                      
                      <TableCell sx={{textAlign: 'center'}} component="th" scope="row">
                        {player.number}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {player.positionName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {player.extraData && (
                          <ul style={{paddingLeft: 10}}>
                            {JSON.parse(player.extraData).teams.map((team) => (
                              <li key={team.teamId}>{team.name}</li>
                            ))}
                          </ul>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {player.instagramHandle && (
                        <Grid container>
                          <Grid item xs={3}><InstagramIcon></InstagramIcon></Grid>
                          <Grid item xs={9}>{player.instagramHandle}</Grid>
                        </Grid>
                        )}
                        {player.twitterHandle && (
                        <Grid container>
                          <Grid item xs={3}><TwitterIcon></TwitterIcon></Grid>
                          <Grid item xs={9}>{player.twitterHandle}</Grid>
                        </Grid>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination 
            count={pager.count} 
            sx={{marginTop: '20px'}} 
            defaultPage={pager.defaultPage} 
            variant="outlined" 
            onChange={(event,val)=> handlePageChange(val)} />
            </div>
          )}
          {!usePlayers && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {usePlayers && usePlayers.length === 0 && (
            <div>
              <h3>No Players.</h3>
              <div>Try Refresh or use the "Create Player" link above to create one.</div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}