import React, { useEffect } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import {Button, Grid} from '@mui/material';
import { TitleBar } from '../Shared/TitleBar';

export const QuestionsTimesUp = () => {
  var enabledStyle = { background: '#fdb611', color: '#000000' };
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  var entryId = "";
  if (query.get("entryId") != null) {
    entryId = query.get("entryId");
  }
  var retry = 0;
  if (query.get("retry") != null) {
    retry = parseInt(query.get("retry"));
  }

  useEffect((competitions) => {
    console.log('using effect in questions times up');

  }, []);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title={`Times Up`}></TitleBar>
          <h2>You have one more chance to retry.</h2>
        </Grid>
        <Grid item xs={12}>
          <Button
            component={RouterLink}
            style={enabledStyle}
            fullWidth
            variant="contained"
            to={`/questions?entryId=${entryId}&retry=${retry+1}`}
          >
            Retry
          </Button>
        </Grid>
      </Grid>
    </div >
  );
}