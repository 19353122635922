import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';

export const CreateHoneyPoint = () => {
  const navigate = useNavigate();
  const [honeyPoint, setHoneyPoint] = useState({
    name: "",
    slug: "",
    points: "0"
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [slugError, setSlugError] = useState(false);
  const [slugHelperText, setSlugHelperText] = useState("");
  const [pointsError, setPointsError] = useState(false);
  const [pointsHelperText, setPointsHelperText] = useState("");
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setHoneyPoint(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    console.log('using effect in create honey Point');
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/honey-points', {
      ...honeyPoint,
      points: parseInt(honeyPoint.points),
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Honey Point&action=created&name=' + honeyPoint.name + '&id=' + response.data.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setSlugError(false);
    setPointsError(false);

    if ((field === "name" || field === "") && honeyPoint.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "slug" || field === "") && honeyPoint.slug === '') {
      hasErrors = true;
      setSlugError(true);
      setSlugHelperText("Slug is required");
    }
    if ((field === "points" || field === "") && honeyPoint.points === '') {
      hasErrors = true;
      setPointsError(true);
      setPointsHelperText("Points is required");
    }

    return !hasErrors;
  }

  return (
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Create Honey Point"></TitleBar>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <TextField
              margin="normal"
              error={nameError}
              helperText={nameHelperText}
              name="name"
              required
              fullWidth
              id="name"
              label="Name"
              value={honeyPoint.name}
              onChange={handleFormChange}
              onBlur={() => validateForm("name")}
              autoFocus
            />
            <TextField
              margin="normal"
              error={slugError}
              helperText={slugHelperText}
              name="slug"
              required
              fullWidth
              id="slug"
              label="Slug"
              value={honeyPoint.slug}
              onChange={handleFormChange}
              onBlur={() => validateForm("slug")}
            />
            <TextField
              margin="normal"
              id="points"
              name="points"
              required
              error={pointsError}
              helperText={pointsHelperText}
              fullWidth
              label="Points"
              value={honeyPoint.points}
              onChange={handleFormChange}
            />
            <SubmitButton loading={loading}></SubmitButton>
            <AddBar cancelTo="/manage-honey-points"></AddBar>
          </Box>
        </Grid>
      </Grid>
  );
}