import React, { useState } from 'react';
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "../UserPool";
import { useNavigate, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { TitleBar } from './Shared/TitleBar';
import { SubmitButton } from './Shared/SubmitButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const ConfirmUser = () => {
  const navigate = useNavigate();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  var qCode = "";
  if (query.get("code") != null) {
    qCode = query.get("code");
  }
  var qUserName = "";
  if (query.get("userName") != null) {
    qUserName = query.get("userName");
  }
  var qEmail = "";
  if (query.get("email") != null) {
    qEmail = query.get("email");
  }
  const [confirmUser, setUser] = useState({
    userName: qUserName,
    email: qEmail,
    code: qCode
  });
  const [codeError, setCodeError] = useState(false);
  const [codeHelperText, setCodeHelperText] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [userNameHelperText, setUserNameHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const handleDialogClickOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleFormChange = e => {
    const { name, value } = e.target;
    setUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    const user = new CognitoUser({
      Username: confirmUser.userName,
      Pool: UserPool
    });

    user.confirmRegistration(confirmUser.code, true, (err, result) => {
      if (err) {
        console.log(err);
        setLoading(false);
        setDialogMessage(err.message);
        handleDialogClickOpen(true);
      } else {
        navigate(`/login?userName=${confirmUser.userName}`);
      }
    });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setUserNameError(false);
    setCodeError(false);

    if ((field === "userName" || field === "") && confirmUser.userName === '') {
      hasErrors = true;
      setUserNameError(true);
      setUserNameHelperText("User Name is required");
    }
    if ((field === "code" || field === "") && confirmUser.code === '') {
      hasErrors = true;
      setCodeError(true);
      setCodeHelperText("Code is required");
    }

    return !hasErrors;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Confirm Email"></TitleBar>
          <Box sx={{ mt: 2 }}>
            Check your inbox at <b>{confirmUser.email}</b> for the confirmation code we just sent you.
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="normal"
                  error={userNameError}
                  helperText={userNameHelperText}
                  name="userName"
                  required
                  fullWidth
                  id="userName"
                  label="User Name"
                  value={confirmUser.userName}
                  onChange={handleFormChange}
                  onBlur={() => validateForm("userName")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="normal"
                  error={codeError}
                  helperText={codeHelperText}
                  name="code"
                  required
                  fullWidth
                  id="code"
                  label="Code"
                  value={confirmUser.code}
                  onChange={handleFormChange}
                  onBlur={() => validateForm("code")}
                />
              </Grid>
            </Grid>
            <SubmitButton loading={loading} name="Confirm Email"></SubmitButton>
          </Box>
        </Grid>
      </Grid>


      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"There was a problem."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}