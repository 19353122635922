import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { DoneBar } from '../Shared/DoneBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { LoginContext } from '../../contexts/LoginContext';
import _ from 'lodash';
import moment from 'moment';

export const UpdateMatch = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  let { id } = useParams();
  const [match, setMatch] = useState({
    description: "",
    name: "",
    homeTeamId: "",
    awayTeamId: "",
    cupId: "0",
    startDate: "",
    autoDates: true
  });
  const [homeTeamIdError, setHomeTeamIdError] = useState(false);
  const [homeTeamIdHelperText, setHomeTeamIdHelperText] = useState("");
  const [awayTeamIdError, setAwayTeamIdError] = useState(false);
  const [awayTeamIdHelperText, setAwayTeamIdHelperText] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [startDateHelperText, setStartDateHelperText] = useState("");
  const [teams, setTeams] = useState(null);
  const [cups, setCups] = useState(null);
  const [matchPlayerCombinedList, setMatchPlayerCombinedList] = useState(null);
  const [matchPlayersBusy, setMatchPlayersBusy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    if(name === 'autoDates'){
      setMatch(prevState => ({
        ...prevState,
        autoDates: e.target.checked
      }));
    } else {
      setMatch(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleChangePlayer = e => {
    if(matchPlayersBusy){
      console.log('Still busy, try again');
      return;
    }
    setMatchPlayersBusy(true);
    const { name, checked } = e.target;
    var newMatchPlayerCombinedList = [];
    _.forEach(matchPlayerCombinedList, (matchPlayerCombined) => {
      if (name === matchPlayerCombined.playerId) {
        matchPlayerCombined.checked = checked;
      }
      newMatchPlayerCombinedList.push(matchPlayerCombined);
    });
    setMatchPlayerCombinedList(newMatchPlayerCombinedList);
    if(checked) {
      axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/match-players', {
        playerId: name,
        matchId: id
      }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setMatchPlayersBusy(false);
      })
      .catch(function (error) {
        console.log(error);
        setMatchPlayersBusy(false);
      });
    } else {
      var matchPlayerCombined = _.find(matchPlayerCombinedList, (p) => {
        return p.playerId === name;
      });
      axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/match-players/' + matchPlayerCombined.matchPlayerId,
      {
        headers: {
          'Authorization': localStorage.getItem('jwtToken')
        },
        data: {
          matchId: matchPlayerCombined.matchId,
          playerId: matchPlayerCombined.playerId
        }
      })
        .then(function (response) {
          setMatchPlayersBusy(false);
        })
        .catch(function (error) {
          console.log(error);
          setMatchPlayersBusy(false);
        });
    }
  };

  const ownTeams = () => {
    return _.filter(teams, (o) => {
      if(userSub.adminClubId){
        return o.clubId === userSub.adminClubId;
      }
      return o;
    })
  }
  const opponentTeams = () => {
    return _.filter(teams, (o) => {
      return o.clubId !== userSub.adminClubId;
    })
  }

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update match');

    console.log('Getting teams');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/teams?sortBy=group_name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {

        setTeams(response.data);

        console.log('Getting cups');
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/cups?sortBy=name&sortByDirection=asc', {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {
            
            setCups(response.data);
            
            console.log('Getting match: ' + id);
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/matches/' + id, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response) {
                setMatch(prevState => ({
                  ...prevState,
                  description: PrepProp(response.data.description),
                  name: PrepProp(response.data.name),
                  homeTeamId: PrepProp(response.data.homeTeamId),
                  awayTeamId: PrepProp(response.data.awayTeamId),
                  cupId: PrepProp(response.data.cupId) === "" ? "0" : PrepProp(response.data.cupId),
                  startDate: PrepProp(response.data.startDate),
                  autoDates: (PrepProp(response.data.autoDates) === "" || PrepProp(response.data.autoDates) === 0) ? false : true
                }));

                axios.get(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/team-players?sortBy=player_name&sortByDirection=asc&teamId=${response.data.homeTeamId}`, {
                  headers: {
                    'Authorization': localStorage.getItem('jwtToken')
                  }
                })
                  .then(function (response) {
                    var localPlayers = response.data;
                    
                    axios.get(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/match-players?matchId=${id}`, {
                      headers: {
                        'Authorization': localStorage.getItem('jwtToken')
                      }
                    })
                      .then(function (response) {
                        var localMatchPlayers = response.data;
                        setMatchPlayersBusy(false);

                        var matchPlayerCombinedList = [];
                        _.forEach(localPlayers, (localPlayer) => {
                          var matchPlayerCombined = {
                            matchPlayerId: null,
                            matchId: id,
                            playerId: localPlayer.playerId,
                            playerName: localPlayer.playerName,
                            checked: false
                          }
                          var f = _.find(localMatchPlayers, (p) => {
                            return p.matchId === matchPlayerCombined.matchId && p.playerId === matchPlayerCombined.playerId;
                          });
                          if (f) {
                            matchPlayerCombined.matchPlayerId = f.id;
                            matchPlayerCombined.checked = true;
                          }
                          matchPlayerCombinedList.push(matchPlayerCombined);
                        });
                        setMatchPlayerCombinedList(matchPlayerCombinedList);

                        setPageLoading(false);
                      })
                      .catch(function (error) {
                        console.log(error);
                      });

                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });

      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id, userSub]);

  const deleteMatch = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/matches/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/complete?entity=Match&action=deleted&name=' + match.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/matches/' + id, {
      ...match,
      startDate: moment(match.startDate).format('yyyy-MM-DDTHH:mm')
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Match&action=updated&name=' + match.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setHomeTeamIdError(false);
    setAwayTeamIdError(false);
    setStartDateError(false);

    if ((field === "homeTeamId" || field === "") && match.homeTeamId === '') {
      hasErrors = true;
      setHomeTeamIdError(true);
      setHomeTeamIdHelperText("Home Team is required");
    }
    if ((field === "awayTeamId" || field === "") && match.awayTeamId === '') {
      hasErrors = true;
      setAwayTeamIdError(true);
      setAwayTeamIdHelperText("Away Team is required");
    }
    if ((field === "startDate" || field === "") && (match.startDate === '' || match.startDate === null)) {
      hasErrors = true;
      setStartDateError(true);
      setStartDateHelperText("Start Date is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title="Update Match" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={8}>
      <h2>{match.name}</h2>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        {!pageLoading && (
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="homeTeamIdLabel">Own Team</InputLabel>
              <Select
                labelId="homeTeamIdLabel"
                error={homeTeamIdError}
                id="homeTeamId"
                name="homeTeamId"
                value={match.homeTeamId}
                label="Own Team"
                onChange={handleFormChange}
              >
                {teams && ownTeams().map((team) => (
                  <MenuItem value={team.id} key={team.id}>{team.clubName} ({team.leagueDivisionName})</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{homeTeamIdHelperText}</FormHelperText>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="awayTeamIdLabel">Opponent Team</InputLabel>
              <Select
                labelId="awayTeamIdLabel"
                error={awayTeamIdError}
                id="awayTeamId"
                name="awayTeamId"
                value={match.awayTeamId}
                label="Opponent Team"
                onChange={handleFormChange}
              >
                {teams && opponentTeams().map((team) => (
                  <MenuItem value={team.id} key={team.id}>{team.clubName} ({team.leagueDivisionName})</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{awayTeamIdHelperText}</FormHelperText>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="cupIdLabel">Cup</InputLabel>
              <Select
                labelId="cupIdLabel"
                id="cupId"
                name="cupId"
                value={match.cupId}
                label="Cup"
                onChange={handleFormChange}
              >
                <MenuItem value="0">N/A</MenuItem>
                {cups && cups.map((cup) => (
                  <MenuItem value={cup.id} key={cup.id}>{cup.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              id="description"
              name="description"
              fullWidth
              label="Description"
              multiline
              rows={4}
              value={match.description}
                onChange={handleFormChange}
            />
            <FormControl margin="normal" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Start"
                  value={match.startDate}
                  error={startDateError}
                  inputFormat="yyyy/MM/dd HH:mm"
                  onChange={(newValue) => {
                    setMatch(prevState => ({
                      ...prevState,
                      startDate: newValue
                    }));
                  }}
                />
              </LocalizationProvider>
              <FormHelperText error>{startDateHelperText}</FormHelperText>
            </FormControl>
            <FormGroup>
              <FormControlLabel control={<Switch id="autoDates" name="autoDates" checked={match.autoDates} onChange={handleFormChange} />} label="Auto Dates" />
            </FormGroup>
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deleteMatch} loadingDelete={loadingDelete} cancelTo="/manage-matches"></UpdateBar>
            <hr></hr>
            <Grid container>
              <Grid item><h2>Team Sheet</h2></Grid>
              <Grid item sx={{ pt: 2 }}>
                {matchPlayersBusy && (
                  <CircularProgress style={{ padding: "10px" }} />
                )}
              </Grid>
            </Grid>
            <FormGroup>
              {matchPlayerCombinedList.map((matchPlayerCombined) => (
                <FormControlLabel
                  key={matchPlayerCombined.playerId}
                  control={
                    <Checkbox name={matchPlayerCombined.playerId} checked={matchPlayerCombined.checked} onChange={handleChangePlayer} />
                  }
                  label={matchPlayerCombined.playerName}
                />
              ))}
            </FormGroup>
            <DoneBar doneTo="/manage-matches"></DoneBar>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}