import React from 'react';
import Grid from '@mui/material/Grid';
import { TitleBar } from '../Shared/TitleBar';

export const PlayerRatingsHome = () => {
  return (
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Player Ratings"></TitleBar>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          
        </Grid>
      </Grid>
  );
}