import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';

export const CreateUser = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    userName: "",
    email: "",
    firstName: "",
    surname: ""
  });
  const [userNameError, setUserNameError] = useState(false);
  const [userNameHelperText, setUserNameHelperText] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameHelperText, setFirstNameHelperText] = useState("");
  const [surnameError, setSurnameError] = useState(false);
  const [surnameHelperText, setSurnameHelperText] = useState("");
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/users', user, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-users');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setUserNameError(false);
    setEmailError(false);
    setFirstNameError(false);
    setSurnameError(false);

    if ((field === "userName" || field === "") && user.userName === '') {
      hasErrors = true;
      setUserNameError(true);
      setUserNameHelperText("User Name is required");
    }
    if ((field === "email" || field === "") && user.email === '') {
      hasErrors = true;
      setEmailError(true);
      setEmailHelperText("Email is required");
    }
    if ((field === "firstName" || field === "") && user.firstName === '') {
      hasErrors = true;
      setFirstNameError(true);
      setFirstNameHelperText("First Name is required");
    }
    if ((field === "surname" || field === "") && user.surname === '') {
      hasErrors = true;
      setSurnameError(true);
      setSurnameHelperText("urname is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Create User"></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
          <TextField
            margin="normal"
            error={userNameError}
            helperText={userNameHelperText}
            name="userName"
            required
            fullWidth
            id="userName"
            label="User Name"
            value={user.userName}
            onChange={handleFormChange}
            onBlur={() => validateForm("userName")}
          />
          <TextField
            margin="normal"
            error={emailError}
            helperText={emailHelperText}
            name="email"
            required
            fullWidth
            id="email"
            label="Email"
            value={user.email}
            onChange={handleFormChange}
            onBlur={() => validateForm("email")}
          />
          <TextField
            margin="normal"
            error={firstNameError}
            helperText={firstNameHelperText}
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First Name"
            value={user.firstName}
            onChange={handleFormChange}
            onBlur={() => validateForm("firstName")}
          />
          <TextField
            margin="normal"
            error={surnameError}
            helperText={surnameHelperText}
            name="surname"
            required
            fullWidth
            id="surname"
            label="Surname"
            value={user.surname}
            onChange={handleFormChange}
            onBlur={() => validateForm("surname")}
          />
          <SubmitButton loading={loading}></SubmitButton>
          <AddBar cancelTo="/manage-users"></AddBar>
        </Box>
      </Grid>
    </Grid>
  );
}