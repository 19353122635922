import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';
import _ from "lodash";

export const CreateQuestionAnswer = () => {
  const navigate = useNavigate();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  var questionId = "";
  if (query.get("questionId") != null) {
    questionId = query.get("questionId");
  }

  const [questionAnswer, setQuestionAnswer] = useState({
    name: "",
    questionId: "",
    isCorrect: false
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [questionIdError, setQuestionIdError] = useState(false);
  const [questionIdHelperText, setQuestionIdHelperText] = useState("");
  const [questions, setQuestions] = useState(null);
  const [questionName, setQuestionName] = useState("");
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = e => {
    const { name, value } = e.target;
    if(name === 'isCorrect'){
      setQuestionAnswer(prevState => ({
        ...prevState,
        isCorrect: e.target.checked
      }));
    } else {
      setQuestionAnswer(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  useEffect(() => {
    console.log('using effect in update questionAnswer');

    console.log('Getting questions');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/questions?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setQuestions(response.data);
        setQuestionAnswer({
          name: "",
          questionId: questionId,
          isCorrect: false
        });
        setQuestionName(_.find(response.data, (o) => {return o.id === questionId}).name);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [questionId]);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/question-answers', questionAnswer, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        var specialCase = questionId === "" ? "" : "specialCase=1&";
        navigate('/complete?' + specialCase + 'entity=Question Answer&action=created&name=' + questionAnswer.name + '&id=' + response.data.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setQuestionIdError(false);

    if ((field === "name" || field === "") && questionAnswer.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "questionId" || field === "") && questionAnswer.questionId === '') {
      hasErrors = true;
      setQuestionIdError(true);
      setQuestionIdHelperText("Question is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Create Question Answer"></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
          {questionId === "" && (
          <FormControl margin="normal" fullWidth>
            <InputLabel id="questionIdLabel">Question</InputLabel>
            <Select
              labelId="questionIdLabel"
              error={questionIdError}
              id="questionId"
              name="questionId"
              value={questionAnswer.questionId}
              label="Question"
              onChange={handleFormChange}
            >
              {questions && questions.map((question) => (
                <MenuItem value={question.id} key={question.id}>{question.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{questionIdHelperText}</FormHelperText>
          </FormControl>
          )}
          {questionId !== "" && (
            <div>{questionName}</div>
          )}
          <TextField
            margin="normal"
            error={nameError}
            helperText={nameHelperText}
            name="name"
            required
            fullWidth
            id="name"
            label="Answer"
            value={questionAnswer.name}
            onChange={handleFormChange}
            onBlur={() => validateForm("name")}
          />
          <FormGroup>
            <FormControlLabel control={<Switch id="isCorrect" name="isCorrect" checked={questionAnswer.isCorrect} onChange={handleFormChange} />} label="Is Correct" />
          </FormGroup>
          <SubmitButton loading={loading}></SubmitButton>
          <AddBar cancelTo={questionId === "" ? "/manage-question-answers" : "/manage-questions"}></AddBar>
        </Box>
      </Grid>
    </Grid>
  );
}