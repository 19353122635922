import React, { useState, useEffect, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TitleBar } from '../Shared/TitleBar';
import { Grid, Box, LinearProgress, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import Moment from 'react-moment';

export const ManageEntryQuestionAnswers = () => {
  const navigate = useNavigate();
  const [entryQuestionAnswers, setEntryQuestionAnswers] = useState(null);

  const getEntryQuestionAnswers = useCallback((entryQuestionAnswers) => {
    console.log('Getting entryQuestionAnswers');
    
    if(entryQuestionAnswers)
      setEntryQuestionAnswers(null);

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entry-question-answers', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setEntryQuestionAnswers(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status === 401) {
          navigate('/log-out');
        }
      });
  }, [navigate]);

  useEffect((entryQuestionAnswers) => {
    console.log('using effect in entryquestionanswers component');
    getEntryQuestionAnswers(entryQuestionAnswers);
  }, [getEntryQuestionAnswers]);

  const handleRefresh = () => {
    getEntryQuestionAnswers(entryQuestionAnswers);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Manage Entry Question Answers"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">
          <RouterLink to="/create-entry-question-answer">Create Entry Question Answer</RouterLink>
        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {entryQuestionAnswers && entryQuestionAnswers.length > 0 && (
          <TableContainer component={Paper} sx={{mt: 2}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Details</TableCell>
                  <TableCell>Is Correct</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entryQuestionAnswers.map((entryQuestionAnswer) => (
                  <TableRow
                    key={entryQuestionAnswer.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div>{entryQuestionAnswer.entryName}</div>
                      <div>{entryQuestionAnswer.competitionName}</div>
                      <div>{entryQuestionAnswer.questionName}</div>
                      <div>{entryQuestionAnswer.questionAnswerName}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {entryQuestionAnswer.isCorrect ? "Yes" : "No"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {entryQuestionAnswer.createdBy}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Moment format="YYYY/MM/DD">
                        {entryQuestionAnswer.dateCreated}
                      </Moment>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <RouterLink to={"/update-entry-question-answer/" + entryQuestionAnswer.id}>
                        <EditIcon />
                      </RouterLink>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )}
          {!entryQuestionAnswers && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {entryQuestionAnswers && entryQuestionAnswers.length === 0 && (
            <div>
              <h3>No Question Answers.</h3>
              <div>Try Refresh or use the "Create Question Answer" link above to create one.</div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}