import React, { useState, useEffect, useCallback, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TitleBar } from '../Shared/TitleBar';
import { Grid, Box, LinearProgress, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import Moment from 'react-moment';
import { LoginContext } from '../../contexts/LoginContext';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import moment from 'moment';

export const ManageCompetitions = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  const [competitions, setCompetitions] = useState(null);

  const getCompetitions = useCallback((competitions) => {
    console.log('Getting competitions');
    
    if(competitions)
      setCompetitions(null);

    var sortFilter = "sortBy=match_start_date&sortByDirection=desc"
    var filter = userSub.role === "clubadmin" ? "?clubId=" + userSub.adminClubId + "&" + sortFilter : "?" + sortFilter;

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/competitions' + filter, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setCompetitions(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status === 401) {
          navigate('/log-out');
        }
      });
  }, [navigate, userSub]);

  useEffect((competitions) => {
    console.log('using effect in competitions component');
    getCompetitions(competitions);
  }, [getCompetitions]);

  const handleRefresh = () => {
    getCompetitions(competitions);
  }

  const rowColor = (competition) => {
    // var duration = moment.duration(moment(competition.matchStartDate).diff(moment()));
    // var hours = duration.asHours();
    if(competition.status === "RESULTED") {
      return 'row-blue';
    }
    return moment(competition.matchStartDate) < moment() ? "row-light-red" : "";
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Manage Competitions"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">
          <RouterLink to="/create-competition">Create Competition</RouterLink>
        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {competitions && competitions.length > 0 && (
          <TableContainer component={Paper} sx={{mt: 2}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>Entries</TableCell>
                  <TableCell>Sponsor Player/Fan %</TableCell>
                  <TableCell>Proceeds Player/Fan %</TableCell>
                  <TableCell>Entry</TableCell>
                  <TableCell>Entry</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {competitions.map((competition) => (
                  <TableRow
                    key={competition.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={rowColor(competition)}
                  >
                    <TableCell component="th" scope="row">
                      <RouterLink to={"/update-competition/" + competition.id}>{competition.name}</RouterLink>
                      <div>
                        Match Start: <Moment format="YYYY/MM/DD HH:mm">
                          {competition.matchStartDate}
                        </Moment>
                      </div>
                      <div>
                        Voting open: <Moment format="YYYY/MM/DD HH:mm">
                          {competition.openDate}
                        </Moment>
                      </div>
                      <div>
                        Competition Close: <Moment format="YYYY/MM/DD HH:mm">
                          {competition.endDate}
                        </Moment>
                      </div>
                      <div>
                      <RouterLink to={"/competition-results/" + competition.id}>Results <SportsScoreIcon></SportsScoreIcon></RouterLink>
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div>Entries: {competition.totalEntries}</div>
                      <div>Answered: {competition.totalEntriesAnswered}</div>
                      <div>Correct: {competition.totalEntriesCorrect}</div>
                      <div>Voted: {competition.totalEntriesVoted}</div>
                      <div>Rated: {competition.totalEntriesRated}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {competition.sponsorPlayerPercentage}/{competition.sponsorFanPercentage}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {competition.proceedsPlayerPercentage}/{competition.proceedsFanPercentage}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div>Max Entries: {competition.maxEntries === "" ? competition.maxEntries : "Unlimited"}</div>
                      <div>Entry Credits: {competition.entryCredits}</div>
                      <div>Questions: {competition.totalQuestions}</div>
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{textAlign: 'right'}}>
                      {competition.sponsorLogo && (
                      <img src={competition.sponsorLogo} alt={competition.sponsorLogo} style={{height:'15px'}}></img>
                      )}
                      {competition.sponsorCommitment && (
                        <div>
                          £{competition.sponsorCommitment}
                      </div>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {competition.status}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )}
          {!competitions && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {competitions && competitions.length === 0 && (
            <div>
              <h3>No Competitions.</h3>
              <div>Try Refresh or use the "Create Competition" link above to create one.</div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}