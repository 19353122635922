import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { DoneBar } from '../Shared/DoneBar';
import { SubmitButton } from '../Shared/SubmitButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { LoginContext } from '../../contexts/LoginContext';
import _ from "lodash";

export const UpdatePlayer = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  let { id } = useParams();
  const [player, setPlayer] = useState({
    firstName: "",
    name: "",
    surname: "",
    sportId: "",
    positionId: "",
    number: "",
    positionName: "",
    clubId: userSub.adminClubId === null ? "" : userSub.adminClubId,
    photoUrl: "",
    twitterHandle: "",
    instagramHandle: ""
  });
  const [positions, setPositions] = useState(null);
  const [sports, setSports] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [playerTeamCombinedList, setPlayerTeamCombinedList] = useState(null);
  const [playerTeamsBusy, setPlayerTeamsBusy] = useState(false);
  const [clubIdError, setClubIdError] = useState(false);
  const [clubIdHelperText, setClubIdHelperText] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameHelperText, setFirstNameHelperText] = useState("");
  const [sportIdError, setSportIdError] = useState(false);
  const [sportIdHelperText, setSportIdHelperText] = useState("");
  const [positionIdError, setPositionIdError] = useState(false);
  const [positionIdHelperText, setPositionIdHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setPlayer(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === "sportId") {
      getPositions(value);
    }
    if (name === "positionId" && value !== "0") {
      var position = _.find(positions, (o) => {
        return o.id === value;
      });
      setPlayer({
        ...player,
        positionId: value,
        number: position.number
      })
    }
  };

  const getPositions = (sportId) => {
    console.log('Getting positions');
    axios.get(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/positions?sportId=${sportId}&sortBy=number&sortByDirection=asc`, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setPositions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeTeam = e => {
    if (playerTeamsBusy) {
      console.log('Still busy, try again');
      return;
    }
    setPlayerTeamsBusy(true);
    const { name, checked } = e.target;
    var newPlayerTeamCombinedList = [];
    _.forEach(playerTeamCombinedList, (playerTeamCombined) => {
      if (name === playerTeamCombined.teamId) {
        playerTeamCombined.checked = checked;
      }
      newPlayerTeamCombinedList.push(playerTeamCombined);
    });
    setPlayerTeamCombinedList(newPlayerTeamCombinedList);
    if (checked) {
      axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/team-players', {
        teamId: name,
        playerId: id
      }, {
        headers: {
          'Authorization': localStorage.getItem('jwtToken')
        }
      })
        .then(function (response) {
          setPlayerTeamsBusy(false);
        })
        .catch(function (error) {
          console.log(error);
          setPlayerTeamsBusy(false);
        });
    } else {
      var playerTeamCombined = _.find(playerTeamCombinedList, (p) => {
        return p.teamId === name;
      });
      axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/team-players/' + playerTeamCombined.teamPlayerId, {
        headers: {
          'Authorization': localStorage.getItem('jwtToken')
        }
      })
        .then(function (response) {
          setPlayerTeamsBusy(false);
        })
        .catch(function (error) {
          console.log(error);
          setPlayerTeamsBusy(false);
        });
    }
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update player');

    console.log('Getting clubs');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setClubs(response.data);

        console.log('Getting positions: ' + id);
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports', {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {

            setSports(response.data);

            console.log('Getting player: ' + id);
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/players/' + id, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response) {
                var localPlayer = response.data;
                setPlayer(prevState => ({
                  ...prevState,
                  name: PrepProp(localPlayer.name),
                  firstName: PrepProp(localPlayer.firstName),
                  surname: PrepProp(localPlayer.surname),
                  userId: PrepProp(localPlayer.userId),
                  sportId: PrepProp(localPlayer.sportId),
                  positionId: PrepProp(response.data.positionId) === "" ? "0" : PrepProp(response.data.positionId),
                  number: PrepProp(localPlayer.number),
                  clubId: PrepProp(localPlayer.clubId),
                  photoUrl: PrepProp(localPlayer.photoUrl),
                  twitterHandle: PrepProp(localPlayer.twitterHandle),
                  instagramHandle: PrepProp(localPlayer.instagramHandle)
                }));

                getPositions(localPlayer.sportId);

                console.log('Getting teams');
                setPlayerTeamsBusy(true);
                axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/teams?clubId=' + localPlayer.clubId, {
                  headers: {
                    'Authorization': localStorage.getItem('jwtToken')
                  }
                })
                  .then(function (response) {
                    var localTeams = response.data;

                    console.log('Getting player teams');
                    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/team-players?playerId=' + id, {
                      headers: {
                        'Authorization': localStorage.getItem('jwtToken')
                      }
                    })
                      .then(function (response) {
                        var localPlayerTeams = response.data;
                        setPlayerTeamsBusy(false);

                        var playerTeamCombinedList = [];
                        _.forEach(localTeams, (localTeam) => {
                          var playerTeamCombined = {
                            teamPlayerId: null,
                            playerId: id,
                            teamId: localTeam.id,
                            teamName: localTeam.name,
                            checked: false
                          }
                          var f = _.find(localPlayerTeams, (p) => {
                            return p.playerId === playerTeamCombined.playerId && p.teamId === playerTeamCombined.teamId;
                          });
                          if (f) {
                            playerTeamCombined.teamPlayerId = f.id;
                            playerTeamCombined.checked = true;
                          }
                          playerTeamCombinedList.push(playerTeamCombined);
                        });
                        setPlayerTeamCombinedList(playerTeamCombinedList);

                        setPageLoading(false);
                      })
                      .catch(function (error) {
                        console.log(error);
                      });

                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              })
              .catch(function (error) {
                console.log(error);
              });

          })
          .catch(function (error) {
            console.log(error);
          });

      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deletePlayer = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/players/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/complete?entity=Player&action=deleted&name=' + player.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/players/' + id, {
      ...player,
      number: player.number === "" ? null : parseInt(player.number),
      positionId: player.positionId === "0" ? null : player.positionId,
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Player&action=updated&name=' + player.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setFirstNameError(false);
    setSportIdError(false);
    setPositionIdError(false);
    setClubIdError(false);

    if ((field === "clubId" || field === "") && player.clubId === '') {
      hasErrors = true;
      setClubIdError(true);
      setClubIdHelperText("Club is required");
    }

    if ((field === "firstName" || field === "") && player.firstName === '') {
      hasErrors = true;
      setFirstNameError(true);
      setFirstNameHelperText("First Name is required");
    }
    if ((field === "sportId" || field === "") && player.sportId === '') {
      hasErrors = true;
      setSportIdError(true);
      setSportIdHelperText("Sport is required");
    }
    if ((field === "positionId" || field === "") && player.positionId === '') {
      hasErrors = true;
      setPositionIdError(true);
      setPositionIdHelperText("Position is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title="Update Player" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            {userSub.adminClubId === null && (
              <FormControl margin="normal" fullWidth>
                <InputLabel id="positionIdLabel">Club</InputLabel>
                <Select
                  labelId="clubIdLabel"
                  error={clubIdError}
                  required
                  id="clubId"
                  name="clubId"
                  value={player.clubId}
                  label="Position"
                  onChange={handleFormChange}
                >
                  {clubs && clubs.map((club) => (
                    <MenuItem value={club.id} key={club.id}>{club.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{clubIdHelperText}</FormHelperText>
              </FormControl>
            )}
            <TextField
              margin="normal"
              error={firstNameError}
              helperText={firstNameHelperText}
              name="firstName"
              required
              fullWidth
              id="firstName"
              label="First Name"
              value={player.firstName}
              onChange={handleFormChange}
              onBlur={() => validateForm("firstName")}
            />
            <TextField
              margin="normal"
              name="surname"
              fullWidth
              id="surname"
              label="Surname"
              value={player.surname}
              onChange={handleFormChange}
            />
            <FormControl margin="normal" fullWidth>
              <InputLabel id="sportIdLabel">Sport</InputLabel>
              <Select
                labelId="sportIdLabel"
                error={sportIdError}
                required
                id="sportId"
                name="sportId"
                value={player.sportId}
                label="Sport"
                onChange={handleFormChange}
              >
                {sports && sports.map((sport) => (
                  <MenuItem value={sport.id} key={sport.id}>{sport.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{sportIdHelperText}</FormHelperText>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="positionIdLabel">Position</InputLabel>
              <Select
                labelId="positionIdLabel"
                error={positionIdError}
                required
                id="positionId"
                name="positionId"
                value={player.positionId}
                label="Position"
                onChange={handleFormChange}
              >
                <MenuItem value="0">N/A</MenuItem>
                {positions && positions.map((position) => (
                  <MenuItem value={position.id} key={position.id}>{position.number} - {position.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{positionIdHelperText}</FormHelperText>
            </FormControl>
            <TextField
              margin="normal"
              name="number"
              required
              fullWidth
              id="number"
              label="Number"
              value={player.number}
              onChange={handleFormChange}
            />
            <TextField
              margin="normal"
              name="photoUrl"
              required
              fullWidth
              id="photoUrl"
              label="Photo"
              value={player.photoUrl}
              onChange={handleFormChange}
            />
            <TextField
              margin="normal"
              name="twitterHandle"
              fullWidth
              id="twitterHandle"
              label="Twitter Handle"
              value={player.twitterHandle}
              onChange={handleFormChange}
            />
            <TextField
              margin="normal"
              name="instagramHandle"
              fullWidth
              id="instagramHandle"
              label="Instagram Handle"
              value={player.instagramHandle}
              onChange={handleFormChange}
            />
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deletePlayer} loadingDelete={loadingDelete} cancelTo="/manage-players"></UpdateBar>
            <hr></hr>
            <Grid container>
              <Grid item><h2>Player Teams</h2></Grid>
              <Grid item sx={{ pt: 2 }}>
                {playerTeamsBusy && (
                  <CircularProgress style={{ padding: "10px" }} />
                )}
              </Grid>
            </Grid>
            <FormGroup>
              {playerTeamCombinedList.map((playerTeamCombined) => (
                <FormControlLabel
                  key={playerTeamCombined.teamId}
                  control={
                    <Checkbox name={playerTeamCombined.teamId} checked={playerTeamCombined.checked} onChange={handleChangeTeam} />
                  }
                  label={playerTeamCombined.teamName}
                />
              ))}
            </FormGroup>
            <DoneBar doneTo="/manage-players"></DoneBar>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}