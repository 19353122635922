import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Grid, Box, LinearProgress, Card, CardContent, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SelectButton } from '../Shared/SelectButton';
import { LoginContext } from '../../contexts/LoginContext';

export const Voting = () => {
  const navigate = useNavigate();
  const { userSub, wallet, setWallet } = useContext(LoginContext);
  const [matchPlayers, setMatchPlayers] = useState(null);
  const [entry, setEntry] = useState(null);
  const [loading, setLoading] = React.useState(false);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  var entryId = "";
  if (query.get("entryId") != null) {
    entryId = query.get("entryId");
  }
  var matchId = "";
  if (query.get("matchId") != null) {
    matchId = query.get("matchId");
  }

  const handleVote = (playerId) => {
    setLoading(true);
    axios.post(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entry-votes`, {
      entryId: entryId,
      playerId: playerId
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {

        axios.put(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries/${entryId}`, {
          voted: true
        }, {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function () {

            var slug = "selected-player"
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/honey-points?slug=' + slug, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response3) {
                var localHoneyPoint = response3.data[0];
                axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/honey-transactions', {
                  userId: userSub.id,
                  walletId: wallet.id,
                  entryId: entryId,
                  isDebit: false,
                  honeyPointSlug: slug
                }, {
                  headers: {
                    'Authorization': localStorage.getItem('jwtToken')
                  }
                })
                  .then(function (response4) {
                    var newWallet = {
                      id: wallet.id,
                      balance: wallet.balance,
                      expectedBalance: wallet.balance,
                      honeyPointsBalance: wallet.honeyPointsBalance,
                      expectedHoneyPointsBalance: wallet.honeyPointsBalance + localHoneyPoint.points,
                    };
                    setWallet(newWallet);
                    localStorage.setItem("wallet", JSON.stringify(newWallet));
                    setLoading(false);
                    if(!entry.rated){
                      navigate(`/rating?entryId=${entryId}&matchId=${entry.matchId}`);
                    } else {
                      navigate(`/completed?entryId=${entryId}`);
                    }
                    
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const getMatchPlayers = useCallback((matchPlayers) => {
    console.log('Getting match players');

    if (matchPlayers)
      setMatchPlayers(null);

    axios.get(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/match-players?matchId=${matchId}`, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setMatchPlayers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [matchId]);

  useEffect((matchPlayers) => {
    console.log('using effect in voting');

    getMatchPlayers(matchPlayers);

    axios.get(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries/${entryId}`, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setEntry(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [entryId, getMatchPlayers]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Vote for the Player of the Match"></TitleBar>
          {entry && (
            <h3>{entry.competitionName}</h3>
          )}
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          {matchPlayers && matchPlayers.length > 0 && (
            <Grid container spacing={2}>
              {matchPlayers.map((matchPlayer) => (
                <Grid item xs={12} sm={6} key={matchPlayer.id}>
                  <Card sx={{ minWidth: 275 }} key={matchPlayer.id}>
                    <CardContent>
                      <Typography variant="h5" component="div">
                        <Grid container>
                          <Grid item xs={4} sx={{ textAlign: 'center' }}>
                            <img src={matchPlayer.photoUrl} alt={matchPlayer.playerName} style={{ height: '40px' }}></img>
                          </Grid>
                          <Grid item xs={8} sx={{ textAlign: 'center' }}>
                            <div className="voteBox">
                              <div className="voteName">
                                <div>{matchPlayer.number} - {matchPlayer.playerName}</div>
                                <div>{matchPlayer.positionName}</div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div className="voteButton">
                              <SelectButton handleVote={handleVote} playerId={matchPlayer.playerId} loading={loading} name={"Vote for " + matchPlayer.playerFirstName}></SelectButton>
                            </div>
                          </Grid>
                        </Grid>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
          {!matchPlayers && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {matchPlayers && matchPlayers.length === 0 && (
            <div>
              <h3>No Match Players.</h3>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}