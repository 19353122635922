import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';

export const CreateCompetitionQuestion = () => {
  const navigate = useNavigate();
  const [competitionQuestion, setCompetitionQuestion] = useState({
    competitionId: "",
    competitionName: "",
    questionId: "",
    questionName: ""
  });
  const [competitionIdError, setCompetitionIdError] = useState(false);
  const [competitionIdHelperText, setCompetitionIdHelperText] = useState("");
  const [questionIdError, setQuestionIdError] = useState(false);
  const [questionIdHelperText, setQuestionIdHelperText] = useState("");
  const [competitions, setCompetitions] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setCompetitionQuestion(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    console.log('using effect in update competitionQuestion');

    console.log('Getting competitions');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/competitions?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setCompetitions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    console.log('Getting questions');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/questions?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setQuestions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/competition-questions', competitionQuestion, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-competition-questions');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setCompetitionIdError(false);
    setQuestionIdError(false);

    if ((field === "competitionId" || field === "") && competitionQuestion.competitionId === '') {
      hasErrors = true;
      setCompetitionIdError(true);
      setCompetitionIdHelperText("Competition is required");
    }
    if ((field === "questionId" || field === "") && competitionQuestion.questionId === '') {
      hasErrors = true;
      setQuestionIdError(true);
      setQuestionIdHelperText("Question is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Create Competition Question"></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="competitionIdLabel">Competition</InputLabel>
            <Select
              labelId="competitionIdLabel"
              error={competitionIdError}
              id="competitionId"
              name="competitionId"
              value={competitionQuestion.competitionId}
              label="Competition"
              onChange={handleFormChange}
            >
              {competitions && competitions.map((competition) => (
                <MenuItem value={competition.id} key={competition.id}>{competition.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{competitionIdHelperText}</FormHelperText>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="questionIdLabel">Question</InputLabel>
            <Select
              labelId="questionIdLabel"
              error={questionIdError}
              id="questionId"
              name="questionId"
              value={competitionQuestion.questionId}
              label="Question"
              onChange={handleFormChange}
            >
              {questions && questions.map((question) => (
                <MenuItem value={question.id} key={question.id}>{question.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{questionIdHelperText}</FormHelperText>
          </FormControl>
          <SubmitButton loading={loading}></SubmitButton>
          <AddBar cancelTo="/manage-competition-questions"></AddBar>
        </Box>
      </Grid>
    </Grid>
  );
}