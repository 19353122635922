import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';

export const UpdateSport = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [sport, setSport] = useState({
    name: "",
    matchDuration: ""
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [matchDurationError, setMatchDurationError] = useState(false);
  const [matchDurationHelperText, setMatchDurationHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setSport(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update sport');

    console.log('Getting sport: ' + id);
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setSport(prevState => ({
          ...prevState,
          name: PrepProp(response.data.name),
          matchDuration: PrepProp(response.data.matchDuration),
        }));

        setPageLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteSport = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/manage-sports');
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports/' + id, {
      ...sport,
      matchDuration: parseInt(sport.matchDuration),
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Sport&action=updated&name=' + sport.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setMatchDurationError(false);

    if ((field === "name" || field === "") && sport.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "matchDuration" || field === "") && sport.matchDuration === '') {
      hasErrors = true;
      setMatchDurationError(true);
      setMatchDurationHelperText("Match Duration is required");
    }

    return !hasErrors;
  }

  return (
      <Grid container>
        <TitleBar title="Update Sport" pageLoading={pageLoading}></TitleBar>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          {!pageLoading && (
            <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
              <TextField
                margin="normal"
                error={nameError}
                helperText={nameHelperText}
                name="name"
                required
                fullWidth
                id="name"
                label="Name"
                value={sport.name}
                onChange={handleFormChange}
                onBlur={() => validateForm("name")}
              />
              <TextField
                margin="normal"
                error={matchDurationError}
                helperText={matchDurationHelperText}
                name="matchDuration"
                required
                fullWidth
                id="matchDuration"
                label="Match Duration"
                value={sport.matchDuration}
                onChange={handleFormChange}
                onBlur={() => validateForm("matchDuration")}
              />
              <SubmitButton loading={loading}></SubmitButton>
              <UpdateBar handleDelete={deleteSport} loadingDelete={loadingDelete} cancelTo="/manage-sports"></UpdateBar>
            </Box>
          )}
        </Grid>
      </Grid>
  );
}