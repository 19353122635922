import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { LoginContext } from '../../contexts/LoginContext';

export const UpdateClub = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  let { id } = useParams();
  const [club, setClub] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    town: "",
    postalCode: "",
    county: "",
    country: "",
    logoUrl: "",
    defaultSportId: "",
    email: "",
    phoneNumber: "",
    ballColour: "",
    rateCardId: ""
  });
  const [sports, setSports] = useState(null);
  const [rateCards, setRateCards] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [addressLine1Error, setAddressLine1Error] = useState(false);
  const [addressLine1HelperText, setAddressLine1HelperText] = useState("");
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [postalCodeHelperText, setPostalCodeHelperText] = useState("");
  const [townError, setTownError] = useState(false);
  const [townHelperText, setTownHelperText] = useState("");
  const [countyError, setCountyError] = useState(false);
  const [countyHelperText, setCountyHelperText] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [countryHelperText, setCountryHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  var doneDirect = "/manage-clubs";
  if(userSub.role === "clubadmin"){
    doneDirect = "/club-admin-home"
  }

  const handleFormChange = e => {
    const { name, value } = e.target;
    setClub(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update club');

    console.log('Getting sports');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setSports(response.data);

        console.log('Getting rate cards');
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/rate-cards', {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response2) {
            setRateCards(response2.data)
            console.log('Getting club: ' + id);
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs/' + id, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response) {
                setClub(prevState => ({
                  ...prevState,
                  name: PrepProp(response.data.name),
                  addressLine1: PrepProp(response.data.addressLine1),
                  addressLine2: PrepProp(response.data.addressLine2),
                  town: PrepProp(response.data.town),
                  postalCode: PrepProp(response.data.postalCode),
                  county: PrepProp(response.data.county),
                  country: PrepProp(response.data.country),
                  logoUrl: PrepProp(response.data.logoUrl),
                  defaultSportId: PrepProp(response.data.defaultSportId),
                  rateCardId: PrepProp(response.data.rateCardId),
                  phoneNumber: PrepProp(response.data.phoneNumber),
                  email: PrepProp(response.data.email),
                  ballColour: PrepProp(response.data.ballColour)
                }));

                setPageLoading(false);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteClub = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/complete?entity=Club&action=deleted&name=' + club.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs/' + id, club, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Club&action=updated&name=' + club.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setAddressLine1Error(false);
    setPostalCodeError(false);
    setTownError(false);
    setCountyError(false);
    setCountryError(false);

    if ((field === "name" || field === "") && club.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "addressLine1" || field === "") && club.addressLine1 === '') {
      hasErrors = true;
      setAddressLine1Error(true);
      setAddressLine1HelperText("Address Line 1 is required");
    }
    if ((field === "town" || field === "") && club.town === '') {
      hasErrors = true;
      setTownError(true);
      setTownHelperText("Address Line Town is required");
    }
    if ((field === "postalCode" || field === "") && club.postalCode === '') {
      hasErrors = true;
      setPostalCodeError(true);
      setPostalCodeHelperText("Postal Code is required");
    }
    if ((field === "county" || field === "") && club.county === '') {
      hasErrors = true;
      setCountyError(true);
      setCountyHelperText("County is required");
    }
    if ((field === "country" || field === "") && club.country === '') {
      hasErrors = true;
      setCountryError(true);
      setCountryHelperText("Country is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title={userSub.role === "clubadmin" ? userSub.adminClubName + " Profile" : "Update Club"} pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  error={nameError}
                  helperText={nameHelperText}
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  value={club.name}
                  onChange={handleFormChange}
                  autoFocus
                />
                <TextField
                  margin="normal"
                  error={addressLine1Error}
                  helperText={addressLine1HelperText}
                  name="addressLine1"
                  required
                  fullWidth
                  id="addressLine1"
                  label="Address Line 1"
                  value={club.addressLine1}
                  onChange={handleFormChange}
                />
                <TextField
                  margin="normal"
                  name="addressLine2"
                  fullWidth
                  id="addressLine2"
                  label="Address Line 2"
                  value={club.addressLine2}
                  onChange={handleFormChange}
                />
                <TextField
                  margin="normal"
                  error={townError}
                  helperText={townHelperText}
                  name="town"
                  required
                  fullWidth
                  id="town"
                  label="Town"
                  value={club.town}
                  onChange={handleFormChange}
                />
                <TextField
                  margin="normal"
                  error={postalCodeError}
                  helperText={postalCodeHelperText}
                  name="postalCode"
                  required
                  fullWidth
                  id="postalCode"
                  label="Postal Code"
                  value={club.postalCode}
                  onChange={handleFormChange}
                />
                <TextField
                  margin="normal"
                  error={countyError}
                  helperText={countyHelperText}
                  name="county"
                  required
                  fullWidth
                  id="county"
                  label="County"
                  value={club.county}
                  onChange={handleFormChange}
                />
                <TextField
                  margin="normal"
                  error={countryError}
                  helperText={countryHelperText}
                  name="country"
                  required
                  fullWidth
                  id="country"
                  label="Country"
                  value={club.country}
                  onChange={handleFormChange}
                />
                <TextField
                  margin="normal"
                  name="logoUrl"
                  fullWidth
                  id="logoUrl"
                  label="Logo Url"
                  value={club.logoUrl}
                  onChange={handleFormChange}
                />
                <TextField
                  margin="normal"
                  name="ballColour"
                  fullWidth
                  id="ballColour"
                  label="Ball Colour"
                  value={club.ballColour}
                  onChange={handleFormChange}
                />
                <TextField
                  margin="normal"
                  name="phoneNumber"
                  fullWidth
                  id="phoneNumber"
                  label="Phone Number"
                  value={club.phoneNumber}
                  onChange={handleFormChange}
                />
                <TextField
                  margin="normal"
                  name="email"
                  fullWidth
                  id="email"
                  label="Email"
                  value={club.email}
                  onChange={handleFormChange}
                />
                <FormControl margin="normal" fullWidth>
                  <InputLabel id="defaultSportIdLabel">Default Sport</InputLabel>
                  <Select
                    labelId="defaultSportIdLabel"
                    id="defaultSportId"
                    name="defaultSportId"
                    value={club.defaultSportId}
                    label="Default Sport"
                    onChange={handleFormChange}
                  >
                    <MenuItem value="0" key="0">Select a Sport</MenuItem>
                    {sports && sports.map((sport) => (
                      <MenuItem value={sport.id} key={sport.id}>{sport.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  <InputLabel id="rateCardIdLabel">Rate Card</InputLabel>
                  <Select
                    labelId="rateCardIdLabel"
                    id="rateCardId"
                    name="rateCardId"
                    value={club.rateCardId}
                    label="Rate Card"
                    onChange={handleFormChange}
                  >
                    <MenuItem value="0" key="0">Select a Rate Card</MenuItem>
                    {rateCards && rateCards.map((rateCard) => (
                      <MenuItem value={rateCard.id} key={rateCard.id}>{rateCard.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deleteClub} hideDelete={userSub.role !== "admin"} loadingDelete={loadingDelete} cancelTo={doneDirect}></UpdateBar>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}