import React, { useState, useEffect, useCallback, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { TitleBar } from '../Shared/TitleBar';
import { Grid, Box, LinearProgress, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import { green } from '@mui/material/colors';
import { red } from '@mui/material/colors';
import { LoginContext } from '../../contexts/LoginContext';

export const ManageQuestions = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  const [questions, setQuestions] = useState(null);

  const getQuestions = useCallback((questions) => {
    console.log('Getting questions');

    if (questions)
      setQuestions(null);

    var sortFilter = "sortBy=name&sortByDirection=asc"
    var filter = userSub.role === "clubadmin" ? "?clubId=" + userSub.adminClubId + "&" + sortFilter : "?" + sortFilter;

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/questions' + filter, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setQuestions(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          navigate('/log-out');
        }
      });
  }, [navigate, userSub]);

  useEffect((questions) => {
    console.log('using effect in questions component');
    getQuestions(questions);
  }, [getQuestions]);

  const handleRefresh = () => {
    getQuestions(questions);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Manage Questions"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">
          <RouterLink to="/create-question">Create Question</RouterLink>
        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {questions && questions.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Question</TableCell>
                    <TableCell>Answers</TableCell>
                    <TableCell>Sport</TableCell>
                    {userSub.adminClubId === null && (
                    <TableCell>Club</TableCell>
                    )}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questions.map((question) => (
                    <TableRow
                      key={question.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <RouterLink to={"/update-question/" + question.id}>{question.name}</RouterLink>
                        {question.extraData && (
                          <div>
                            <div style={{ fontWeight: 'bold', marginTop: "5px", marginBottom: "5px" }}>Answers</div>
                            {JSON.parse(question.extraData).questionAnswers.map((questionAnswer) => (
                              <div key={questionAnswer.questionAnswerId} style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                marginTop: '2px'
                            }}>
                                {questionAnswer.isCorrect && (<CheckCircleIcon fontSize="small" sx={{color: green[500]}} />)}
                                {!questionAnswer.isCorrect && (<CancelIcon fontSize="small" sx={{color: red[500]}} />)}
                                <span style={{marginLeft: '5px'}}>{questionAnswer.name}</span>
                            </div>  
                            ))}
                          </div>
                        )}
                        <div style={{marginTop: '5px'}}>
                          
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {(question.answerCount === null || question.answerCount === 0) && ( 
                          <div className="redText">No Answers</div>
                        )}
                        {question.answerCount > 0 && (
                          <div>{question.answerCount}</div>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {question.sportName}
                      </TableCell>
                      {userSub.adminClubId === null && (
                      <TableCell component="th" scope="row">
                        {question.clubName}
                      </TableCell>
                      )}
                      <TableCell component="th" scope="row">
                      <RouterLink to={"/create-question-answer?questionId=" + question.id}>Add Answer</RouterLink>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!questions && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {questions && questions.length === 0 && (
            <div>
              <h3>No Questions.</h3>
              <div>Try Refresh or use the "Create Question" link above to create one.</div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}