import React, { useState, useEffect, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TitleBar } from '../Shared/TitleBar';
import { Grid, Box, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LinearProgress } from '@mui/material';
import Moment from 'react-moment';

export const ManageUsers = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);

  const getUsers = useCallback((users) => {
    console.log('Getting users');
    
    if(users)
      setUsers(null);

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/users', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setUsers(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status === 401) {
          navigate('/log-out');
        }
      });
  }, [navigate]);

  useEffect((users) => {
    console.log('using effect in users component');
    getUsers(users);
  }, [getUsers]);

  const handleRefresh = () => {
    getUsers(users);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Manage Users"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">
          
        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {users && users.length > 0 && (
          <TableContainer component={Paper} sx={{mt: 2}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>User Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Date Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow
                    key={user.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <RouterLink to={"/update-user/" + user.id}>{user.userName}</RouterLink>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {user.email}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {user.firstName} {user.surname}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {user.role}
                      {user.adminClubId && (
                        <div>{user.adminClubName}</div>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Moment format="YYYY/MM/DD">
                        {user.dateCreated}
                      </Moment>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )}
          {!users && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress></LinearProgress>
            </Box>
          )}
          {users && users.length === 0 && (
            <div>
              <h3>No Users.</h3>
              <div>Try Refresh or use the "Create User" link above to create one.</div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}