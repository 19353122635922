import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Grid, Box, LinearProgress, Card, CardContent, Typography, Rating as RateStar, Button, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { LoginContext } from '../../contexts/LoginContext';
import _ from "lodash";

export const Rating = () => {
  const navigate = useNavigate();
  const { userSub, wallet, setWallet } = useContext(LoginContext);
  const [matchPlayerRatingsCombinedList, setMatchPlayerRatingsCombinedList] = useState(null);
  const [entry, setEntry] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  var enabledStyle = { background: '#fdb611', color: '#000000' };
  var disabledStyle = { background: '#e2e2e2', color: '#ffffff' };

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  var entryId = "";
  if (query.get("entryId") != null) {
    entryId = query.get("entryId");
  }
  var matchId = "";
  if (query.get("matchId") != null) {
    matchId = query.get("matchId");
  }

  const handleRate = (playerId, rating, entryRateId, matchPlayerId) => {

    if (!rating && entryRateId) {
      axios.delete(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entry-rates/${entryRateId}`, {
        headers: {
          'Authorization': localStorage.getItem('jwtToken')
        }
      })
        .then(function () {
          console.log('Rating deleted');
          updateEntryRate(matchPlayerId, null);
        })
        .catch(function (error) {
          console.log(error);
        });
      return;
    }

    if (!entryRateId) {
      axios.post(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entry-rates`, {
        entryId: entryId,
        playerId: playerId,
        rating: rating
      }, {
        headers: {
          'Authorization': localStorage.getItem('jwtToken')
        }
      })
        .then(function (response) {
          console.log('New rate submitted');
          var newEntryRateId = response.data.id;
          updateEntryRate(matchPlayerId, newEntryRateId);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios.put(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entry-rates/${entryRateId}`, {
        entryId: entryId,
        playerId: playerId,
        rating: rating
      }, {
        headers: {
          'Authorization': localStorage.getItem('jwtToken')
        }
      })
        .then(function () {
          console.log('Rate change submitted');
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const updateEntryRate = (matchPlayerId, newEntryRateId) => {
    var localMatchPlayerRatingsCombinedList = _.clone(matchPlayerRatingsCombinedList);
    _.forEach(localMatchPlayerRatingsCombinedList, (localMatchPlayerRating) => {
      if (localMatchPlayerRating.id === matchPlayerId) {
        localMatchPlayerRating.entryRateId = newEntryRateId;
      }
    });
    setMatchPlayerRatingsCombinedList(localMatchPlayerRatingsCombinedList);
  }

  const handleSubmitRatings = () => {
    setSubmitting(true);
    axios.put(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries/${entryId}`, {
      rated: true
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function () {

        var slug = "submitted-ratings"
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/honey-points?slug=' + slug, {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response3) {
            var localHoneyPoint = response3.data[0];
            axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/honey-transactions', {
              userId: userSub.id,
              walletId: wallet.id,
              entryId: entryId,
              isDebit: false,
              honeyPointSlug: slug
            }, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response4) {
                var newWallet = {
                  id: wallet.id,
                  balance: wallet.balance,
                  expectedBalance: wallet.balance,
                  honeyPointsBalance: wallet.honeyPointsBalance,
                  expectedHoneyPointsBalance: wallet.honeyPointsBalance + localHoneyPoint.points,
                };
                setWallet(newWallet);
                localStorage.setItem("wallet", JSON.stringify(newWallet));
                setSubmitting(false);
                navigate(`/completed?entryId=${entryId}`);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const getMatchPlayers = useCallback((matchPlayerRatingsCombinedList) => {
    console.log('Getting match players');

    if (matchPlayerRatingsCombinedList)
      setMatchPlayerRatingsCombinedList(null);

    axios.get(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/match-players?matchId=${matchId}`, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        var localMatchPlayers = response.data;
        axios.get(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entry-rates?entryId=${entryId}`, {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response2) {
            var localEntryRates = response2.data;
            var localCombinedList = [];
            _.forEach(localMatchPlayers, (localMatchPlayer) => {
              var localCombined = {
                id: localMatchPlayer.id,
                playerId: localMatchPlayer.playerId,
                number: localMatchPlayer.number,
                playerName: localMatchPlayer.playerName,
                positionName: localMatchPlayer.positionName,
                photoUrl: localMatchPlayer.photoUrl,
                entryRateId: null
              }
              var entryRate = _.find(localEntryRates, (o) => {
                return o.playerId === localMatchPlayer.playerId;
              });
              if (entryRate) {
                localCombined.rating = entryRate.rating;
                localCombined.entryRateId = entryRate.id;
              }
              localCombinedList.push(localCombined);
            });

            setMatchPlayerRatingsCombinedList(localCombinedList);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [matchId, entryId]);

  useEffect((matchPlayerRatingsCombinedList) => {
    console.log('using effect in voting');

    getMatchPlayers(matchPlayerRatingsCombinedList);

    axios.get(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries/${entryId}`, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setEntry(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [entryId, getMatchPlayers]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Rate Match Players"></TitleBar>
          {entry && (
            <h3>{entry.competitionName}</h3>
          )}
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          {matchPlayerRatingsCombinedList && matchPlayerRatingsCombinedList.length > 0 && (
            <Grid container spacing={2}>
              {matchPlayerRatingsCombinedList.map((matchPlayer) => (
                <Grid item xs={12} sm={6} key={matchPlayer.id}>
                  <Card sx={{ minWidth: 275 }} key={matchPlayer.id}>
                    <CardContent>
                      <Typography variant="h5" component="div">
                        <Grid container>
                          <Grid item xs={4} sx={{ textAlign: 'center' }}>
                            <img src={matchPlayer.photoUrl} alt={matchPlayer.playerName} style={{ height: '40px' }}></img>
                          </Grid>
                          <Grid item xs={8} sx={{ textAlign: 'center' }}>
                            <div className="rateBox">
                              <div className="rateName">
                                <div>{matchPlayer.number} - {matchPlayer.playerName}</div>
                                <div>{matchPlayer.positionName}</div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} justifyContent="center">
                            <Grid container justifyContent="center">
                              <Grid item>
                                <div className="rateStars">
                                  <RateStar
                                    name="size-large"
                                    defaultValue={matchPlayer.rating}
                                    size="large"
                                    onChange={(event, newValue) => {
                                      handleRate(matchPlayer.playerId, newValue, matchPlayer.entryRateId, matchPlayer.id);
                                    }}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
          {!matchPlayerRatingsCombinedList && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {matchPlayerRatingsCombinedList && matchPlayerRatingsCombinedList.length === 0 && (
            <div>
              <h3>No Match Players.</h3>
            </div>
          )}
        </Grid>
        {matchPlayerRatingsCombinedList && (
          <Grid item xs={12} sx={{ mt: 4 }}>
            <Box sx={{ position: 'relative', mt: 3 }}>
              <Button
                onClick={handleSubmitRatings}
                fullWidth
                variant="contained"
                disabled={submitting}
                style={submitting ? disabledStyle : enabledStyle}
              >
                Submit Ratings
              </Button>
              {submitting && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#495563',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  );
}