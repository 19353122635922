import React, { useState, useEffect, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TitleBar } from '../Shared/TitleBar';
import { Grid, Box, LinearProgress, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import Moment from 'react-moment';

export const ManageTeamPlayers = () => {
  const navigate = useNavigate();
  const [teamPlayers, setTeamPlayers] = useState(null);

  const getTeamPlayers = useCallback((teamPlayers) => {
    console.log('Getting teamPlayers');
    
    if(teamPlayers)
      setTeamPlayers(null);

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/team-players', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setTeamPlayers(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status === 401) {
          navigate('/log-out');
        }
      });
  }, [navigate]);

  useEffect((teamPlayers) => {
    console.log('using effect in users component');
    getTeamPlayers(teamPlayers);
  }, [getTeamPlayers]);

  const handleRefresh = () => {
    getTeamPlayers(teamPlayers);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Manage Team Players"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">
          <RouterLink to="/create-team-player">Create Team Player</RouterLink>
        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {teamPlayers && teamPlayers.length > 0 && (
          <TableContainer component={Paper} sx={{mt: 2}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Team Name</TableCell>
                  <TableCell>Player Name</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Date Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamPlayers.map((teamPlayer) => (
                  <TableRow
                    key={teamPlayer.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <RouterLink to={"/update-team-player/" + teamPlayer.id}>{teamPlayer.teamName}</RouterLink>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {teamPlayer.playerName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {teamPlayer.createdBy}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Moment format="YYYY/MM/DD">
                        {teamPlayer.dateCreated}
                      </Moment>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )}
          {!teamPlayers && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {teamPlayers && teamPlayers.length === 0 && (
            <div>
              <h3>No Team Players.</h3>
              <div>Try Refresh or use the "Create Team Player" link above to create one.</div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}