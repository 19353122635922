import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export const DoneBar = (props) => {
  return (
    <Grid container sx={{ mt: 3 }}>
      <Grid item xs={6}>
        <Box sx={{ position: 'relative' }}>
          
        </Box>
      </Grid>
      <Grid item xs={6} align="right">
        <Button component={RouterLink} variant="text" to={props.doneTo}>Done</Button>
      </Grid>
    </Grid>
  );
}