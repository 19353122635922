import React, { useContext, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Grid, Button, Box, LinearProgress } from '@mui/material';
import { TitleBar } from '../Shared/TitleBar';
import axios from 'axios';
import { LoginContext } from '../../contexts/LoginContext';

export const PaymentStub = () => {
  const navigate = useNavigate();
  const { wallet, setWallet } = useContext(LoginContext);
  const [loading, setLoading] = useState(false);
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  var amount = 0;
  if (query.get("amount") != null) {
    amount = parseInt(query.get("amount"));
  }

  const addWalletTransaction = () => {
    setLoading(true);
    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallet-transactions', {
      walletId: wallet.id,
      isDebit: false,
      amount: parseInt(amount)
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setTimeout(() => {
          setLoading(false);

          var newWallet = {
            id: wallet.id,
            balance: wallet.balance,
            expectedBalance: wallet.balance + parseInt(amount),
            honeyPointsBalance: wallet.honeyPointsBalance,
            expectedHoneyPointsBalance: wallet.honeyPointsBalance,
          };
          setWallet(newWallet);
          localStorage.setItem("wallet", JSON.stringify(newWallet));
          navigate(`/payment-result?result=approved&expectedBalance=${wallet.balance + amount}`);
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Processing Payment"></TitleBar>
      </Grid>
      <Grid item xs={12}>
        {loading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        {!loading && (
          <>
            <h3>This is a payment simulation. No real money involved.</h3>
            <div>Your balance will be credited with <span className="compDynamicWord">{amount}</span> <span className="compDynamicWord">{amount === 1 ? "credit" : "credits"}</span></div>
            <Grid container spacing={2} sx={{mt: 4}}>
              <Grid item><Button
                variant="contained"
                onClick={addWalletTransaction}
                color="success"
              >
                Simulate Payment Approved
              </Button></Grid>
              <Grid item><Button
                component={RouterLink}
                variant="contained"
                color="error"
                to={`/payment-result?result=declined`}
              >
                Simulate Payment Declined
              </Button></Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}