import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { LoginContext } from '../../contexts/LoginContext';

export const UpdateHoneyPoint = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  let { id } = useParams();
  const [honeyPoint, setHoneyPoint] = useState({
    name: "",
    slug: "",
    points: "0"
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [slugError, setSlugError] = useState(false);
  const [slugHelperText, setSlugHelperText] = useState("");
  const [pointsError, setPointsError] = useState(false);
  const [pointsHelperText, setPointsHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setHoneyPoint(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update honeyPoint');

    console.log('Getting honeyPoint: ' + id);
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/honey-points/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setHoneyPoint(prevState => ({
          ...prevState,
          name: PrepProp(response.data.name),
          slug: PrepProp(response.data.slug),
          points: PrepProp(response.data.points)
        }));

        setPageLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteHoneyPoint = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/honey-points/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/complete?entity=Honey Point&action=deleted&name=' + honeyPoint.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/honey-points/' + id, {
      ...honeyPoint,
      points: parseInt(honeyPoint.points)
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Honey Point&action=updated&name=' + honeyPoint.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setSlugError(false);
    setPointsError(false);

    if ((field === "name" || field === "") && honeyPoint.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "slug" || field === "") && honeyPoint.slug === '') {
      hasErrors = true;
      setSlugError(true);
      setSlugHelperText("Slug is required");
    }
    if ((field === "points" || field === "") && honeyPoint.points === '') {
      hasErrors = true;
      setPointsError(true);
      setPointsHelperText("Points is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title={userSub.role === "honeyPointadmin" ? userSub.adminHoneyPointName + " Profile" : "Update HoneyPoint"} pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  error={nameError}
                  helperText={nameHelperText}
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  value={honeyPoint.name}
                  onChange={handleFormChange}
                  onBlur={() => validateForm("name")}
                  autoFocus
                />
                <TextField
                  margin="normal"
                  error={slugError}
                  helperText={slugHelperText}
                  name="slug"
                  required
                  fullWidth
                  id="slug"
                  label="Slug"
                  value={honeyPoint.slug}
                  onChange={handleFormChange}
                  onBlur={() => validateForm("slug")}
                />
                <TextField
                  margin="normal"
                  id="points"
                  name="points"
                  required
                  error={pointsError}
                  helperText={pointsHelperText}
                  fullWidth
                  label="Points"
                  value={honeyPoint.points}
                  onChange={handleFormChange}
                />
              </Grid>
            </Grid>
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deleteHoneyPoint} hideDelete={false} loadingDelete={loadingDelete} cancelTo="/manage-honey-points"></UpdateBar>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}