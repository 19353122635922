import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';

export const CreateTeamPlayer = () => {
  const navigate = useNavigate();
  const [teamPlayer, setTeamPlayer] = useState({
    teamId: "",
    playerId: ""
  });
  const [teamIdError, setTeamIdError] = useState(false);
  const [teamIdHelperText, setTeamIdHelperText] = useState("");
  const [playerIdError, setPlayerIdError] = useState(false);
  const [playerIdHelperText, setPlayerIdHelperText] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [teams, setTeams] = useState(null);
  const [players, setPlayers] = useState(null);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setTeamPlayer(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    console.log('using effect in update teamPlayer');

    console.log('Getting teams');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/teams?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setTeams(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    console.log('Getting players');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/players?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setPlayers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/team-players', teamPlayer, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-team-players');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setTeamIdError(false);
    setPlayerIdError(false);

    if ((field === "teamId" || field === "") && teamPlayer.teamId === '') {
      hasErrors = true;
      setTeamIdError(true);
      setTeamIdHelperText("Team is required");
    }
    if ((field === "playerId" || field === "") && teamPlayer.playerId === '') {
      hasErrors = true;
      setPlayerIdError(true);
      setPlayerIdHelperText("Player is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Create Team Player"></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="teamIdLabel">Team</InputLabel>
            <Select
              labelId="teamIdLabel"
              error={teamIdError}
              id="teamId"
              name="teamId"
              value={teamPlayer.teamId}
              label="Team"
              onChange={handleFormChange}
            >
              {teams && teams.map((team) => (
                <MenuItem value={team.id} key={team.id}>{team.clubName} ({team.name})</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{teamIdHelperText}</FormHelperText>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="playerIdLabel">Player</InputLabel>
            <Select
              labelId="playerIdLabel"
              error={playerIdError}
              id="playerId"
              name="playerId"
              value={teamPlayer.playerId}
              label="Player"
              onChange={handleFormChange}
            >
              {players && players.map((player) => (
                <MenuItem value={player.id} key={player.id}>{player.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{playerIdHelperText}</FormHelperText>
          </FormControl>
          <SubmitButton loading={loading}></SubmitButton>
          <AddBar cancelTo="/manage-team-players"></AddBar>
        </Box>
      </Grid>
    </Grid>
  );
}