import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import moment from 'moment';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';

export const CreateMatchPlayer = () => {
  const navigate = useNavigate();
  const [matchPlayer, setMatchPlayer] = useState({
    matchId: "",
    playerId: ""
  });
  const [matchIdError, setMatchIdError] = useState(false);
  const [matchIdHelperText, setMatchIdHelperText] = useState("");
  const [playerIdError, setPlayerIdError] = useState(false);
  const [playerIdHelperText, setPlayerIdHelperText] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [matches, setMatches] = useState(null);
  const [players, setPlayers] = useState(null);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setMatchPlayer(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    console.log('using effect in update matchPlayer');

    console.log('Getting matches');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/matches?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setMatches(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    console.log('Getting players');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/players?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setPlayers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/match-players', matchPlayer, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-match-players');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setMatchIdError(false);
    setPlayerIdError(false);

    if ((field === "matchId" || field === "") && matchPlayer.matchId === '') {
      hasErrors = true;
      setMatchIdError(true);
      setMatchIdHelperText("Match is required");
    }
    if ((field === "playerId" || field === "") && matchPlayer.playerId === '') {
      hasErrors = true;
      setPlayerIdError(true);
      setPlayerIdHelperText("Player is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Create Match Player"></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="matchIdLabel">Match</InputLabel>
            <Select
              labelId="matchIdLabel"
              error={matchIdError}
              id="matchId"
              name="matchId"
              value={matchPlayer.matchId}
              label="Match"
              onChange={handleFormChange}
            >
              {matches && matches.map((match) => (
                <MenuItem value={match.id} key={match.id}>{moment(match.startDate).format('yyyy/MM/DD HH:mm')} - {match.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{matchIdHelperText}</FormHelperText>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="playerIdLabel">Player</InputLabel>
            <Select
              labelId="playerIdLabel"
              error={playerIdError}
              id="playerId"
              name="playerId"
              value={matchPlayer.playerId}
              label="Player"
              onChange={handleFormChange}
            >
              {players && players.map((player) => (
                <MenuItem value={player.id} key={player.id}>{player.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{playerIdHelperText}</FormHelperText>
          </FormControl>
          <SubmitButton loading={loading}></SubmitButton>
          <AddBar cancelTo="/manage-match-players"></AddBar>
        </Box>
      </Grid>
    </Grid>
  );
}