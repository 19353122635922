import React, { useEffect } from 'react';
import {Grid} from '@mui/material';
import { TitleBar } from '../Shared/TitleBar';
import { EntryTasks } from './EntryTasks';

export const Entered = () => {

  useEffect(() => {
    console.log('using effect in entered');

  }, []);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title={`Congratulations`}></TitleBar>
          <h2>Step 1 complete.</h2>
        </Grid>
        <Grid item xs={12}>
          <p>
          After the match, Fanhive will notify you to complete Step 2 by selecting the Nantwich FC Fans’ Player of the Match and to optionally rate the Nantwich FC players.
          </p>
          <p>
          The more you participate, the more Fanhive honey points you earn.
          </p>
          <EntryTasks 
            hideAnswered={true} 
            hideVoted={false} 
            hideRated={false}
            showCompetitionName={false}
            infoText="Next steps"
          ></EntryTasks>
        </Grid>
      </Grid>
    </div >
  );
}