import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export const UpdateEntryQuestionAnswer = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [entryQuestionAnswer, setEntryQuestionAnswer] = useState({
    entryId: "",
    questionAnswerId: ""
  });
  const [entryIdError, setEntryIdError] = useState(false);
  const [entryIdHelperText, setEntryIdHelperText] = useState("");
  const [questionAnswerIdError, setQuestionAnswerIdError] = useState(false);
  const [questionAnswerIdHelperText, setQuestionAnswerIdHelperText] = useState("");
  const [entries, setEntries] = useState(null);
  const [questionAnswers, setQuestionAnswers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setEntryQuestionAnswer(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update entryQuestionAnswer');

    console.log('Getting entries');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {

        setEntries(response.data);

        console.log('Getting questionanswers');
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/question-answers?sortBy=name&sortByDirection=asc', {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {
            setQuestionAnswers(response.data);

            console.log('Getting entry question answer: ' + id);
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entry-question-answers/' + id, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response) {
                setEntryQuestionAnswer(prevState => ({
                  ...prevState,
                  entryId: PrepProp(response.data.entryId),
                  questionAnswerId: PrepProp(response.data.questionAnswerId)
                }));

                setPageLoading(false);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });

      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteEntryQuestionAnswer = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entry-question-answers/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/manage-entry-question-answers');
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entry-question-answers/' + id, entryQuestionAnswer, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-entry-question-answers');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setEntryIdError(false);
    setQuestionAnswerIdError(false);

    if ((field === "entryId" || field === "") && entryQuestionAnswer.entryId === '') {
      hasErrors = true;
      setEntryIdError(true);
      setEntryIdHelperText("Entry is required");
    }
    if ((field === "questionAnswerId" || field === "") && entryQuestionAnswer.questionAnswerId === '') {
      hasErrors = true;
      setQuestionAnswerIdError(true);
      setQuestionAnswerIdHelperText("Question Answer is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title="Update Entry Question Answer" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="entryIdLabel">Entry</InputLabel>
              <Select
                labelId="entryIdLabel"
                error={entryIdError}
                id="entryId"
                name="entryId"
                value={entryQuestionAnswer.entryId}
                label="Entry"
                onChange={handleFormChange}
              >
                {entries && entries.map((entry) => (
                  <MenuItem value={entry.id} key={entry.id}>{entry.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{entryIdHelperText}</FormHelperText>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="questionAnswerIdLabel">Question Answer</InputLabel>
              <Select
                labelId="questionAnswerIdLabel"
                error={questionAnswerIdError}
                id="questionAnswerId"
                name="questionAnswerId"
                value={entryQuestionAnswer.questionAnswerId}
                label="Question Answer"
                onChange={handleFormChange}
              >
                {questionAnswers && questionAnswers.map((questionAnswer) => (
                  <MenuItem value={questionAnswer.id} key={questionAnswer.id}>{questionAnswer.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{questionAnswerIdHelperText}</FormHelperText>
            </FormControl>
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deleteEntryQuestionAnswer} loadingDelete={loadingDelete} cancelTo="/manage-entry-question-answers"></UpdateBar>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}