import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';

export const CreatePosition = () => {
  const navigate = useNavigate();
  const [position, setPosition] = useState({
    name: "",
    sportId: "",
    number: ""
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [sportIdError, setSportIdError] = useState(false);
  const [sportIdHelperText, setSportIdHelperText] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [sports, setSports] = useState(null);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setPosition(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    console.log('using effect in update position');

    console.log('Getting sports');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setSports(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/positions', {
      name: position.name,
      sportId: position.sportId,
      number: position.number === "" ? null : parseInt(position.number)
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Position&action=created&name=' + position.name + '&id=' + response.data.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setSportIdError(false);

    if ((field === "name" || field === "") && position.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "sportId" || field === "") && position.sportId === '') {
      hasErrors = true;
      setSportIdError(true);
      setSportIdHelperText("Sport is required");
    }

    return !hasErrors;
  }

  return (
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Create Position"></TitleBar>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="sportIdLabel">Sport</InputLabel>
              <Select
                labelId="sportIdLabel"
                error={sportIdError}
                id="sportId"
                name="sportId"
                value={position.sportId}
                label="Sport"
                onChange={handleFormChange}
              >
                {sports && sports.map((sport) => (
                  <MenuItem value={sport.id} key={sport.id}>{sport.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{sportIdHelperText}</FormHelperText>
            </FormControl>
            <TextField
              margin="normal"
              error={nameError}
              helperText={nameHelperText}
              name="name"
              required
              fullWidth
              id="name"
              label="Name"
              value={position.name}
              onChange={handleFormChange}
              onBlur={() => validateForm("name")}
            />
            <TextField
              margin="normal"
              name="number"
              fullWidth
              id="number"
              label="Number"
              value={position.number}
              onChange={handleFormChange}
            />
            <SubmitButton loading={loading}></SubmitButton>
            <AddBar cancelTo="/manage-positions"></AddBar>
          </Box>
        </Grid>
      </Grid>
  );
}