import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

export const UpdateBar = (props) => {
  return (
    <Grid container sx={{ mt: 3 }}>
      <Grid item xs={6}>
        <Box sx={{ position: 'relative' }}>
          {!props.hideDelete && (
          <Button
            type="button"
            onClick={props.handleDelete}
            variant="text"
            color="error"
            disabled={props.loadingDelete}
          >
            Delete
          </Button>
          )}
          {props.loadingDelete && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '12%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={6} align="right">
        <Button component={RouterLink} variant="text" to={props.cancelTo}>Cancel</Button>
      </Grid>
    </Grid>
  );
}