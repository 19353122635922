import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';

export const CreateWalletTransaction = () => {
  const navigate = useNavigate();
  const [walletTransaction, setWalletTransaction] = useState({
    walletId: "",
    isDebit: true,
    amount: ""
  });
  const [walletIdError, setWalletIdError] = useState(false);
  const [walletIdHelperText, setWalletIdHelperText] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [amountHelperText, setAmountHelperText] = useState("");
  const [wallets, setWallets] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = e => {
    const { name, value } = e.target;
    if(name === 'isDebit'){
      setWalletTransaction(prevState => ({
        ...prevState,
        isDebit: e.target.checked
      }));
    } else {
      setWalletTransaction(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  useEffect(() => {
    console.log('using effect in update walletTransaction');

    console.log('Getting wallets');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallets?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setWallets(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallet-transactions', {
      walletId: walletTransaction.walletId,
      isDebit: walletTransaction.isDebit,
      amount: parseInt(walletTransaction.amount)
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-wallet-transactions');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setWalletIdError(false);
    setAmountError(false);

    if ((field === "walletId" || field === "") && walletTransaction.walletId === '') {
      hasErrors = true;
      setWalletIdError(true);
      setWalletIdHelperText("Wallet is required");
    }
    if ((field === "amount" || field === "") && walletTransaction.amount <= 0) {
      hasErrors = true;
      setAmountError(true);
      setAmountHelperText("Amount is required");
    }
    if ((field === "amount" || field === "") && (walletTransaction.amount !== '' && isNaN(walletTransaction.amount))) {
      hasErrors = true;
      setAmountError(true);
      setAmountHelperText("Amount must be a number");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Create Wallet Transaction"></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="walletIdLabel">Wallet</InputLabel>
            <Select
              labelId="walletIdLabel"
              error={walletIdError}
              id="walletId"
              name="walletId"
              value={walletTransaction.walletId}
              label="Wallet"
              onChange={handleFormChange}
            >
              {wallets && wallets.map((wallet) => (
                <MenuItem value={wallet.id} key={wallet.id}>{wallet.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{walletIdHelperText}</FormHelperText>
          </FormControl>
          <TextField
            margin="normal"
            id="amount"
            name="amount"
            required
            error={amountError}
            helperText={amountHelperText}
            fullWidth
            label="Amount"
            value={walletTransaction.amount}
            onChange={handleFormChange}
          />
          <FormGroup>
            <FormControlLabel control={<Switch id="isDebit" name="isDebit" checked={walletTransaction.isDebit} onChange={handleFormChange} />} label="Is Debit" />
          </FormGroup>
          <SubmitButton loading={loading}></SubmitButton>
          <AddBar cancelTo="/manage-wallet-transactions"></AddBar>
        </Box>
      </Grid>
    </Grid>
  );
}