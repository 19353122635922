import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export const UpdateWallet = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [wallet, setWallet] = useState({
    name: "",
    userId: "",
    balance: 0
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [userIdError, setUserIdError] = useState(false);
  const [userIdHelperText, setUserIdHelperText] = useState("");
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setWallet(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update wallet');

    console.log('Getting users');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/users?sortBy=user_name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {

        setUsers(response.data);

        console.log('Getting wallet: ' + id);
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallets/' + id, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response) {
                setWallet(prevState => ({
                  ...prevState,
                  name: PrepProp(response.data.name),
                  userId: PrepProp(response.data.userId),
                  balance: parseInt(PrepProp(response.data.balance)),
                  honeyPointsBalance: parseInt(PrepProp(response.data.honeyPointsBalance))
                }));

                setPageLoading(false);
              })
              .catch(function (error) {
                console.log(error);
              });

      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteWallet = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallets/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/manage-wallets');
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallets/' + id, wallet, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-wallets');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setUserIdError(false);

    if ((field === "name" || field === "") && wallet.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "userId" || field === "") && wallet.userId === '') {
      hasErrors = true;
      setUserIdError(true);
      setUserIdHelperText("User is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title="Update Wallet" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <>
          <h2>Credit Balance: {wallet.balance}</h2>
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <TextField
              margin="normal"
              error={nameError}
              helperText={nameHelperText}
              name="name"
              required
              fullWidth
              id="name"
              label="Name"
              value={wallet.name}
              onChange={handleFormChange}
              onBlur={() => validateForm("name")}
            />
            <FormControl margin="normal" fullWidth>
              <InputLabel id="userIdLabel">User</InputLabel>
              <Select
                labelId="userIdLabel"
                error={userIdError}
                id="userId"
                name="userId"
                value={wallet.userId}
                label="User"
                onChange={handleFormChange}
              >
                {users && users.map((user) => (
                  <MenuItem value={user.id} key={user.id}>{user.userName}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{userIdHelperText}</FormHelperText>
            </FormControl>
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deleteWallet} loadingDelete={loadingDelete} cancelTo="/manage-wallets"></UpdateBar>
          </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
}