import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';

export const CreateClub = () => {
  const navigate = useNavigate();
  const [club, setClub] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    town: "",
    postalCode: "",
    county: "",
    country: "",
    logoUrl: "",
    defaultSportId: "",
    email: "",
    phoneNumber: "",
    rateCardId: ""
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [addressLine1Error, setAddressLine1Error] = useState(false);
  const [addressLine1HelperText, setAddressLine1HelperText] = useState("");
  const [townError, setTownError] = useState(false);
  const [townHelperText, setTownHelperText] = useState("");
  const [countyError, setCountyError] = useState(false);
  const [countyHelperText, setCountyHelperText] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [countryHelperText, setCountryHelperText] = useState("");
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [postalCodeHelperText, setPostalCodeHelperText] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [sports, setSports] = useState(null);
  const [rateCards, setRateCards] = useState(null);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setClub(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    console.log('using effect in update club');

    console.log('Getting sports');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setSports(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/rate-cards?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setRateCards(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs', club, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Club&action=created&name=' + club.name + '&id=' + response.data.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setAddressLine1Error(false);
    setTownError(false);
    setPostalCodeError(false);
    setCountyError(false);
    setCountryError(false);

    if ((field === "name" || field === "") && club.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "addressLine1" || field === "") && club.addressLine1 === '') {
      hasErrors = true;
      setAddressLine1Error(true);
      setAddressLine1HelperText("Address Line 1 is required");
    }
    if ((field === "town" || field === "") && club.town === '') {
      hasErrors = true;
      setTownError(true);
      setTownHelperText("Town is required");
    }
    if ((field === "postalCode" || field === "") && club.postalCode === '') {
      hasErrors = true;
      setPostalCodeError(true);
      setPostalCodeHelperText("Postal Code is required");
    }
    if ((field === "country" || field === "") && club.country === '') {
      hasErrors = true;
      setCountryError(true);
      setCountryHelperText("Country is required");
    }
    if ((field === "county" || field === "") && club.county === '') {
      hasErrors = true;
      setCountyError(true);
      setCountyHelperText("County is required");
    }
    if ((field === "country" || field === "") && club.country === '') {
      hasErrors = true;
      setCountryError(true);
      setCountryHelperText("Country is required");
    }

    return !hasErrors;
  }

  return (
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Create Club"></TitleBar>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <TextField
              margin="normal"
              error={nameError}
              helperText={nameHelperText}
              name="name"
              required
              fullWidth
              id="name"
              label="Name"
              value={club.name}
              onChange={handleFormChange}
              onBlur={() => validateForm("name")}
              autoFocus
            />
            <TextField
              margin="normal"
              error={addressLine1Error}
              helperText={addressLine1HelperText}
              name="addressLine1"
              required
              fullWidth
              id="addressLine1"
              label="Address Line 1"
              value={club.addressLine1}
              onChange={handleFormChange}
              onBlur={() => validateForm("addressLine1")}
            />
            <TextField
              margin="normal"
              name="addressLine2"
              fullWidth
              id="addressLine2"
              label="Address Line 2"
              value={club.addressLine2}
              onChange={handleFormChange}
            />
            <TextField
              margin="normal"
              error={townError}
              helperText={townHelperText}
              name="town"
              required
              fullWidth
              id="town"
              label="Town"
              value={club.town}
              onChange={handleFormChange}
              onBlur={() => validateForm("town")}
            />
            <TextField
              margin="normal"
              error={postalCodeError}
              helperText={postalCodeHelperText}
              name="postalCode"
              required
              fullWidth
              id="postalCode"
              label="Postal Code"
              value={club.postalCode}
              onChange={handleFormChange}
              onBlur={() => validateForm("postalCode")}
            />
            <TextField
              margin="normal"
              error={countyError}
              helperText={countyHelperText}
              name="county"
              required
              fullWidth
              id="county"
              label="County"
              value={club.county}
              onChange={handleFormChange}
              onBlur={() => validateForm("county")}
            />
            <TextField
              margin="normal"
              error={countryError}
              helperText={countryHelperText}
              name="country"
              required
              fullWidth
              id="country"
              label="Country"
              value={club.country}
              onChange={handleFormChange}
              onBlur={() => validateForm("country")}
            />
            <TextField
              margin="normal"
              name="logoUrl"
              fullWidth
              id="logoUrl"
              label="Logo Url"
              value={club.logoUrl}
              onChange={handleFormChange}
              onBlur={() => validateForm("logoUrl")}
            />
            <TextField
              margin="normal"
              name="phoneNumber"
              fullWidth
              id="phoneNumber"
              label="Phone Number"
              value={club.phoneNumber}
              onChange={handleFormChange}
            />
            <TextField
              margin="normal"
              name="email"
              fullWidth
              id="email"
              label="Email"
              value={club.email}
              onChange={handleFormChange}
            />
            <FormControl margin="normal" fullWidth>
              <InputLabel id="defaultSportIdLabel">Default Sport</InputLabel>
              <Select
                labelId="defaultSportIdLabel"
                id="defaultSportId"
                name="defaultSportId"
                value={club.defaultSportId}
                label="Default Sport"
                onChange={handleFormChange}
              >
                <MenuItem value="0" key="0">Select a Sport</MenuItem>
                {sports && sports.map((sport) => (
                  <MenuItem value={sport.id} key={sport.id}>{sport.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="rateCardIdLabel">Rate Card</InputLabel>
              <Select
                labelId="rateCardIdLabel"
                id="rateCardId"
                name="rateCardId"
                value={club.rateCardId}
                label="Rate Card"
                onChange={handleFormChange}
              >
                <MenuItem value="0" key="0">Select a Rate Card</MenuItem>
                {rateCards && rateCards.map((rateCard) => (
                  <MenuItem value={rateCard.id} key={rateCard.id}>{rateCard.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <SubmitButton loading={loading}></SubmitButton>
            <AddBar cancelTo="/manage-clubs"></AddBar>
          </Box>
        </Grid>
      </Grid>
  );
}