import React, {useContext} from 'react';
import { Navigate } from "react-router-dom";
import { LoginContext } from '../contexts/LoginContext';

function RequireAuth({ children }) {
  const {isLoggedIn} = useContext(LoginContext);

  return isLoggedIn 
    ? children 
    : <Navigate to="/login" replace />;
}

export default RequireAuth;