import React from 'react'
import { Link as RouterLink } from 'react-router-dom';

import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TodayIcon from '@mui/icons-material/Today';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import SportsIcon from '@mui/icons-material/Sports';
import GroupsIcon from '@mui/icons-material/Groups';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

export const ClubAdminRoleList = (props) => {
  return (
    <List subheader={<ListSubheader>Club Admin</ListSubheader>}>
      <ListItem button component={RouterLink} to={"/update-club/" + props.userSub.adminClubId}>
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText primary="Club Profile" />
      </ListItem>
      <ListItem button component={RouterLink} to="/manage-teams">
        <ListItemIcon>
          <GroupsIcon />
        </ListItemIcon>
        <ListItemText primary="Teams" />
      </ListItem>
      <ListItem button component={RouterLink} to="/manage-players">
        <ListItemIcon>
          <DirectionsRunIcon />
        </ListItemIcon>
        <ListItemText primary="Players" />
      </ListItem>
      <ListItem button component={RouterLink} to="/manage-matches">
        <ListItemIcon>
          <SportsIcon />
        </ListItemIcon>
        <ListItemText primary="Matches" />
      </ListItem>
      <ListItem button component={RouterLink} to="/manage-sponsors">
        <ListItemIcon>
          <LocalAtmIcon />
        </ListItemIcon>
        <ListItemText primary="Sponsors" />
      </ListItem>
      <ListItem button component={RouterLink} to="/manage-competitions">
        <ListItemIcon>
          <TodayIcon />
        </ListItemIcon>
        <ListItemText primary="Competitions" />
      </ListItem>
      <ListItem button component={RouterLink} to="/manage-competition-questions">
        <ListItemIcon>
          <CheckCircleIcon />
        </ListItemIcon>
        <ListItemText primary="Competition Questions" />
      </ListItem>
      <ListItem button component={RouterLink} to="/manage-questions">
        <ListItemIcon>
          <HelpCenterIcon />
        </ListItemIcon>
        <ListItemText primary="Questions" />
      </ListItem>
    </List>
  )
};
