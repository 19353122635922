import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { AddBar } from '../Shared/AddBar';
import { LoginContext } from '../../contexts/LoginContext';
import _ from 'lodash';

export const CreatePlayer = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  const [player, setPlayer] = useState({
    firstName: "",
    surname: "",
    sportId: "",
    positionId: "0",
    number: "",
    positionName: "",
    clubId: userSub.adminClubId === null ? "" : userSub.adminClubId,
    photoUrl: "",
    twitterHandle: "",
    instagramHandle: ""
  });
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameHelperText, setFirstNameHelperText] = useState("");
  const [sportIdError, setSportIdError] = useState(false);
  const [sportIdHelperText, setSportIdHelperText] = useState("");
  const [clubIdError, setClubIdError] = useState(false);
  const [clubIdHelperText, setClubIdHelperText] = useState("");
  const [positions, setPositions] = useState(null);
  const [sports, setSports] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setPlayer(prevState => ({
      ...prevState,
      [name]: value
    }));
    if(name === "sportId"){
      getPositions(value);
    }
    if(name === "positionId") {
      var position = _.find(positions, (o) => {
        return o.id === value;
      });
      if(player.number === ""){
        setPlayer({
          ...player,
          positionId: value,
          number: position.number
        })
      }
    }
  };

  const getPositions = (sportId) => {
    console.log('Getting positions');
    axios.get(`https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/positions?sportId=${sportId}&sortBy=number&sortByDirection=asc`, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setPositions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    console.log('using effect in update player');

    if(userSub.adminClubId === null) {
      console.log('Getting clubs');
      axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs?sortBy=name&sortByDirection=asc', {
        headers: {
          'Authorization': localStorage.getItem('jwtToken')
        }
      })
        .then(function (response) {
          setClubs(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    console.log('Getting sports');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setSports(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [userSub]);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/players', {
      ...player,
      number: player.number === "" ? null : parseInt(player.number),
      positionId: player.positionId === "0" ? null : player.positionId,
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate(`/complete?entity=Player&action=created&name=${player.firstName} ${player.surname}&id=${response.data.id}&specialCase=2`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setFirstNameError(false);
    setSportIdError(false);
    setClubIdError(false);

    if ((field === "clubId" || field === "") && player.clubId === '') {
      hasErrors = true;
      setClubIdError(true);
      setClubIdHelperText("Club is required");
    }

    if ((field === "firstName" || field === "") && player.firstName === '') {
      hasErrors = true;
      setFirstNameError(true);
      setFirstNameHelperText("First Name is required");
    }
    if ((field === "sportId" || field === "") && player.sportId === '') {
      hasErrors = true;
      setSportIdError(true);
      setSportIdHelperText("Sport is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title="Create Player"></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
          {userSub.adminClubId === null && (
            <FormControl margin="normal" fullWidth>
              <InputLabel id="positionIdLabel">Club</InputLabel>
              <Select
                labelId="clubIdLabel"
                error={clubIdError}
                required
                id="clubId"
                name="clubId"
                value={player.clubId}
                label="Position"
                onChange={handleFormChange}
              >
                {clubs && clubs.map((club) => (
                  <MenuItem value={club.id} key={club.id}>{club.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{clubIdHelperText}</FormHelperText>
            </FormControl>
          )}
          <TextField
            margin="normal"
            error={firstNameError}
            helperText={firstNameHelperText}
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First Name"
            value={player.firstName}
            onChange={handleFormChange}
            onBlur={() => validateForm("firstName")}
          />
          <TextField
            margin="normal"
            name="surname"
            fullWidth
            id="surname"
            label="Surname"
            value={player.surname}
            onChange={handleFormChange}
          />
          <FormControl margin="normal" fullWidth>
            <InputLabel id="sportIdLabel">Sport</InputLabel>
            <Select
              labelId="sportIdLabel"
              error={sportIdError}
              required
              id="sportId"
              name="sportId"
              value={player.sportId}
              label="Sport"
              onChange={handleFormChange}
            >
              {sports && sports.map((sport) => (
                <MenuItem value={sport.id} key={sport.id}>{sport.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{sportIdHelperText}</FormHelperText>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="positionIdLabel">Position</InputLabel>
            <Select
              labelId="positionIdLabel"
              id="positionId"
              name="positionId"
              value={player.positionId}
              label="Position"
              onChange={handleFormChange}
            >
              <MenuItem value="0">N/A</MenuItem>
              {positions && positions.map((position) => (
                <MenuItem value={position.id} key={position.id}>{position.number} - {position.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            name="number"
            required
            fullWidth
            id="number"
            label="Number"
            value={player.number}
            onChange={handleFormChange}
          />
          <TextField
            margin="normal"
            name="photoUrl"
            fullWidth
            id="photoUrl"
            label="Photo"
            value={player.photoUrl}
            onChange={handleFormChange}
          />
          <TextField
            margin="normal"
            name="twitterHandle"
            fullWidth
            id="twitterHandle"
            label="Twitter Handle"
            value={player.twitterHandle}
            onChange={handleFormChange}
          />
          <TextField
            margin="normal"
            name="instagramHandle"
            fullWidth
            id="instagramHandle"
            label="Instagram Handle"
            value={player.instagramHandle}
            onChange={handleFormChange}
          />
          <SubmitButton loading={loading}></SubmitButton>
          <AddBar cancelTo="/manage-players"></AddBar>
        </Box>
      </Grid>
    </Grid>
  );
}