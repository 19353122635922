import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import { LoginContext } from '../../contexts/LoginContext';

export const UpdateQuestion = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  let { id } = useParams();
  const [question, setQuestion] = useState({
    clubId: userSub.adminClubId === null ? "" : userSub.adminClubId,
    sportId: "0",
    name: ""
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [sports, setSports] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setQuestion(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update question');

    console.log('Getting clubs');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setClubs(response.data);
        console.log('Getting sports');
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports?sortBy=name&sortByDirection=asc', {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {
            setSports(response.data);
            console.log('Getting question: ' + id);
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/questions/' + id, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response) {
                setQuestion(prevState => ({
                  ...prevState,
                  name: PrepProp(response.data.name),
                  clubId: PrepProp(response.data.clubId) === "" ? "0" : PrepProp(response.data.clubId),
                  sportId: PrepProp(response.data.sportId) === "" ? "0" : PrepProp(response.data.sportId)
                }));
        
                setPageLoading(false);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteQuestion = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/questions/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/complete?entity=Question&action=deleted&name=' + question.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/questions/' + id, {
      ...question,
      sportId: question.sportId === "0" ? null : question.sportId, 
      clubId: question.clubId === "0" ? null : question.clubId
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=Question&action=updated&name=' + question.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);

    if ((field === "name" || field === "") && question.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }

    return !hasErrors;
  }

  return (
      <Grid container>
        <TitleBar title="Update Question" pageLoading={pageLoading}></TitleBar>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          {!pageLoading && (
            <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
              {userSub.adminClubId === null && (
              <FormControl margin="normal" fullWidth>
                <InputLabel id="clubIdLabel">Club</InputLabel>
                <Select
                  labelId="clubIdLabel"
                  id="clubId"
                  name="clubId"
                  value={question.clubId}
                  label="Club"
                  onChange={handleFormChange}
                >
                  <MenuItem value="0">N/A</MenuItem>
                  {clubs && clubs.map((club) => (
                    <MenuItem value={club.id} key={club.id}>{club.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              )}
              <FormControl margin="normal" fullWidth>
                <InputLabel id="sportIdLabel">Sport</InputLabel>
                <Select
                  labelId="sportIdLabel"
                  id="sportId"
                  name="sportId"
                  value={question.sportId}
                  label="Sport"
                  onChange={handleFormChange}
                >
                  <MenuItem value="0">N/A</MenuItem>
                  {sports && sports.map((sport) => (
                    <MenuItem value={sport.id} key={sport.id}>{sport.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                margin="normal"
                error={nameError}
                helperText={nameHelperText}
                name="name"
                required
                fullWidth
                id="name"
                label="Question"
                value={question.name}
                onChange={handleFormChange}
                onBlur={() => validateForm("name")}
              />
              <SubmitButton loading={loading}></SubmitButton>
              <UpdateBar handleDelete={deleteQuestion} loadingDelete={loadingDelete} cancelTo="/manage-questions"></UpdateBar>
            </Box>
          )}
        </Grid>
      </Grid>
  );
}