import React, {useEffect, useContext} from 'react'
import { TitleBar } from "./Shared/TitleBar";
import UserPool from "../UserPool";
import { Link } from 'react-router-dom';
import { LoginContext } from '../contexts/LoginContext';

export const Logout = () => {

  const {setIsLoggedIn, setUserSub, setWallet} = useContext(LoginContext);

  useEffect(() => {
    console.log('using effect in logout component');
    
    setIsLoggedIn(false);
    setUserSub(null);
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userSub");
    localStorage.removeItem("wallet");
    
    var user = UserPool.getCurrentUser();
    if(user) {
      user.getSession((err, session) => {
        
      });
      user.globalSignOut({
        onSuccess: (data) => {
          setWallet(null);
        },
        onFailure: (err) => {
          console.log("onFailure: ", err);
        }
      });
    }
  });

  return (
    <div>
      <TitleBar title="Log Out"></TitleBar>
      <div>You are now logged out. <Link to="/login">Log back in</Link></div>
    </div>
  )
};
