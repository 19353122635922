import UserPool from "../UserPool";

const AuthService = {
  isLoggedIn: function() {
      
    var isLoggedIn = false;
    var user = UserPool.getCurrentUser();
    if(user) {
      user.getSession((err, session) => {
        if(err){
          isLoggedIn = false;
        }
        isLoggedIn = true;
      });
    }    

    return isLoggedIn;
  },
  userSub: function() {
    return (localStorage.getItem("userSub") === null || localStorage.getItem("userSub") === undefined) ? 
    {} : JSON.parse(localStorage.getItem("userSub"));
  },
  wallet: function() {
    return (localStorage.getItem("wallet") === null || 
    localStorage.getItem("wallet") === undefined ||
    localStorage.getItem("wallet") === "undefined") ? 
    {} : JSON.parse(localStorage.getItem("wallet"));
  }
};

export default AuthService;