import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TitleBar } from "./Shared/TitleBar";
import UserPool from "../UserPool";

export const Users = () => {

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);

  useEffect(() => {
    console.log('using effect in users component');
    var user = UserPool.getCurrentUser();
    if(!user) {
      console.log('Auto logout');
      navigate('/login');
    } else {
      getUsers(user);
    }    
  });

  const getUsers = (user) => {
    if(users.length === 0) {

      axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/users', {
        headers: {
          'Authorization': localStorage.getItem('jwtToken')
        }
      })
        .then(function (response) {
          setUsers(response.data);
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <TitleBar title="Users"></TitleBar>
      {users.map((user) => {
        return(
          <div key={user.id}>{user.userName}</div>
        );
      })}
    </div>
  );
};
