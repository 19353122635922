import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export const UpdateCompetitionQuestion = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [competitionQuestion, setCompetitionQuestion] = useState({
    competitionId: "",
    competitionName: "",
    questionId: "",
    questionName: ""
  });
  const [competitionIdError, setCompetitionIdError] = useState(false);
  const [competitionIdHelperText, setCompetitionIdHelperText] = useState("");
  const [questionIdError, setQuestionIdError] = useState(false);
  const [questionIdHelperText, setQuestionIdHelperText] = useState("");
  const [competitions, setCompetitions] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setCompetitionQuestion(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update competitionQuestion');

    console.log('Getting competitions');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/competitions?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {

        setCompetitions(response.data);

        console.log('Getting questions: ' + id);
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/questions', {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {

            setQuestions(response.data);

            console.log('Getting Competition Questions: ' + id);
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/competition-questions/' + id, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response) {
                setCompetitionQuestion(prevState => ({
                  ...prevState,
                  competitionId: PrepProp(response.data.competitionId),
                  questionId: PrepProp(response.data.questionId)
                }));

                setPageLoading(false);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });

      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteCompetitionQuestion = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/competition-questions/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/manage-competition-questions');
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/competition-questions/' + id, competitionQuestion, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-competition-questions');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setCompetitionIdError(false);
    setQuestionIdError(false);

    if ((field === "competitionId" || field === "") && competitionQuestion.competitionId === '') {
      hasErrors = true;
      setCompetitionIdError(true);
      setCompetitionIdHelperText("Competition is required");
    }
    if ((field === "questionId" || field === "") && competitionQuestion.questionId === '') {
      hasErrors = true;
      setQuestionIdError(true);
      setQuestionIdHelperText("Question is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title="Update Competition Question" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="competitionIdLabel">Competition</InputLabel>
              <Select
                labelId="competitionIdLabel"
                error={competitionIdError}
                id="competitionId"
                name="competitionId"
                value={competitionQuestion.competitionId}
                label="Competition"
                onChange={handleFormChange}
              >
                {competitions && competitions.map((competition) => (
                  <MenuItem value={competition.id} key={competition.id}>{competition.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{competitionIdHelperText}</FormHelperText>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="questionIdLabel">Question</InputLabel>
              <Select
                labelId="questionIdLabel"
                error={questionIdError}
                id="questionId"
                name="questionId"
                value={competitionQuestion.questionId}
                label="Question"
                onChange={handleFormChange}
              >
                {questions && questions.map((question) => (
                  <MenuItem value={question.id} key={question.id}>{question.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{questionIdHelperText}</FormHelperText>
            </FormControl>
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deleteCompetitionQuestion} loadingDelete={loadingDelete} cancelTo="/manage-competition-questions"></UpdateBar>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}