import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import FormControl from '@mui/material/FormControl';

export const UpdateWalletTransaction = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [walletTransaction, setWalletTransaction] = useState({
    walletId: "",
    isDebit: true,
    amount: "",
    newBalance: "",
    notes: "Reversal"
  });
  const [wallets, setWallets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update walletTransaction');

    console.log('Getting wallets');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallets?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {

        setWallets(response.data);

        console.log('Getting walletTransaction: ' + id);
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallet-transactions/' + id, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response) {
                setWalletTransaction(prevState => ({
                  ...prevState,
                  walletId: PrepProp(response.data.walletId),
                  amount: PrepProp(response.data.amount),
                  isDebit: PrepProp(response.data.isDebit),
                  newBalance: PrepProp(response.data.newBalance),
                  notes: PrepProp(response.data.notes)
                }));

                setPageLoading(false);
              })
              .catch(function (error) {
                console.log(error);
              });

      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallet-transactions', {
      walletId: walletTransaction.walletId,
      isDebit: !walletTransaction.isDebit,
      amount: parseInt(walletTransaction.amount),
      notes: "Reversal"
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-wallet-transactions');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Grid container>
      <TitleBar title="Reverse Wallet Transaction" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <>
          <h2>Credit Balance: {walletTransaction.newBalance}</h2>
          {walletTransaction.notes && (
          <div>{walletTransaction.notes}</div>
          )}
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="walletIdLabel">Wallet</InputLabel>
              <Select
                labelId="walletIdLabel"
                id="walletId"
                name="walletId"
                value={walletTransaction.walletId}
                label="Wallet"
                disabled
              >
                {wallets && wallets.map((wallet) => (
                  <MenuItem value={wallet.id} key={wallet.id}>{wallet.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              id="amount"
              name="amount"
              required
              fullWidth
              label="Amount"
              value={walletTransaction.amount}
              disabled
            />
            <SubmitButton loading={loading} name="Reverse"></SubmitButton>
            <UpdateBar hideDelete={true} loadingDelete={loadingDelete} cancelTo="/manage-wallet-transactions"></UpdateBar>
          </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
}