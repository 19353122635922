import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export const UpdateTeamPlayer = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [teamPlayer, setTeamPlayer] = useState({
    teamId: "",
    teamName: "",
    playerId: "",
    playerName: ""
  });
  const [teams, setTeams] = useState(null);
  const [players, setPlayers] = useState(null);
  const [teamIdError, setTeamIdError] = useState(false);
  const [teamIdHelperText, setTeamIdHelperText] = useState("");
  const [playerIdError, setPlayerIdError] = useState(false);
  const [playerIdHelperText, setPlayerIdHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setTeamPlayer(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update teamPlayer');

    console.log('Getting teams');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/teams?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {

        setTeams(response.data);

        console.log('Getting players: ' + id);
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/players', {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {

            setPlayers(response.data);

            console.log('Getting Team Player: ' + id);
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/team-players/' + id, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response) {
                setTeamPlayer(prevState => ({
                  ...prevState,
                  teamId: PrepProp(response.data.teamId),
                  teamName: PrepProp(response.data.teamName),
                  playerId: PrepProp(response.data.playerId),
                  playerName: PrepProp(response.data.playerName)
                }));

                setPageLoading(false);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });

      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteTeamPlayer = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/team-players/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/manage-team-players');
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/team-players/' + id, teamPlayer, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-team-players');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setTeamIdError(false);
    setPlayerIdError(false);

    if ((field === "teamId" || field === "") && teamPlayer.teamId === '') {
      hasErrors = true;
      setTeamIdError(true);
      setTeamIdHelperText("Team is required");
    }
    if ((field === "playerId" || field === "") && teamPlayer.playerId === '') {
      hasErrors = true;
      setPlayerIdError(true);
      setPlayerIdHelperText("Player is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title="Update Team Player" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="teamIdLabel">Team</InputLabel>
              <Select
                labelId="teamIdLabel"
                error={teamIdError}
                id="teamId"
                name="teamId"
                value={teamPlayer.teamId}
                label="Team"
                onChange={handleFormChange}
              >
                {teams && teams.map((team) => (
                  <MenuItem value={team.id} key={team.id}>{team.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{teamIdHelperText}</FormHelperText>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="playerIdLabel">Player</InputLabel>
              <Select
                labelId="playerIdLabel"
                error={playerIdError}
                id="playerId"
                name="playerId"
                value={teamPlayer.playerId}
                label="Player"
                onChange={handleFormChange}
              >
                {players && players.map((player) => (
                  <MenuItem value={player.id} key={player.id}>{player.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{playerIdHelperText}</FormHelperText>
            </FormControl>
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deleteTeamPlayer} loadingDelete={loadingDelete} cancelTo="/manage-team-players"></UpdateBar>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}