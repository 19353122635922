import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export const UpdateLeagueDivision = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [leagueDivision, setLeagueDivision] = useState({
    name: "",
    sportId: "",
    sportName: ""
  });
  const [sports, setSports] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [sportIdError, setSportIdError] = useState(false);
  const [sportIdHelperText, setSportIdHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setLeagueDivision(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update league division');

    console.log('Getting sports');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sports?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {

        setSports(response.data);

        console.log('Getting leagueDivision: ' + id);
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/league-divisions/' + id, {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {
            setLeagueDivision(prevState => ({
              ...prevState,
              name: PrepProp(response.data.name),
              sportId: PrepProp(response.data.sportId),
              sportName: PrepProp(response.data.sportName),
              clubId: PrepProp(response.data.clubId),
              clubName: PrepProp(response.data.clubName)
            }));

            setPageLoading(false);
          })
          .catch(function (error) {
            console.log(error);
          });

      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteLeagueDivision = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/league-divisions/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/complete?entity=League Division&action=deleted&name=' + leagueDivision.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/league-divisions/' + id, leagueDivision, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/complete?entity=League Division&action=updated&name=' + leagueDivision.name + '&id=' + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);
    setSportIdError(false);

    if ((field === "name" || field === "") && leagueDivision.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }
    if ((field === "sportId" || field === "") && leagueDivision.sportId === '') {
      hasErrors = true;
      setSportIdError(true);
      setSportIdHelperText("Sport is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title="Update League/Division" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="sportIdLabel">Sport</InputLabel>
              <Select
                labelId="sportIdLabel"
                error={sportIdError}
                id="sportId"
                name="sportId"
                value={leagueDivision.sportId}
                label="Sport"
                onChange={handleFormChange}
              >
                {sports && sports.map((sport) => (
                  <MenuItem value={sport.id} key={sport.id}>{sport.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{sportIdHelperText}</FormHelperText>
            </FormControl>
            <TextField
              margin="normal"
              error={nameError}
              helperText={nameHelperText}
              name="name"
              required
              fullWidth
              id="name"
              label="Name"
              value={leagueDivision.name}
              onChange={handleFormChange}
              onBlur={() => validateForm("name")}
            />
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deleteLeagueDivision} loadingDelete={loadingDelete} cancelTo="/manage-league-divisions"></UpdateBar>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}