import React, { useState, useEffect, useCallback, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { TitleBar } from '../Shared/TitleBar';
import { Grid, Box, LinearProgress, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import Moment from 'react-moment';
import { LoginContext } from '../../contexts/LoginContext';
import { green } from '@mui/material/colors';
import { red } from '@mui/material/colors';

export const ManageEntries = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  const [entries, setEntries] = useState(null);

  const getEntries = useCallback((entries) => {
    console.log('Getting entries');

    if (entries)
      setEntries(null);

    var sortFilter = "sortBy=name&sortByDirection=asc"
    var filter = "?" + sortFilter;
    if (userSub.role === "user")
      filter = filter + "&userId=" + userSub.id;
    if (userSub.role === "clubadmin")
      filter = filter + "&clubId=" + userSub.adminClubId;

    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/entries' + filter, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setEntries(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          navigate('/log-out');
        }
      });
  }, [navigate, userSub]);

  useEffect((entries) => {
    console.log('using effect in entries component');
    getEntries(entries);
  }, [getEntries]);

  const handleRefresh = () => {
    getEntries(entries);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Manage Entries"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">
          {userSub.role === "admin" && (
            <RouterLink to="/create-entry">Create Entry</RouterLink>
          )}
        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {entries && entries.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    {userSub.role === "admin" && (
                      <TableCell>User</TableCell>
                    )}
                    <TableCell>Status</TableCell>
                    <TableCell>Credits</TableCell>
                    <TableCell>Competition</TableCell>
                    <TableCell>Date Created</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entries.map((entry) => (
                    <TableRow
                      key={entry.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <RouterLink to={`/entry-home?entryId=${entry.id}`}>{entry.name}</RouterLink>
                      </TableCell>
                      {userSub.role === "admin" && (
                        <TableCell>{entry.userName}</TableCell>
                      )}
                      <TableCell component="th" scope="row">
                        <table>
                          <tbody>
                            <tr>
                              <td>Answered</td>
                              <td>{entry.answered && (<CheckCircleIcon fontSize="small" sx={{ color: green[500] }} />)}
                                {!entry.answered && (<CancelIcon fontSize="small" sx={{ color: red[500] }} />)}</td>
                              <td>Score: {entry.correctAnswers}/{entry.totalQuestions}</td>
                            </tr>
                            <tr>
                              <td>Voted</td>
                              <td>{entry.voted && (<CheckCircleIcon fontSize="small" sx={{ color: green[500] }} />)}
                                {!entry.voted && (<CancelIcon fontSize="small" sx={{ color: red[500] }} />)}</td>
                              <td>{entry.votedForPlayerName}</td>
                            </tr>
                            <tr>
                              <td>Rated</td>
                              <td>{entry.rated && (<CheckCircleIcon fontSize="small" sx={{ color: green[500] }} />)}
                                {!entry.rated && (<CancelIcon fontSize="small" sx={{ color: red[500] }} />)}</td>
                              <td>{entry.ratingsCount}</td>
                            </tr>
                          </tbody>
                        </table>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {entry.entryCredits}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <RouterLink to={`/competition-home/${entry.competitionId}`}>{entry.competitionName}</RouterLink>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Moment format="YYYY/MM/DD HH:mm">
                          {entry.dateCreated}
                        </Moment>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!entries && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {entries && entries.length === 0 && (
            <div>
              <h3>No Entries.</h3>
              <div>Try Refresh or use the "Create Entry" link above to create one.</div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}