import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TitleBar } from '../Shared/TitleBar';
import { green } from '@mui/material/colors';

export const Complete = () => {

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  var specialCase = "";
  if (query.get("specialCase") != null) {
    specialCase = query.get("specialCase");
  }
  var entity = "";
  if (query.get("entity") != null) {
    entity = query.get("entity");
  }
  var action = "";
  if (query.get("action") != null) {
    action = query.get("action");
  }
  var name = "";
  if (query.get("name") != null) {
    name = query.get("name");
  }
  var id = "";
  if (query.get("id") != null) {
    id = query.get("id");
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBar title={entity + ' ' + action}></TitleBar>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <div>The {entity} "{name}" has been {action} <CheckCircleIcon sx={{ color: green[500] }} /></div>
        {specialCase === "1" && (
          <div style={{ marginTop: "10px" }}><RouterLink to="/manage-questions">Back to Questions</RouterLink></div>
        )}
        {specialCase !== "1" && (
          <>
            <div style={{ marginTop: "10px" }}>
              <RouterLink to={"/create-" + entity.toLowerCase().replace(/ /g, '-')}>Create another {entity}</RouterLink> or <RouterLink to={"/update-" + entity.toLowerCase().replace(/ /g, '-') + "/" + id}>
                Update this {entity}{specialCase==="2" && " and add to Teams"}{specialCase==="3" && " and add Team Sheet"}
              </RouterLink>
            </div>
            <div style={{ marginTop: "10px" }}><RouterLink to={"/manage-" + entity.toLowerCase().replace(/ /g, '-') + (entity === "Match" ? "e": "") + "s"}>View all {entity}{entity === "Match" ? "e": ""}s</RouterLink></div>
          </>
        )}
      </Grid>
    </Grid>
  );
}