import React from 'react';
import {Grid} from '@mui/material';
import { TitleBar } from '../Shared/TitleBar';
import { EntryTasks } from './EntryTasks';

export const EntryHome = () => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Entry"></TitleBar>
        </Grid>
        <Grid item xs={12}>
        <EntryTasks 
            hideAnswered={false} 
            hideVoted={false} 
            hideRated={false}
            showCompetitionName={true}
            infoText="Click the corresponding yellow button below to complete each entry task. If the button is disabled, you may have already completed this task."
          ></EntryTasks>
        </Grid>
      </Grid>
    </div >
  );
}