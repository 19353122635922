import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LoginContext } from '../../contexts/LoginContext';

export const UpdateSponsor = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  let { id } = useParams();
  const [sponsor, setSponsor] = useState({
    name: "",
    clubId: "0",
    logoUrl: "",
    website: ""
  });
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [clubs, setClubs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setSponsor(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update sponsor');

    console.log('Getting clubs');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setClubs(response.data);

        console.log('Getting sponsor: ' + id);
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sponsors/' + id, {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {
            setSponsor(prevState => ({
              ...prevState,
              name: PrepProp(response.data.name),
              clubId: PrepProp(response.data.clubId) === "" ? "0" : PrepProp(response.data.clubId),
              logoUrl: PrepProp(response.data.logoUrl),
              website: PrepProp(response.data.website)
            }));

            setPageLoading(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteSponsor = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sponsors/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate(`/complete?entity=Sponsor&action=deleted&name=${sponsor.name}&id=${id}`);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sponsors/' + id, {
      ...sponsor,
      clubId: PrepProp(sponsor.clubId) === "0" ? null : sponsor.clubId
    }, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate(`/complete?entity=Sponsor&action=updated&name=${sponsor.name}&id=${id}`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setNameError(false);

    if ((field === "name" || field === "") && sponsor.name === '') {
      hasErrors = true;
      setNameError(true);
      setNameHelperText("Name is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title="Update Sponsor" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            {userSub.adminClubId === null && (
              <FormControl margin="normal" fullWidth>
                <InputLabel id="clubIdLabel">Club</InputLabel>
                <Select
                  labelId="clubIdLabel"
                  id="clubId"
                  name="clubId"
                  value={sponsor.clubId}
                  label="Club"
                  onChange={handleFormChange}
                >
                  <MenuItem value="0">N/A</MenuItem>
                  {clubs && clubs.map((club) => (
                    <MenuItem value={club.id} key={club.id}>{club.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              margin="normal"
              id="name"
              name="name"
              error={nameError}
              helperText={nameHelperText}
              fullWidth
              label="Name"
              value={sponsor.name}
              onChange={handleFormChange}
            />
            <TextField
              margin="normal"
              id="logoUrl"
              name="logoUrl"
              fullWidth
              label="Logo Url"
              value={sponsor.logoUrl}
              onChange={handleFormChange}
            />
            <TextField
              margin="normal"
              id="website"
              name="website"
              fullWidth
              label="Website"
              value={sponsor.website}
              onChange={handleFormChange}
            />
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deleteSponsor} loadingDelete={loadingDelete} cancelTo="/manage-sponsors"></UpdateBar>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}