import React from 'react'
import { Link as RouterLink } from 'react-router-dom';

import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';

import { AdminRoleList } from "./AdminRoleList";
import { UserRoleList } from "./UserRoleList";
import { ClubAdminRoleList } from "./ClubAdminRoleList";

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import HomeIcon from '@mui/icons-material/Home';
import TodayIcon from '@mui/icons-material/Today';

export const PrivateList = (props) => {

  var sessionHome = "/";
  var loggedInAs = props.userSub.role;

  switch (props.userSub.role) {
    case "user":
      {
        sessionHome = "/user-home";
        break;
      }
    case "admin":
      {
        sessionHome = "/admin-home";
        break;
      }
    case "clubadmin":
      {
        sessionHome = "/club-admin-home";
        if(props.userSub.adminClubId) {
          loggedInAs = loggedInAs + ' for ' + props.userSub.adminClubName;
        }
        break;
      }
    default:
      {
        break;
      }
  }

  return (
    <>
      <List subheader={<ListSubheader sx={{lineHeight: '25px', marginTop: '12px;'}}>{props.userSub.userName}, logged in as {loggedInAs}</ListSubheader>}>
        <ListItem button component={RouterLink} to={sessionHome}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={RouterLink} to="/competitions">
          <ListItemIcon>
            <TodayIcon />
          </ListItemIcon>
          <ListItemText primary="Competitions" />
        </ListItem>
        <ListItem button component={RouterLink} to="/log-out">
          <ListItemIcon>
            <LockOpenIcon />
          </ListItemIcon>
          <ListItemText primary={'Log Out'} />
        </ListItem>
      </List>
      {(props.isLoggedIn && props.userSub.role === "admin") && (
        <AdminRoleList userSub={props.userSub}></AdminRoleList>
      )}
      {(props.isLoggedIn && props.userSub.role === "user") && (
        <UserRoleList userSub={props.userSub}></UserRoleList>
      )}
      {(props.isLoggedIn && props.userSub.role === "clubadmin") && (
        <ClubAdminRoleList userSub={props.userSub}></ClubAdminRoleList>
      )}
    </>
  )
};
