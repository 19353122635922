import React, { useState } from 'react';
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import UserPool from "../UserPool";
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import axios from 'axios';
import { TitleBar } from './Shared/TitleBar';
import { SubmitButton } from './Shared/SubmitButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const Register = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    firstName: "",
    surname: "",
    userName: "",
    password: ""
  });
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [userNameHelperText, setUserNameHelperText] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameHelperText, setFirstNameHelperText] = useState("");
  const [surnameError, setSurnameError] = useState(false);
  const [surnameHelperText, setSurnameHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const handleDialogClickOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleFormChange = e => {
    const { name, value } = e.target;
    setUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    UserPool.signUp(
      user.userName,
      user.password,
      [new CognitoUserAttribute(
        {
          Name: "email",
          Value: user.email
        }
      ),
      new CognitoUserAttribute(
        {
          Name: "given_name",
          Value: user.firstName
        }
      ),
      new CognitoUserAttribute(
        {
          Name: "family_name",
          Value: user.surname
        }
      )],
      null,
      (err, data) => {
        console.log();
        if (err) {
          console.log(err.message);
          setDialogMessage(err.message)
          setLoading(false);
          handleDialogClickOpen();
          return;
        }

        axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/users', {
          userName: user.userName,
          email: user.email,
          firstName: user.firstName,
          surname: user.surname,
          userSub: data.userSub
        }, {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {

            axios.post('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/wallets', {
              userId: response.data.id,
              name: 'Default'
            }, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response2) {
                setLoading(false);
                navigate(`/confirm-user?userName=${user.userName}&email=${user.email}`)
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    );
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setEmailError(false);
    setPasswordError(false);

    if ((field === "userName" || field === "") && user.userName === '') {
      hasErrors = true;
      setUserNameError(true);
      setUserNameHelperText("User Name is required");
    }
    if ((field === "email" || field === "") && user.email === '') {
      hasErrors = true;
      setEmailError(true);
      setEmailHelperText("Email is required");
    }
    if ((field === "firstName" || field === "") && user.firstName === '') {
      hasErrors = true;
      setFirstNameError(true);
      setFirstNameHelperText("First Name is required");
    }
    if ((field === "surname" || field === "") && user.surname === '') {
      hasErrors = true;
      setSurnameError(true);
      setSurnameHelperText("Surname is required");
    }
    if ((field === "password" || field === "") && user.password === '') {
      hasErrors = true;
      setPasswordError(true);
      setPasswordHelperText("Password is required");
    }

    return !hasErrors;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Register"></TitleBar>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <TextField
              margin="normal"
              error={userNameError}
              helperText={userNameHelperText}
              name="userName"
              required
              fullWidth
              id="userName"
              label="User Name"
              value={user.userName}
              onChange={handleFormChange}
              onBlur={() => validateForm("userName")}
            />
            <TextField
              margin="normal"
              error={passwordError}
              helperText={passwordHelperText}
              name="password"
              type="password"
              required
              fullWidth
              id="password"
              label="Password"
              value={user.password}
              onChange={handleFormChange}
              onBlur={() => validateForm("password")}
            />
            <TextField
              margin="normal"
              error={emailError}
              helperText={emailHelperText}
              name="email"
              required
              fullWidth
              id="email"
              label="Email"
              value={user.email}
              onChange={handleFormChange}
              onBlur={() => validateForm("email")}
            />
            <TextField
              margin="normal"
              error={firstNameError}
              helperText={firstNameHelperText}
              name="firstName"
              required
              fullWidth
              id="firstName"
              label="First Name"
              value={user.firstName}
              onChange={handleFormChange}
              onBlur={() => validateForm("firstName")}
            />
            <TextField
              margin="normal"
              error={surnameError}
              helperText={surnameHelperText}
              name="surname"
              required
              fullWidth
              id="surname"
              label="Surname"
              value={user.surname}
              onChange={handleFormChange}
              onBlur={() => validateForm("surname")}
            />
            <SubmitButton loading={loading} name="Register"></SubmitButton>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"There was a problem."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}