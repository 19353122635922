import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import axios from 'axios';
import moment from 'moment';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export const UpdateMatchPlayer = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [matchPlayer, setMatchPlayer] = useState({
    matchId: "",
    matchName: "",
    playerId: "",
    playerName: ""
  });
  const [matches, setMatches] = useState(null);
  const [players, setPlayers] = useState(null);
  const [matchIdError, setMatchIdError] = useState(false);
  const [matchIdHelperText, setMatchIdHelperText] = useState("");
  const [playerIdError, setPlayerIdError] = useState(false);
  const [playerIdHelperText, setPlayerIdHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setMatchPlayer(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update matchPlayer');

    console.log('Getting matches');
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/matches?sortBy=name&sortByDirection=asc', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {

        setMatches(response.data);

        console.log('Getting players: ' + id);
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/players', {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {

            setPlayers(response.data);

            console.log('Getting Match Player: ' + id);
            axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/match-players/' + id, {
              headers: {
                'Authorization': localStorage.getItem('jwtToken')
              }
            })
              .then(function (response) {
                setMatchPlayer(prevState => ({
                  ...prevState,
                  matchId: PrepProp(response.data.matchId),
                  matchName: PrepProp(response.data.matchName),
                  playerId: PrepProp(response.data.playerId),
                  playerName: PrepProp(response.data.playerName)
                }));

                setPageLoading(false);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });

      })
      .catch(function (error) {
        console.log(error);
      });

  }, [id]);

  const deleteMatchPlayer = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/match-players/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/manage-match-players');
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/match-players/' + id, matchPlayer, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-match-players');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setMatchIdError(false);
    setPlayerIdError(false);

    if ((field === "matchId" || field === "") && matchPlayer.matchId === '') {
      hasErrors = true;
      setMatchIdError(true);
      setMatchIdHelperText("Match is required");
    }
    if ((field === "playerId" || field === "") && matchPlayer.playerId === '') {
      hasErrors = true;
      setPlayerIdError(true);
      setPlayerIdHelperText("Player is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title="Update Match Player" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="matchIdLabel">Match</InputLabel>
              <Select
                labelId="matchIdLabel"
                error={matchIdError}
                id="matchId"
                name="matchId"
                value={matchPlayer.matchId}
                label="Match"
                onChange={handleFormChange}
              >
                {matches && matches.map((match) => (
                  <MenuItem value={match.id} key={match.id}>{moment(match.startDate).format('yyyy/MM/DD HH:mm')} - {match.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{matchIdHelperText}</FormHelperText>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="playerIdLabel">Player</InputLabel>
              <Select
                labelId="playerIdLabel"
                error={playerIdError}
                id="playerId"
                name="playerId"
                value={matchPlayer.playerId}
                label="Player"
                onChange={handleFormChange}
              >
                {players && players.map((player) => (
                  <MenuItem value={player.id} key={player.id}>{player.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{playerIdHelperText}</FormHelperText>
            </FormControl>
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deleteMatchPlayer} loadingDelete={loadingDelete} cancelTo="/manage-match-players"></UpdateBar>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}