import React, { useState, useEffect, useCallback, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TitleBar } from '../Shared/TitleBar';
import { Grid, Box, LinearProgress, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import Moment from 'react-moment';
import { LoginContext } from '../../contexts/LoginContext';

export const ManageSponsors = () => {
  const navigate = useNavigate();
  const { userSub } = useContext(LoginContext);
  const [sponsors, setSponsors] = useState(null);

  const getSponsors = useCallback((sponsors) => {
    console.log('Getting sponsors');
    
    if(sponsors)
      setSponsors(null);

      var sortFilter = "sortBy=name&sortByDirection=asc"
      var filter = userSub.role === "clubadmin" ? "?clubId=" + userSub.adminClubId + "&" + sortFilter : "?" + sortFilter;

      axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/sponsors' + filter, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setSponsors(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status === 401) {
          navigate('/log-out');
        }
      });
  }, [navigate, userSub]);

  useEffect((sponsors) => {
    console.log('using effect in sponsors component');
    getSponsors(sponsors);
  }, [getSponsors]);

  const handleRefresh = () => {
    getSponsors(sponsors);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TitleBar title="Manage Sponsors"></TitleBar>
        </Grid>
        <Grid item xs={4} align="right">
          <RouterLink to="/create-sponsor">Create Sponsor</RouterLink>
        </Grid>
        <Grid item xs={12} align="right">
          <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
            <RefreshIcon></RefreshIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {sponsors && sponsors.length > 0 && (
          <TableContainer component={Paper} sx={{mt: 2}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Logo</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Club</TableCell>
                  <TableCell>Website</TableCell>
                  <TableCell>Date Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sponsors.map((sponsor) => (
                  <TableRow
                    key={sponsor.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {sponsor.logoUrl && (
                      <img src={sponsor.logoUrl} alt={sponsor.name} style={{height:'30px'}}></img>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <RouterLink to={"/update-sponsor/" + sponsor.id}>{sponsor.name}</RouterLink>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {sponsor.clubName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {sponsor.website && (
                      <a href={sponsor.website} target="blank">{sponsor.website}</a>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Moment format="YYYY/MM/DD">
                        {sponsor.dateCreated}
                      </Moment>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )}
          {!sponsors && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {sponsors && sponsors.length === 0 && (
            <div>
              <h3>No Sponsors.</h3>
              <div>Try Refresh or use the "Create Sponsor" link above to create one.</div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}