import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TitleBar } from '../Shared/TitleBar';
import { UpdateBar } from '../Shared/UpdateBar';
import { SubmitButton } from '../Shared/SubmitButton';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

export const UpdateUser = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [user, setUser] = useState({
    userName: "",
    email: "",
    firstName: "",
    surname: "",
    role: "",
    adminClubId: ""
  });
  const [userNameError, setUserNameError] = useState(false);
  const [userNameHelperText, setUserNameHelperText] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameHelperText, setFirstNameHelperText] = useState("");
  const [surnameError, setSurnameError] = useState(false);
  const [surnameHelperText, setSurnameHelperText] = useState("");
  const [roleError, setRoleError] = useState(false);
  const [roleHelperText, setRoleHelperText] = useState("");
  const [clubs, setClubs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const handleFormChange = e => {
    const { name, value } = e.target;
    setUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const PrepProp = (data) => {
    return (data === undefined || data === null) ? "" : data
  }

  useEffect(() => {
    console.log('using effect in update user');

    console.log('Getting clubs: ' + id);
    axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/clubs', {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setClubs(response.data);

        console.log('Getting user: ' + id);
        axios.get('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/users/' + id, {
          headers: {
            'Authorization': localStorage.getItem('jwtToken')
          }
        })
          .then(function (response) {
            setUser(prevState => ({
              ...prevState,
              userName: PrepProp(response.data.userName),
              email: PrepProp(response.data.email),
              firstName: PrepProp(response.data.firstName),
              surname: PrepProp(response.data.surname),
              role: PrepProp(response.data.role),
              adminClubId: PrepProp(response.data.adminClubId) === "" ? "0" : PrepProp(response.data.adminClubId)
            }));

            setPageLoading(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });



  }, [id]);

  const deleteUser = () => {
    if (!loadingDelete) {
      setLoadingDelete(true);
    }
    axios.delete('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/users/' + id, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoadingDelete(false);
        navigate('/manage-users');
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
    }

    if (!validateForm("")) {
      setLoading(false);
      return;
    }

    axios.put('https://rhsrnact8d.execute-api.us-east-2.amazonaws.com/dev/users/' + id, user, {
      headers: {
        'Authorization': localStorage.getItem('jwtToken')
      }
    })
      .then(function (response) {
        setLoading(false);
        navigate('/manage-users');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var validateForm = (field) => {
    var hasErrors = false;
    setUserNameError(false);
    setEmailError(false);
    setFirstNameError(false);
    setSurnameError(false);
    setRoleError(false);

    if ((field === "userName" || field === "") && user.userName === '') {
      hasErrors = true;
      setUserNameError(true);
      setUserNameHelperText("User Name is required");
    }
    if ((field === "email" || field === "") && user.email === '') {
      hasErrors = true;
      setEmailError(true);
      setEmailHelperText("Email is required");
    }
    if ((field === "firstName" || field === "") && user.firstName === '') {
      hasErrors = true;
      setFirstNameError(true);
      setFirstNameHelperText("First Name is required");
    }
    if ((field === "surname" || field === "") && user.surname === '') {
      hasErrors = true;
      setSurnameError(true);
      setSurnameHelperText("Surname is required");
    }
    if ((field === "role" || field === "") && user.role === '') {
      hasErrors = true;
      setRoleError(true);
      setRoleHelperText("Role is required");
    }

    return !hasErrors;
  }

  return (
    <Grid container>
      <TitleBar title="Update User" pageLoading={pageLoading}></TitleBar>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        {!pageLoading && (
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
            <TextField
              margin="normal"
              error={userNameError}
              helperText={userNameHelperText}
              name="userName"
              required
              fullWidth
              id="userName"
              label="User Name"
              value={user.userName}
              onChange={handleFormChange}
              onBlur={() => validateForm("userName")}
            />
            <TextField
              margin="normal"
              error={emailError}
              helperText={emailHelperText}
              name="email"
              required
              fullWidth
              id="email"
              label="Email"
              value={user.email}
              onChange={handleFormChange}
              onBlur={() => validateForm("email")}
            />
            <TextField
              margin="normal"
              error={firstNameError}
              helperText={firstNameHelperText}
              name="firstName"
              required
              fullWidth
              id="firstName"
              label="First Name"
              value={user.firstName}
              onChange={handleFormChange}
              onBlur={() => validateForm("firstName")}
            />
            <TextField
              margin="normal"
              error={surnameError}
              helperText={surnameHelperText}
              name="surname"
              required
              fullWidth
              id="surname"
              label="Surname"
              value={user.surname}
              onChange={handleFormChange}
              onBlur={() => validateForm("surname")}
            />
            <TextField
              margin="normal"
              error={roleError}
              helperText={roleHelperText}
              name="role"
              required
              fullWidth
              id="role"
              label="Role"
              value={user.role}
              onChange={handleFormChange}
              onBlur={() => validateForm("role")}
            />
            <FormControl margin="normal" fullWidth>
              <InputLabel id="adminClubIdLabel">Admin for Club</InputLabel>
              <Select
                labelId="adminClubIdLabel"
                id="adminClubId"
                name="adminClubId"
                value={user.adminClubId}
                label="Admin for Club"
                onChange={handleFormChange}
              >
                <MenuItem value="0">N/A</MenuItem>
                {clubs && clubs.map((club) => (
                  <MenuItem value={club.id} key={club.id}>{club.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <SubmitButton loading={loading}></SubmitButton>
            <UpdateBar handleDelete={deleteUser} loadingDelete={loadingDelete} cancelTo="/manage-users"></UpdateBar>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}