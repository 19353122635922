import React from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export const TitleBar = (props) => {
  return (
    <>
      <Grid item xs={12}>
        <h1 className="titleBar">{props.title}</h1>
      </Grid>
      {props.pageLoading && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
    </>
  )
};
